import { byCountry, isNZ } from 'utils/byConfig';
import { getLatitudeId } from './getAuth';

const AU_INTERNATIONAL = '+61';
const NZ_INTERNATIONAL = '+64';
const IN_INTERNATIONAL = '+91';

const AU_LOCAL = '04';
const NZ_LOCAL = '02';

export const toLocalNumber = mobileNumber => {
  // TODO: remove log after blank mobile number bug fix https://di.latitudefinancial.com/jira/browse/NZP-2220
  const latitudeId = getLatitudeId();
  const log = {
    latitudeId,
    step: '#toLocalNumber',
    'mobileNumber.startsWith(NZ_INTERNATIONAL)': mobileNumber.startsWith(NZ_INTERNATIONAL),
    'mobileNumber.startsWith(AU_INTERNATIONAL)': mobileNumber.startsWith(AU_INTERNATIONAL),
    'mobileNumber.startsWith(IN_INTERNATIONAL)': mobileNumber.startsWith(IN_INTERNATIONAL),
    'isNZ()': isNZ(),
    'process.env.REACT_APP_ENV': process.env.REACT_APP_ENV,
    'mobileNumber === ""': mobileNumber === '',
    'typeof mobileNumber': typeof mobileNumber,
    'mobileNumber.length': typeof mobileNumber === 'string' ? mobileNumber.length : null,
  };
  // eslint-disable-next-line no-console
  console.error(`Latitude ID ${latitudeId}: ${JSON.stringify(log)}`);

  if (mobileNumber.startsWith(NZ_INTERNATIONAL)) {
    return byCountry({
      AU:
        process.env.REACT_APP_ENV === 'prod'
          ? mobileNumber.replace(NZ_INTERNATIONAL, '0')
          : // create a fake AU number to pass non-prod AU backend validations.
            mobileNumber.replace(NZ_INTERNATIONAL, AU_LOCAL),
      NZ: mobileNumber.replace(NZ_INTERNATIONAL, '0'),
    });
  }
  if (mobileNumber.startsWith(AU_INTERNATIONAL)) {
    return byCountry({
      AU: mobileNumber.replace(AU_INTERNATIONAL, '0'),
      NZ:
        process.env.REACT_APP_ENV === 'prod'
          ? mobileNumber.replace(AU_INTERNATIONAL, '0')
          : // create a fake NZ number to pass non-prod NZ backend validations.
            mobileNumber.replace(AU_INTERNATIONAL, NZ_LOCAL),
    });
  }
  if (mobileNumber.startsWith(IN_INTERNATIONAL) && process.env.REACT_APP_ENV !== 'prod') {
    return byCountry({
      // create a fake AU or NZ number to pass non-prod backend validations.
      AU: '0412345678',
      NZ: '021234567',
    });
  }
  return mobileNumber;
};

export const toInternationalNumber = mobileNumber => {
  if (mobileNumber.startsWith(NZ_LOCAL)) {
    // if mobile number starts with 02 then it is a NZ number and should replace `0` with `+64`
    return mobileNumber.replace('0', NZ_INTERNATIONAL);
  }

  if (mobileNumber.startsWith(AU_LOCAL)) {
    return mobileNumber.replace('0', AU_INTERNATIONAL);
  }

  return mobileNumber;
};
