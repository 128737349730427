import { useEffect } from 'react';
import { usePageTracking } from 'react-event-tracker';
import { useStoreValue } from 'store';
import { getDataLayerElements } from 'utils/getDataLayerElements';

import {
  config,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_NZ_GEM,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  PRODUCT_CODE_GO,
} from '_config';
import { byCard } from 'utils';

export const usePageTracker = (siteSubSection, pageTitle, isError) => {
  const [state] = useStoreValue();

  const trackDataLayerOnCompareProducts = () => {
    const elements = {
      product: {
        productId: [PRODUCT_CODE_DAVID_JONES_PREMIERE, PRODUCT_CODE_DAVID_JONES_PRESTIGE],
      },
    };
    return pageTitle === 'davidjones-product-comparison' ? elements : '';
  };

  const { trackPageView } = usePageTracking(
    {
      page: {
        pageType: isError ? 'error-page' : 'funnel-page',
        siteSection: byCard(
          {
            [PRODUCT_CODE_NZ_GEM]: 'gemnz',
            [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: 'dj',
            [PRODUCT_CODE_DAVID_JONES_PREMIERE]: 'dj',
            [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: '28degrees',
            [PRODUCT_CODE_GO]: 'go',
          },
          { whenNoProductCode: 'dj' },
        ),
        siteSubSection,
        pageTitle,
      },
      product: {
        productId: config.productCode ? [config.productCode] : null,
      },
      ...getDataLayerElements(state),
      ...trackDataLayerOnCompareProducts(),
    },
    {
      trackPageViewByDefault: false,
    },
  );

  useEffect(() => {
    trackPageView();
  }, [trackPageView, pageTitle]);

  return null;
};
