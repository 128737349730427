import {
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_NZ_GEM,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
} from '_config';

export const defaultMaintenanceMessage = {
  [PRODUCT_CODE_NZ_GEM]: 'We will be back online as soon as possible. Thank you for your patience.',
  [PRODUCT_CODE_DAVID_JONES_PREMIERE]: 'We will be back online as soon as possible. Thank you for your patience.',
  [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: 'We will be back online as soon as possible. Thank you for your patience.',
  [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: 'We will be back online as soon as possible. Thank you for your patience.',
  [PRODUCT_CODE_GO]: 'We will be back online as soon as possible. Thank you for your patience.',
};

// Default values only, updated values are coming from LaunchDarkly
const defaultPremiereRatesAndFees = {
  purchaseAnnualRate: 23.99,
  cashAnnualRate: 24.99,
  annualFee: 99,
  additionalCardHolderFee: 24,
  lateFee: 35.0,
  paperStatementFee: 3.0,
  ausPostHandlingFee: 3.95,
  replacementCardFee: 0.0,
};

// Default values only, updated values are coming from LaunchDarkly
const defaultPrestigeRatesAndFees = {
  purchaseAnnualRate: 23.99,
  cashAnnualRate: 24.99,
  annualFee: 295,
  additionalCardHolderFee: 'Not Applicable',
  lateFee: 35.0,
  paperStatementFee: 3.0,
  ausPostHandlingFee: 3.95,
  replacementCardFee: 0.0,
};

// Default values only, updated values are coming from LaunchDarkly
const defaultAu28DRatesAndFees = {
  purchaseAnnualRate: 27.49,
  cashAnnualRate: 29.99,
  annualFee: 0.0,
  additionalCardHolderFee: 0.0,
  lateFee: 45.0,
  paperStatementFee: 5,
  ausPostHandlingFee: 3.95,
  onlinePaymentHandlingFee: 1.95,
  replacementCardFee: 0.0,
  cashAdvanceFeePercentagePerTransaction: 3.5,
  cashAdvanceFeeFixed: 4,
  monthlyFee: 0,
};

// Default values only, updated values are coming from LaunchDarkly
const defaultGoRatesAndFees = {
  purchaseAnnualRate: 27.49,
  cashAnnualRate: 29.99,
  annualFee: 0.0,
  additionalCardHolderFee: 0.0,
  lateFee: 35.0,
  paperStatementFee: 2.5,
  ausPostHandlingFee: 3.95,
  replacementCardFee: 0.0,
  monthlyFee: 9.95,
};

export const defaultNzRatesAndFees = {
  lateFee: 15,
  purchaseAnnualRate: 28.99,
  additionalCardHolderFee: 0,
  paperStatementFee: 1,
  replacementCardFee: 5,
  annualFee: 55,
  establishmentFee: 55,
  cashAnnualRate: 29.95,
  nzPostHandlingFee: 2.7,
  internationalTransactionFee: 2,
  atmWithdrawalFeeInternational: 1.5,
  atmWithdrawalFee: 1,
  statementReprintFee: 5,
  cashWithdrawalFee: 2,
};

export const DEFAULT_FEATURES_NZ = {
  promoCode: false,
  saveAndResume: false,
  showSaveAndResumeButton: false, // for A/B test https://di.latitudefinancial.com/jira/browse/NZP-1565
  dynamicCreditLimit: false,
  creditLimit99999: false,
  biometrics: true,
  incomeReplay: false,
  maintenancePage: false,
  nzRatesAndFees: defaultNzRatesAndFees,
  maintenanceMessage: defaultMaintenanceMessage,
  emailVerification: false,
};

export const DEFAULT_FEATURES_DJ = {
  djSaveAndResume: true,
  djDynamicCreditLimit: true,
  djMaintenancePage: false,
  premiereRatesAndFees: defaultPremiereRatesAndFees,
  prestigeRatesAndFees: defaultPrestigeRatesAndFees,
  maintenanceMessage: defaultMaintenanceMessage,
  djEmailVerification: true,
  djAch: true,
};

export const DEFAULT_FEATURES_28D = {
  au28DLaunched: true,
  au28DSaveAndResume: true,
  au28DDynamicCreditLimit: true,
  au28DMaintenancePage: false,
  au28DRatesAndFees: defaultAu28DRatesAndFees,
  maintenanceMessage: defaultMaintenanceMessage,
  au28DEmailVerification: true,
  au28DAch: true,
};

export const DEFAULT_FEATURES_GO = {
  goSaveAndResume: true,
  goDynamicCreditLimit: true,
  goMaintenancePage: false,
  goRatesAndFees: defaultGoRatesAndFees,
  maintenanceMessage: defaultMaintenanceMessage,
  goEmailVerification: true,
  goAch: true,
};
