import { useMemo } from 'react';
import defaults from 'lodash/defaults';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import { isEmpty } from 'lodash';
import { byCountry, byCard } from 'utils/byConfig';
import {
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  config,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  PRODUCT_CODE_GO,
} from '_config';
import {
  HARVEY_NORMAN_IN_STORE_MERCHANTS,
  TIER_3_IN_STORE_MERCHANTS,
  SMP,
  NZ_DEFAULT_APPLY_AND_BUY,
  HARVEY_NORMAN_PARAMS,
  IN_STORE_MERCHANT_PARAMS,
  merchants,
  APPLE_QR,
  APPLE_QR_MERCHANT_PARAMS,
  DAVID_JONES_PRESTIGE_APPLY_AND_BUY,
  DAVID_JONES_PREMIERE_APPLY_AND_BUY,
  DAVID_JONES_APPLY_AND_SHOP_INSTANTLY_MERCHANT_ID,
  DAVID_JONES_PREMIERE_APPLY_AND_SHOP_INSTANTLY,
  DAVID_JONES_PRESTIGE_APPLY_AND_SHOP_INSTANTLY,
  DAVID_JONES_QR_CODE_MERCHANT_ID,
  DAVID_JONES_PREMIERE_QR_CODE,
  DAVID_JONES_PRESTIGE_QR_CODE,
  DAVID_JONES_QR_CODE,
  TWENTY_EIGHT_DEGREES_PARAMS,
  DAVID_JONES_PARTNER_WEBSITE_MERCHANT_ID,
  DAVID_JONES_PREMIERE_PARTNER_WEBSITE,
  DAVID_JONES_PRESTIGE_PARTNER_WEBSITE,
  GO_PARAMS,
} from './params';

/**
  @type UseApplyAndBuyQueryParams

  @typedef {() => ApplyAndBuyParams} UseApplyAndBuyQueryParams

  @typedef {
    {
      merchantID?: string
      shortCode?: string
      solCode?: string
      gemId1?: string
      channel?: string
      stream?: string
      merchantName?: string
      merchantSourceCode?: string
      returnUrl?: string
    }
  } ApplyAndBuyParams
*/
export const useApplyAndBuyQueryParams = () => {
  const queryString = window.location.search;
  const applyAndBuy = useMemo(() => {
    /**
      ```ts
      type ExpectedQueryParams = {
        gemid1: string
        merchantID?: string
        origin?: string
        channel?: string
        stream?: string
        shortCode?: string
        solCode?: string
        merchantSourceCode?: string
      }
      ```
     */
    const queryParams = new URLSearchParams(queryString);
    const mappedParams = {
      gemId1: queryParams.get('gemid1'),
      merchantId: queryParams.get('merchantID'),
      channel: queryParams.get('channel'),
      stream: queryParams.get('stream'),
      shortCode: queryParams.get('shortCode'),
      solCode: queryParams.get('solCode'),
      merchantSourceCode: queryParams.get('merchantSourceCode'),
    };

    const merchant = mappedParams.merchantId ? lookupMerchant(mappedParams.merchantId) : null;

    if (
      HARVEY_NORMAN_IN_STORE_MERCHANTS.includes(mappedParams.merchantId) &&
      (mappedParams.shortCode === null || mappedParams.solCode === null || mappedParams.merchantSourceCode === null)
    ) {
      return defaults(merchant, omitEmpty(mappedParams), HARVEY_NORMAN_PARAMS, IN_STORE_MERCHANT_PARAMS);
    }

    if (APPLE_QR === mappedParams.merchantId) {
      return defaults(merchant, omitEmpty(mappedParams), APPLE_QR_MERCHANT_PARAMS);
    }

    if (queryParams.get('origin') === SMP || TIER_3_IN_STORE_MERCHANTS.includes(mappedParams.merchantId)) {
      return defaults(merchant, omitEmpty(mappedParams), IN_STORE_MERCHANT_PARAMS, NZ_DEFAULT_APPLY_AND_BUY);
    }
    if (
      config.productCode === PRODUCT_CODE_DAVID_JONES_PREMIERE &&
      DAVID_JONES_APPLY_AND_SHOP_INSTANTLY_MERCHANT_ID === mappedParams.merchantId
    ) {
      return defaults(omitEmpty(mappedParams), DAVID_JONES_PREMIERE_APPLY_AND_SHOP_INSTANTLY);
    }
    if (
      config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE &&
      DAVID_JONES_APPLY_AND_SHOP_INSTANTLY_MERCHANT_ID === mappedParams.merchantId
    ) {
      return defaults(omitEmpty(mappedParams), DAVID_JONES_PRESTIGE_APPLY_AND_SHOP_INSTANTLY);
    }
    if (
      config.productCode === PRODUCT_CODE_DAVID_JONES_PREMIERE &&
      DAVID_JONES_QR_CODE_MERCHANT_ID === mappedParams.merchantId
    ) {
      return defaults(omitEmpty(mappedParams), DAVID_JONES_PREMIERE_QR_CODE);
    }
    if (
      config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE &&
      DAVID_JONES_QR_CODE_MERCHANT_ID === mappedParams.merchantId
    ) {
      return defaults(omitEmpty(mappedParams), DAVID_JONES_PRESTIGE_QR_CODE);
    }
    if (DAVID_JONES_QR_CODE_MERCHANT_ID === mappedParams.merchantId) {
      return defaults(omitEmpty(mappedParams), DAVID_JONES_QR_CODE);
    }
    if (
      config.productCode === PRODUCT_CODE_DAVID_JONES_PREMIERE &&
      DAVID_JONES_PARTNER_WEBSITE_MERCHANT_ID === mappedParams.merchantId
    ) {
      return defaults(omitEmpty(mappedParams), DAVID_JONES_PREMIERE_PARTNER_WEBSITE);
    }
    if (
      config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE &&
      DAVID_JONES_PARTNER_WEBSITE_MERCHANT_ID === mappedParams.merchantId
    ) {
      return defaults(merchant, omitEmpty(mappedParams), DAVID_JONES_PRESTIGE_PARTNER_WEBSITE);
    }
    if (config.productCode === PRODUCT_CODE_TWENTY_EIGHT_DEGREES) {
      return defaults(merchant, omitEmpty(mappedParams), TWENTY_EIGHT_DEGREES_PARAMS);
    }

    if (config.productCode === PRODUCT_CODE_GO) {
      return defaults(merchant, omitEmpty(mappedParams), GO_PARAMS);
    }

    return defaults(merchant, omitEmpty(mappedParams), getDefaultApplyAndBuyParams());
  }, [queryString]);

  return applyAndBuy;
};
export const getDefaultApplyAndBuyParams = () => {
  return byCountry(
    {
      AU: () =>
        byCard(
          {
            [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: DAVID_JONES_PRESTIGE_APPLY_AND_BUY,
            [PRODUCT_CODE_DAVID_JONES_PREMIERE]: DAVID_JONES_PREMIERE_APPLY_AND_BUY,
            [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: TWENTY_EIGHT_DEGREES_PARAMS,
            [PRODUCT_CODE_GO]: DAVID_JONES_PREMIERE_APPLY_AND_BUY,
          },
          {
            // in case when resuming AU application and the product code is not yet fetched.
            whenNoProductCode: {},
          },
        ),
      NZ: () => NZ_DEFAULT_APPLY_AND_BUY,
    },
    { lazy: true },
  );
};

/**
 * Omit null and empty string items
 */
const omitEmpty = value => omitBy(value, v => isNull(v) || isEmpty(v));

const lookupMerchant = merchantId => {
  const merchant = merchants[merchantId];

  if (merchant) {
    return merchant;
  }

  // eslint-disable-next-line no-console
  console.error(`unexpected merchantId: ${merchantId}`);
  return null;
};
