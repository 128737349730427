import { convertRateToMonthlyString } from 'utils/convertRateToMonthly';
import { config, PRODUCT_CODE_TWENTY_EIGHT_DEGREES } from '_config';
import { maxAUCreditLimit } from '_config/au/constants';
import { residentialStatusOptions } from '_config/_constants/residentialStatusOptions';
import { getInitialState, CHANNEL_INSTORE, CHANNEL_EXTERNAL_QR, CHANNEL_APPLY_AND_BUY, CHANNEL_DIRECT } from 'store';
import { APPROVED } from 'store/constants';
import { byCard, byCountry } from 'utils';
import { getFeatures, isFeatureOn } from 'featureToggles';
import {
  getGender,
  getAge,
  convertToYears,
  getSelectorLabel,
  getOutcomeReason,
  getApplicationOutcome,
} from './getDataLayerElementsHelper';
import {
  NZ_DEFAULT_APPLY_AND_BUY,
  HARVEY_NORMAN_IN_STORE_MERCHANTS,
  TWENTY_EIGHT_DEGREES_PARAMS,
} from '../hooks/useApplyAndBuyQueryParams/params';

import { calculatedTotalMonthlyExpenses } from './expensesFooterHelper';

/**
 * @resource  Data Layer specification can be found here https://di.latitudefinancial.com/wiki/display/DIG/Data+Layer+Requirements+for+NZ+Apply+UI
 */
export const getDataLayerElements = (state = getInitialState()) => {
  const {
    overview,
    contactDetails,
    personalDetails,
    extraDetails,
    otherDetails: { currentAddress, residentialStatus, lengthOfCurrentResidency },
    employmentDetails,
    expensesDetails,
    additionalDebtsDetails: { hasOtherDebts },
    creditLimitDetails,
    preferredCreditLimitDetails,
    achDetails,
  } = state.acquisition;

  const elements = {
    featureToggles: Object.entries(getFeatures() || {})
      .filter(([_, value]) => typeof value === 'boolean')
      .map(([key, value]) => `${key}:${value}`),
    user: {
      profile: {
        profileid: state.latitudeId, // Okta uid
      },
    },
    application: {
      // type application_biometrics = FEATURE_OFF | NOT_USED | STARTED | CANCELLED | SUCCESSFUL | DATA_INVALID
      biometrics: !isFeatureOn('biometrics') ? 'FEATURE_OFF' : state.biometricsVerification,
      promoCode: isFeatureOn('promoCode') ? overview.promoCode : null,
      intApplicationId: state.applicationRef,
      applicationId: state.applicationId,
      applicationChannel:
        state.applyAndBuy.channel === CHANNEL_INSTORE
          ? CHANNEL_INSTORE
          : state.applyAndBuy.channel === 'externalQR'
          ? CHANNEL_EXTERNAL_QR
          : isApplyAndBuy(state)
          ? CHANNEL_APPLY_AND_BUY
          : CHANNEL_DIRECT, // 'Apply and Buy' | 'Direct' (for non Apply & Buy) | 'externalQR` (for non-in-store QR codes)
      applicationStartDate: state.applicationStartDate, // Timestamp of when the Application was Started in ISO8601 date time format:
      applicationStatus: state.applicationSubmitted ? 'Complete' : 'Started',
      retrieveflag: state.applicationRetrieveflag,
      applicationOutcomeReason: getOutcomeReason(state.applicationStatus, state.applicationStatusReason), // <'earlyDecline', 'Decline', 'ConditionalPOI', 'ConditionalIDV', 'ConditionalPOIandIDV'>
      applicationOutcome: getApplicationOutcome(state.applicationStatus), // <'Approved', 'Declined', 'Referred', 'ConditionalApproval', 'Error'>
      additionalCardHolderAdded: !achDetails?.hasAch ? '' : achDetails?.hasAch === 'yes' ? 'Yes' : 'No', // <true, false>
      // insuranceTaken: '', // <true, false> This is not currently relevant
      // The credit limit amount requested by user. Set from field "creditLimit" on page "Credit Limit"
      requestedCreditLimit: byCountry({
        AU: state.creditLimitAccepted || state.creditLimitApproved,
        NZ: isFeatureOn('dynamicCreditLimit')
          ? byCountry({
              AU: state.creditLimitAccepted || state.creditLimitApproved,
              NZ:
                state.creditLimitAccepted ||
                preferredCreditLimitDetails?.creditLimit ||
                creditLimitDetails?.creditLimit,
            })
          : preferredCreditLimitDetails?.creditLimit || creditLimitDetails?.creditLimit,
      }),
      // The approved credit limit amount returned from downstream provider
      approvedCreditLimit: byCountry({
        AU: state.applicationStatus === APPROVED ? state?.creditLimitAccepted || state?.creditLimitApproved : '',
        NZ: state.creditLimitApproved,
      }),
      productMaxCreditLimit: byCountry({
        AU: String(maxAUCreditLimit),
        NZ: String(config.maxCreditLimit),
      }),
      eIdvSelected: 'No', // <true, false>
      eIdvCompleted: 'No', // <true, false>
      ePoiCompleted: 'No', // <true, false>
      poi: {
        assessmentId: state.assessmentId, // Assessment ID is provided from
        poiStartDate: state.poiStartDate, // Timestamp of when the POI Process was Started in ISO8601 date time format:
        bankSelected: state.bankSelected, // set for the POI through the "Bank Connect, Account selection, transaction selection process" - only one bank can be connected
        numberOfAccountsSelected: state.numberOfAccountsSelected, // Number of Accounts Selected on ePOI Form to verify income for the current bank selected
        numberOfTransactionsSelected: state.numberOfTransactionsSelected,
      },
    },
    applicant: {
      personal: {
        gender: getGender(personalDetails.title), // Derived from applicant.personal.title set by Tealium - Case Title { when 'Mr' set Gender to 'Male', when 'Dr' set Gender to Unknown, otherwise set Gender to 'Female'}
        title: personalDetails.title, // set directly from selected values from field "title" on page "About You".
        firstName: personalDetails.firstName || state.biometricsResult?.firstName || '', // set directly from field "firstName" on page "About You".
        age:
          getAge(personalDetails.dateOfBirth, new Date()) ||
          getAge(state.biometricsResult?.dateOfBirth, new Date()) ||
          '', // calculated from field "Dates of Birth" on page "About You" - use formula (Today minus Date of Birth) - convert to years, and truncated to whole numbers, e.g. 43, 44 etc.
        emailAddress: contactDetails.emailAddress, // set directly from field "confirmEmail" on page "About You". Should only be set if confirmEmail === emailAddress - This value will be hashed by Tealium
        mobileNumber: contactDetails.mobileNumber.replace('0', config.phoneCountryCode), // set directly from field "mobileNumber" on page "About You". This value will be hashed by Tealium
        maritalStatus: byCountry({
          AU: personalDetails.relationshipStatus,
          NZ: extraDetails.relationshipStatus,
        }), // set directly from field "relationshipStatus" on page "Extra Details".
        numberOfDependants: byCountry({
          AU: personalDetails.numberOfDependents,
          NZ: extraDetails.numberOfDependents,
        }), // set directly from field "numberOfDependents" on page "Extra Details".
        residentialStatus: getSelectorLabel(residentialStatusOptions, residentialStatus), // set directly from selected value from field "residentialStatus" on page "Other Details".
        idType: extraDetails.idType || state.biometricsResult?.idType || '', // set directly from selected value from field "identification" on page "Extra Details".
      },
      employment: {
        employmentStatus: employmentDetails.employmentStatus, // set directly from field "employmentStatus" on page "Employment Details".
        occupationType: employmentDetails?.occupation || '', // set directly from selected value from field "occupation" on page "Employment Details".
        industryType: employmentDetails.industry, // set directly from selected value from field "industry" on page "Employment Details".
        yearsAtCurrentEmployer: convertToYears(employmentDetails.lengthOfTimeAtCurrentEmployer), // set from fields "lengthOfTimeAtCurrentEmployer" on page "Employment Details". -- Convert to number using fields "lengthOfTimeAtCurrentEmployer.years + (lengthOfTimeAtCurrentEmployer.months / 12)" round to 2 decimal places
        incomeMonthly:
          (
            +convertRateToMonthlyString(employmentDetails.income) +
            +convertRateToMonthlyString(employmentDetails.otherIncome)
          ).toFixed(2) === '0.00'
            ? null
            : (
                +convertRateToMonthlyString(employmentDetails.income) +
                +convertRateToMonthlyString(employmentDetails.otherIncome)
              ).toFixed(2), // set directly from field "income.amount" on page "Employment Details". -- Convert total income to a monthly amount, round to 2 decimal places.
        otherEmploymentStatus: employmentDetails.otherEmploymentStatus || null, // set directly from field "employmentStatus" on page "Employment Details".
      },
      address: {
        postcode: currentAddress.address.postcode || state.biometricsResult?.address?.postcode || '', // set directly from field "currentAddress.postcode" on page "Other Details".
        suburb: currentAddress.address.suburb || state.biometricsResult?.address?.suburb || '', // set directly from field "currentAddress.suburb" on page "Other Details".
        city: byCountry({
          AU: currentAddress.address.city || null,
          NZ: currentAddress.address.city || state.biometricsResult?.address?.city || '',
        }),
        state: byCountry({
          AU: currentAddress.address.state || null,
          NZ: null,
        }),
        yearsAtCurrentAddress: convertToYears(lengthOfCurrentResidency), // convert to number using fields "lengthOfCurrentResidency.years + (lengthOfCurrentResidency.months / 12)" round to 2 decimal places
      },
      expenses: {
        isExpensesShared:
          expensesDetails.isExpensesShared === 'yes' || expensesDetails.isExpensesShared === 'yes' ? 'Yes' : 'No', // <true, false> set directly from field "isExpensesShared" on page "Expense Details".
        expensesMonthly: calculatedTotalMonthlyExpenses(expensesDetails) || null, // set directly from field "total monthly living expense" on page "Expense Details".,
        hasOtherDebts: hasOtherDebts === '' ? '' : hasOtherDebts === 'yes' ? 'Yes' : 'No', // <true, false> set directly from field "hasOtherDebts" on page "Additional Debt Details".
      },
    },
    merchant: isApplyAndBuy(state)
      ? {
          storeMerchantId: state.applyAndBuy.merchantId,
          merchantGroupCode: state.applyAndBuy?.shortCode || '',
          webSite: state.applyAndBuy?.returnUrl ? new URL(state.applyAndBuy.returnUrl).hostname : '',
          callToActionPos: getCallToActionPos(state.applyAndBuy?.returnUrl, state.applyAndBuy?.merchantId),
        }
      : {
          storeMerchantId: '',
          merchantGroupCode: '',
          webSite: '',
          callToActionPos: '',
        },
  };

  return elements;
};

const isApplyAndBuy = state => {
  return byCard({
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: state.applyAndBuy?.merchantId !== TWENTY_EIGHT_DEGREES_PARAMS.merchantId,
    default: state.applyAndBuy?.merchantId !== NZ_DEFAULT_APPLY_AND_BUY.merchantId,
  });
};

// type GetCallToActionPos = (url?: string) => string;
const getCallToActionPos = (url, merchantId) => {
  if (!url) {
    if (HARVEY_NORMAN_IN_STORE_MERCHANTS.includes(merchantId)) {
      return 'qr';
    }
    return '';
  }
  return url.toLowerCase().includes('checkout') ? 'cart' : 'web';
};
