import React, { useMemo, useState, useCallback } from 'react';
import { LayoutPage, LayoutContent, Form, FormRenderer, WarningModal } from 'components';
import { useStoreValue } from 'store';
import { initFormConfig, submitHandler, byCountry } from 'utils';
import { config } from '_config';
import { isAU } from 'utils/byConfig';
import { useWarning } from '_config/au/employmentDetails';
import { useFormInitialValues, useSteps } from '../../hooks';

const FORM_ID = 'employmentDetails';

export function EmploymentDetails() {
  const [storeState] = useStoreValue();
  const { acquisition } = storeState;
  const initialValues = useFormInitialValues(FORM_ID);
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const onModalClose = useCallback(() => setWarningModalOpen(false), [setWarningModalOpen]);
  const { check, warnings } = useWarning();
  const formConfig = useMemo(() => initFormConfig(config.formConfigs[FORM_ID], storeState), [storeState]);
  const { goNext } = useSteps();
  const onSubmit = useCallback(
    ({ values, errors }) => {
      function updateStoreAndRedirect() {
        const newAcquisition = {
          ...acquisition,
          employmentDetails: { ...values },
        };

        goNext({
          storePatch: { acquisition: newAcquisition },
          dataLayerEventLabel: byCountry({
            AU: 'Next - Expenses',
            NZ: 'Next',
          }),
        });
      }

      submitHandler({ submit: updateStoreAndRedirect, errors });
    },
    [acquisition, goNext],
  );

  const onClickNext = useCallback(
    ({ values, errors }) => {
      if (Object.keys(errors).length > 0) {
        return;
      }
      if (isAU() && !check(values)) {
        setWarningModalOpen(true);
      } else {
        onSubmit({ values, errors });
      }
    },
    [check, onSubmit],
  );

  return (
    <LayoutPage noSessionTimeout>
      <Form
        id={FORM_ID}
        initialValues={initialValues}
        onSubmit={onClickNext}
        showSaveDraftButton
        saveDraftOnSubmit
        saveDraftOnSessionTimeout
      >
        {formData => (
          <LayoutContent>
            <FormRenderer config={formConfig} formData={formData} storeState={storeState} />
            <WarningModal
              title="Can you confirm these finances are correct?"
              isOpen={isWarningModalOpen}
              onClose={onModalClose}
              onSubmit={() => onSubmit(formData.state)}
              warnings={warnings}
              editButtonText="Edit Finance"
              confirmButtonText="Confirm and Continue"
            />
          </LayoutContent>
        )}
      </Form>
    </LayoutPage>
  );
}
