import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useEventTracking } from 'react-event-tracker';
import { useStoreValue, AXIOS_UNKNOWN_ERROR, OKTA_OTP_TOO_OFTEN_ERROR } from 'store';
import { LayoutPage, LayoutContent, Form, Errors } from 'components';
import { isAU, initFormConfig, submitHandler, getDataLayerElements } from 'utils';
import { useErrorTrackingAndUpdateStore, useFormInitialValues, useRegisterAndLoginUser, useSteps } from 'hooks';
import { config, STEP_VERIFY_TO_RESUME, STEP_VERIFY } from '_config';
import { DEV_FEATURES } from '__dev/devFeatures';
import { OktaProfileError } from 'components/errors/OktaProfileError';
import { ContactDetailsFields } from './ContactDetailsFields';
import { useFocusOnFirstFormElement } from '../../hooks/useFocusOnFirstFormElement';

const FORM_ID = 'contactDetails';

function ContactDetails() {
  const [storeState, updateStore] = useStoreValue();
  const { isResuming, acquisition, applicationStatus, ...state } = storeState;
  const { pathname } = useSteps();

  useEffect(() => {
    if (isAU) {
      window.DD_RUM?.startSessionReplayRecording();
    }
  }, []);

  const handleSubmitSuccess = () => {
    updateStore({ activeStep: nextStep, applicationErrors: null });
    history.push(nextStep);
  };
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();

  const { loading, submit } = useRegisterAndLoginUser({
    onSuccess: handleSubmitSuccess,
    onError: handleErrorCallback,
  });

  const retrySubmit = useCallback(() => {
    const { emailAddress: email, mobileNumber: mobile } = acquisition.contactDetails;
    submit({ email, mobile });
  }, [acquisition.contactDetails, submit]);

  const nextStep = useMemo(() => {
    return isResuming ? STEP_VERIFY_TO_RESUME : STEP_VERIFY;
  }, [isResuming]);

  const formConfig = useMemo(() => {
    return initFormConfig(config.formConfigs[FORM_ID](isResuming));
  }, [isResuming]);

  const history = useHistory();
  const { trackEvent } = useEventTracking();
  const initialValues = useFormInitialValues(FORM_ID);

  useFocusOnFirstFormElement();

  const onSubmit = ({ values, errors }) => {
    function registerAndLoginUser() {
      const uniqueEmail = DEV_FEATURES.OTP_GENERATE_UNIQUE_EMAIL
        ? values.emailAddress.replace(/\d*@/, `${Date.now()}@`)
        : '';

      const contactDetails = {
        ...values,
        ...(uniqueEmail
          ? {
              emailAddress: uniqueEmail,
              confirmEmail: uniqueEmail,
            }
          : null),
      };

      const newAcquisition = {
        ...acquisition,
        contactDetails,
      };

      updateStore({
        acquisition: newAcquisition,
      });

      trackEvent({
        event: {
          category: 'application',
          action: 'application-navigation',
          location: pathname.slice(1),
          label: 'Next',
        },
        ...getDataLayerElements({ acquisition: newAcquisition, ...state }),
      });

      const { emailAddress: email, mobileNumber: mobile } = newAcquisition.contactDetails;

      if (DEV_FEATURES.OTP_SKIP) {
        updateStore({
          maskedMobileNumber: '04*******00',
        });
        handleSubmitSuccess();
      } else {
        submit({ email, mobile });
      }
    }

    submitHandler({ submit: registerAndLoginUser, errors });
  };

  const hasErrors = !!state.applicationErrors;

  const noProgressStepper = isAU();

  if (hasErrors && [OKTA_OTP_TOO_OFTEN_ERROR, AXIOS_UNKNOWN_ERROR].includes(state.applicationErrors?.type)) {
    return (
      <LayoutPage noProgressStepper={noProgressStepper}>
        <Errors applicationRef={storeState.applicationRef} retry={retrySubmit} retrying={loading} isPreSubmission />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage noProgressStepper={noProgressStepper} hideTitleIfErrors={false}>
      <Form
        id={FORM_ID}
        initialValues={initialValues}
        onSubmit={onSubmit}
        loading={loading}
        submitButtonLabel="Next"
        disableFormCache
      >
        {formData => {
          return (
            <LayoutContent>
              <ContactDetailsFields isResuming={isResuming} config={formConfig} formData={formData} />
              {hasErrors && <OktaProfileError />}
            </LayoutContent>
          );
        }}
      </Form>
    </LayoutPage>
  );
}

export { ContactDetails };
