import React from 'react';
import { Container, Grid, Link, Stack, Text } from 'basis';
import { LayoutPage } from 'components';
import styled from '@emotion/styled';
import AdrianaMartinezSignature from 'images/adriana_martinez.png';
import { config } from '../../../../_config';
import { davidJonesTheme } from '../../../../theme';

const StyledUL = styled.ul`
  margin-top: 0;
  padding-left: 16px;
  line-height: 24px;
`;
const StyledDivSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledLeftDivWidth75 = styled.div`
  width: 75%;
`;
const StyledDivAlignRight = styled.div`
  width: 23%;
  text-align: right;
`;

export function Au28DFinancialTable({
  ratesAndFees,
  statementDate,
  downloadOrPrintFile,
  productName,
  accountNumber,
  creditLimit,
  productCode,
}) {
  const {
    purchaseAnnualRate,
    cashAnnualRate,
    cashAdvanceFeePercentagePerTransaction,
    monthlyFee,
    lateFee,
    cashAdvanceFeeFixed,
    paperStatementFee,
    ausPostHandlingFee,
    onlinePaymentHandlingFee,
  } = ratesAndFees;

  return (
    <LayoutPage hideBackButton>
      <Container margin="0 0 0 0">
        <Container padding="5 0" padding-md="5" testId="LAYOUT FORM">
          <Grid preset="page" debug={false}>
            <Grid.Item colSpan="all">
              <Grid rowsGap={0}>
                <Container padding="4 4" margin="0 8">
                  <div style={{ marginBottom: '40px' }}>
                    <Link variant="secondary" id="pdfButton" onClick={downloadOrPrintFile}>
                      Download or Print file
                    </Link>
                  </div>
                  <Grid preset="page" colsGap={11}>
                    <Grid.Item colSpan="all">
                      <Grid rowsGap={1}>
                        <Container padding="0">
                          <div id="financial-table">
                            <Stack gap="1">
                              <Text
                                as="h1"
                                textStyle="heading5"
                                textStyle-md="heading4"
                                color={davidJonesTheme.headingColor}
                              >
                                FINANCIAL TABLE
                              </Text>
                              <hr />
                              <Text>
                                The details below are correct as at the disclosure date. The{' '}
                                <strong>financial table</strong> does not contain all of the pre-contractual information{' '}
                                <strong>we</strong> are required by law to give <strong>you</strong>. Further terms and
                                conditions (and an explanation of some of the words used in the{' '}
                                <strong>financial table</strong>) are in the Conditions of Use in the enclosed booklet
                                entitled {productName} Conditions of Use and Credit Guide, which includes important
                                information about <strong>your credit contract</strong> and privacy.
                              </Text>
                              <br />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <Text textStyle="heading6">Account Number</Text>
                                </Container>
                                <Container>
                                  <Text textStyle="heading6">Disclosure Date</Text>
                                </Container>
                                <Text textStyle="heading6">Credit Limit</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Container>
                                  <data-hj-suppress>
                                    <Text>{accountNumber}</Text>
                                  </data-hj-suppress>
                                </Container>
                                <Container margin={0} margin-md="0 0 0 -11">
                                  <Text>{statementDate}</Text>
                                </Container>
                                <data-hj-suppress>
                                  <Text>${creditLimit}</Text>
                                </data-hj-suppress>
                              </StyledDivSpaceBetween>
                              <br />
                              <Text>
                                <strong>Cash Limit</strong> <i>(refer to your statement)</i>
                              </Text>
                              <hr />
                              <Text>
                                A cash limit forms part of your credit limit which may be withdrawn in cash or cash
                                equivalent transactions. A cash limit may be imposed, withdrawn or changed on your
                                account from time to time, which is notified to you on each statement we give you and,
                                is effective from the date of each statement.{' '}
                              </Text>
                              <br />
                              <Text textStyle="heading5">Annual Percentage Rates</Text>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text textStyle="heading6">Standard annual percentage</Text>
                                <Text>{purchaseAnnualRate}%</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text textStyle="heading6">Cash annual percentage rate</Text>
                                <Text>{cashAnnualRate}%</Text>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <div>
                                  <Text textStyle="heading6">Balance transfer annual percentage rate</Text>
                                  <Text>
                                    Please refer to the balance transfer interest rate set out in any offer presented to
                                    you.
                                  </Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledUL>
                                <li>
                                  For the <strong>purchase and charges balance</strong>, the{' '}
                                  <strong>annual percentage rate</strong> is the{' '}
                                  <strong>standard annual percentage rate</strong>.
                                </li>
                                <li>
                                  For the <strong>cash advance balance</strong>, the{' '}
                                  <strong>annual percentage rate</strong> is the{' '}
                                  <strong>cash annual percentage rate</strong>.
                                </li>
                                <li>
                                  For each <strong>balance transfer balance</strong> during the{' '}
                                  <strong>special promotion term</strong>, the <strong>annual percentage rate</strong>{' '}
                                  is the relevant <strong>balance transfer annual percentage rate</strong>, and for the{' '}
                                  <strong>expired balance transfer balance</strong>, the{' '}
                                  <strong>annual percentage rate</strong> is the{' '}
                                  <strong>cash annual percentage rate</strong>.
                                </li>
                              </StyledUL>
                              <Text textStyle="heading5">Maximum Interest Free Period</Text>
                              <hr />
                              <Text>
                                Up to 55 days for amounts included in the <strong>purchase and charges balance</strong>.
                              </Text>
                              <br />
                              <Text textStyle="heading5">Minimum Repayments</Text>
                              <hr />
                              <Text>The minimum amount payable for a statement period will consist of:</Text>
                              <StyledUL>
                                <li>
                                  <strong>Your minimum monthly payment</strong> - this amount is payable by the{' '}
                                  <strong>due date</strong> shown on the relevant statement of <strong>account</strong>;
                                </li>
                                <li>
                                  <strong>Any overdue amount</strong> - this amount is payable immediately;
                                </li>
                                <li>
                                  <strong>Any overlimit amount</strong> - this amount is payable immediately; and
                                </li>
                                <li>
                                  Any other amount due under the <strong>credit contract</strong> which is payable on
                                  demand.
                                </li>
                              </StyledUL>
                              <Text textStyle="heading5">Fees and Charges</Text>
                              <hr />
                              {monthlyFee && (
                                <>
                                  <StyledDivSpaceBetween>
                                    <StyledLeftDivWidth75>
                                      <Text>A card fee will be charged</Text>
                                    </StyledLeftDivWidth75>
                                    <div style={{ textAlign: 'right' }}>
                                      <Text>
                                        <strong>${monthlyFee} per month</strong>
                                      </Text>
                                      <Text>(effective from 17 Sept 2024)</Text>
                                    </div>
                                  </StyledDivSpaceBetween>
                                  <hr />
                                </>
                              )}
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A <strong>cash advance</strong> fee will be charged to the <strong>account</strong>{' '}
                                    on each occasion on which you obtain a <strong>cash advance</strong> or make a{' '}
                                    <strong>cash equivalent</strong> transaction.{' '}
                                  </Text>
                                </StyledLeftDivWidth75>
                                <div style={{ textAlign: 'right' }}>
                                  <Text>
                                    <strong>{cashAdvanceFeePercentagePerTransaction}%</strong> of the
                                  </Text>
                                  <Text>
                                    <strong>cash advance</strong> or
                                  </Text>
                                  <Text>
                                    <strong>${cashAdvanceFeeFixed}</strong> whichever is greater
                                  </Text>
                                </div>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <Text>
                                  A late fee will be charged to the <strong>account</strong> in each{' '}
                                  <strong>statement period</strong> when any amount due and payable to us is not paid in
                                  full by the <strong>due date</strong>.
                                </Text>
                                <strong>${lateFee?.toFixed(2)}</strong>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A paper statement fee will be charged for each paper statement of account sent to
                                    you. This fee will not be charged if you consent to receiving your statements
                                    electronically.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${paperStatementFee?.toFixed(2)}</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A payment handling fee will be charged to <strong>your account</strong> each time
                                    you make a payment to <strong>your account</strong>, except that no fee is payable
                                    for payments made by mail, by direct debit or via the{' '}
                                    <strong>Online Service Centre</strong>.
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>
                                    <strong>${ausPostHandlingFee?.toFixed(2)} (in person)</strong>
                                  </Text>
                                  <Text>
                                    <strong>${onlinePaymentHandlingFee?.toFixed(2)} (online)</strong>
                                  </Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <StyledDivSpaceBetween>
                                <StyledLeftDivWidth75>
                                  <Text>
                                    A balance transfer fee will be charged to the account if you take up a balance
                                    transfer offer
                                  </Text>
                                </StyledLeftDivWidth75>
                                <StyledDivAlignRight>
                                  <Text>Up to 3% of balance transfer amount</Text>
                                </StyledDivAlignRight>
                              </StyledDivSpaceBetween>
                              <hr />
                              <Container>
                                <Text>
                                  These fees, once charged, form part of <strong>your closing balance</strong> and are
                                  payable as part of <strong>your minimum monthly payment</strong>. <strong>You</strong>{' '}
                                  will not have to make a payment in a statement period if{' '}
                                  <strong>your monthly payment</strong> would otherwise consist of a paper statement
                                  fee.
                                </Text>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Changes to the Credit Contract</Text>
                              </Container>
                              <hr />
                              <Container>
                                <Text>
                                  The <strong>credit limit, annual percentage rate(s),</strong> the maximum interest
                                  free period, the amount, frequency, time for payment or method of calculation of a fee
                                  or charge or minimum repayment may be changed, and new fees or charges may be imposed,
                                  in each case without your consent (except that <strong>we</strong> cannot increase the{' '}
                                  <strong>credit limit</strong> except at your request or with your consent in any form
                                  required by law). <strong>You</strong> will be notified of a change or of new fees or
                                  charges in the manner set out in the Conditions of Use accompanying this financial
                                  table.
                                </Text>
                              </Container>
                              <br />
                              <Container>
                                <Text textStyle="heading5">Other Information</Text>
                                <hr />
                                <Text>
                                  If you do not wish to receive direct marketing offers, please call{' '}
                                  {config.phoneNumbers.assistance2}.
                                </Text>
                              </Container>
                              <Container padding="6 0 0 0">
                                <Text textStyle="heading5">Offer to New Account Holders</Text>
                              </Container>
                              <hr />
                              <Text>
                                Latitude Financial Australia ABN 42 008 583 588, Australian Credit Licence number
                                392145, as the credit provider offers to provide you with a {productName} credit
                                facility on the conditions set out in the credit contract.
                              </Text>
                              <Text>
                                <strong>Your</strong> acceptance of this offer will be taken to have occurred when{' '}
                                <strong>you</strong> are bound by the <strong>credit contract</strong> as set out in
                                clause 1 of the Conditions of Use.
                              </Text>
                              <Container padding="4 0">
                                <Text>Signed on behalf of Latitude Finance Australia.</Text>
                                <br />
                                <img src={AdrianaMartinezSignature} width="150px" alt="form-title-icon" />
                                <Text>
                                  <strong>Adriana Martinez</strong>
                                </Text>
                                <Text>Executive General Manager, Latitude Pay</Text>
                                <hr />
                                <Text>Registered to BPAY Pty Ltd ABN 69 079 137 518</Text>
                              </Container>
                            </Stack>
                          </div>
                        </Container>
                      </Grid>
                    </Grid.Item>
                  </Grid>
                </Container>
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </Container>
    </LayoutPage>
  );
}
