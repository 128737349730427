import React from 'react';
import { useStoreValue } from 'store';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import { useEventTracking } from 'react-event-tracker';
import { FooterSources } from '_config/_constants/externalLinks';
import { byCard, byCountry } from 'utils';
import {
  PRODUCT_CODE_NZ_GEM,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  PRODUCT_CODE_GO,
} from '_config';
import { OutcomeFooter } from './OutcomeFooter';
import { DefaultFooter } from './DefaultFooter';
import { DavidJonesFooter } from '../david-jones/Footer';
import { Au28DFooter } from './Au28DFooter';

export function Footer(props) {
  const ProductFooter = byCard({
    [PRODUCT_CODE_NZ_GEM]: NzFooter,
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: DavidJonesFooter,
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: DavidJonesFooter,
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: Au28DFooter,
    [PRODUCT_CODE_GO]: Au28DFooter,
    default: byCountry({
      // in case resuming with deprecated query param `cardType` instead of `productCode`
      AU: DavidJonesFooter,
      NZ: NzFooter,
    }),
  });

  return <ProductFooter {...props} />;
}

function NzFooter({ footer = 'DEFAULT' }) {
  const [state] = useStoreValue();
  const { trackEvent, getQueryString } = useEventTracking();

  const trackEventSender = label => {
    trackEvent({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location: 'footer',
        label,
      },
      ...getDataLayerElements(state),
    });
  };

  const getLinkWithAnalytics = key => {
    const gemid2 = getQueryString({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location: 'footer',
        label: FooterSources[key].label,
      },
    });

    const link = new URL(FooterSources[key].url);
    link.searchParams.set('gemid2', gemid2);
    return link.href;
  };

  if (footer === 'OUTCOME') {
    return (
      <OutcomeFooter state={state} trackEventSender={trackEventSender} getLinkWithAnalytics={getLinkWithAnalytics} />
    );
  }

  return (
    <DefaultFooter state={state} trackEventSender={trackEventSender} getLinkWithAnalytics={getLinkWithAnalytics} />
  );
}
