import React from 'react';
import { useStoreValue } from 'store';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import { useEventTracking } from 'react-event-tracker';
import { Footer, Link, Text, Container, Flex, Stack, Grid } from 'basis';
import styled from '@emotion/styled';

// TODO: update urls
const FooterSources = {
  privacy_policy: {
    label: 'Privacy and Credit Reporting Policy',
    url: 'https://www.latitudefinancial.com.au/privacy',
  },
  terms_and_conditions: {
    label: 'Website Terms and Conditions',
    url: 'https://www.latitudefinancial.com.au/terms-and-conditions',
  },
  security: {
    label: 'Security',
    url: 'https://www.latitudefinancial.com.au/security',
  },
};

export const FooterWrapper = styled.div`
  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.lg})`} {
      & > div {
        display: flex;
      }
    }
  `}
`;

export function DavidJonesFooter() {
  const [state] = useStoreValue();
  const { trackEvent, getQueryString } = useEventTracking();

  const trackEventSender = label => {
    trackEvent({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location: 'footer',
        label,
      },
      ...getDataLayerElements(state),
    });
  };

  const getLinkWithAnalytics = key => {
    // TODO: update query params for AU Houndstooth products
    const gemid2 = getQueryString({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location: 'footer',
        label: FooterSources[key].label,
      },
    });

    const link = new URL(FooterSources[key].url);
    // TODO: update query params for AU Houndstooth products
    link.searchParams.set('gemid2', gemid2);
    return link.href;
  };

  return (
    <Container bg="black" testId="David-Jones-footer">
      <FooterWrapper>
        <Container bg="black" padding="5" padding-md="6 5">
          <Container margin="auto" margin-md="auto">
            <Grid colsGutter="0" cols="6">
              <Grid.Item colSpan="0-1" colSpan-md="0" colSpan-lg="0" rowSpan="0" rowSpan-md="0" margin-md="0">
                <Flex placeItems="center left">
                  <Footer.Header.Logo name="latitude" />
                </Flex>
              </Grid.Item>

              <Grid.Item colSpan="all">
                <Flex placeItems="left">
                  <Stack direction="horizontal" align="left" gap="3 4">
                    {Object.keys(FooterSources).map((key, index) => {
                      return (
                        <Link
                          key={index}
                          href={getLinkWithAnalytics(key)}
                          newTab
                          variant="dark-bg"
                          onClick={() => trackEventSender(FooterSources[key].label)}
                        >
                          {FooterSources[key].label}
                        </Link>
                      );
                    })}
                  </Stack>
                </Flex>
              </Grid.Item>
              <Grid.Item colSpan="all">
                <Flex placeItems="left">
                  <Container textStyle="legal" margin="7 0 0 0">
                    <Text color="white">
                      Credit facility provided by Latitude Finance Australia ABN 42 008 583 588. Australian Credit
                      Licence Number 392145. 130 Lonsdale Street, Melbourne 3000.
                    </Text>
                  </Container>
                </Flex>
              </Grid.Item>
            </Grid>
          </Container>
        </Container>
      </FooterWrapper>
    </Container>
  );
}
