import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { isFeatureOn } from 'featureToggles';
import { useHandleApiErrors } from '../useHandleApiErrors';
import { toSaveAndSubmitApplicationInput } from './toSaveAndSubmitApplicationInput';

export const SAVE_AND_SUBMIT_APPLICATION_GQL = gql`
  mutation saveAndSubmitApplication($input: SaveAndSubmitApplicationInput!) {
    saveAndSubmitApplication(input: $input) {
      status
    }
  }
`;

/**
 * This hook should only be used in AU.
 */
export function useSaveAndSubmitApplication({ storeState, onSuccess, onError }) {
  const { applicationId, acquisition } = storeState;
  const promoCode = isFeatureOn('promoCode')
    ? acquisition.overview?.promoCode === ''
      ? undefined
      : acquisition.overview?.promoCode
    : undefined;

  const { handleError } = useHandleApiErrors('apollo', onError);
  const [saveAndSubmitApplication, { loading }] = useMutation(SAVE_AND_SUBMIT_APPLICATION_GQL, {
    ...(onSuccess
      ? {
          onCompleted: data => {
            onSuccess(data.saveAndSubmitApplication);
          },
        }
      : {}),
    onError: err => {
      handleError(err);
    },
  });

  const saveAndSubmit = useCallback(
    formData => {
      if (formData) {
        const input = { applicationId, promoCode, ...toSaveAndSubmitApplicationInput(formData, storeState) };
        saveAndSubmitApplication({ variables: { input } });
      }
    },
    [applicationId, promoCode, storeState, saveAndSubmitApplication],
  );

  return {
    saveAndSubmit,
    loading,
  };
}
