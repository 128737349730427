import React from 'react';
import { Button, Container, useTheme } from 'basis';
import { HeaderBannerTextStyle, ProductNameWrapper } from './LandingPage28D.styles';

const HeaderBannerTextArea = ({ openFeatureAndBenefitsModal, color = 'white' }) => {
  const theme = useTheme();
  return (
    <HeaderBannerTextStyle theme={theme}>
      <Container
        padding="0 4 8 4"
        padding-xs="0 6 0 5"
        padding-md="0 0 0 0"
        margin="0"
        margin-md="0 0 0 5"
        margin-lg="0 0 0 5"
        margin-xl="0 0 0 0"
        width="100%"
      >
        <ProductNameWrapper>
          Apply for a Latitude 28° Global Platinum Mastercard<sup>&reg;</sup>
          <br />
        </ProductNameWrapper>
        {/* <Text margin="4 0" color="white">Pay like a local, wherever you are.</Text> */}
        <Button
          margin="6 0 8 0"
          margin-lg="13 0 14 0"
          color={color}
          variant="secondary"
          onClick={openFeatureAndBenefitsModal}
        >
          View benefits
        </Button>
      </Container>
    </HeaderBannerTextStyle>
  );
};

export { HeaderBannerTextArea };
