import React from 'react';
import { Text, Grid, Container, Icon, Link, Stack, Flex } from 'basis';
import { Hero, StyledUL, InternalLink } from 'components';
import {
  config,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  STEP_OUTCOME_FIN_TABLE,
  APPLE_MERCHANT,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_NZ_GEM,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  OutcomeApprovedSourcesAu,
  OverviewSources,
} from '_config';
import { OutcomeContent } from 'siteContent/outcomeContent';
import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { displayCreditLimit, byCountry, isDJ } from 'utils';
import styled from '@emotion/styled';
import DownloadApple from 'images/download-apple.svg';
import DownloadGoogle from 'images/download-google.svg';
import { kebabCase } from 'lodash';
import groupLFramePremiereCardImageMobile from 'images/premiere-card.png';
import groupLFramePrestigeCardImageMobile from 'images/prestige-card.png';
import groupLFrame28DCardImageMobile from 'images/28d-card-vertical.png';
import groupLFrameGoCardImageMobile from 'images/go-card.png';
import iconShoppingBag from 'images/icon-shopping-bag.svg';
import appleGoogleSamsungPay from 'images/apple-google-samsung-pay.png';
import ellipse from 'images/ellipse.svg';
import { getRatesAndFees } from 'featureToggles';
import { ContentWrapper, TopContainer } from '../Outcome.styles';
import { ApprovedInformation } from './ApprovedInformation';
import { ShoppingInformation } from './ShoppingInformation';
import { FeatureInformation } from './FeatureInformation';
import { AppleApplicantsBox } from '../components/AppleApplicantsBox';
import { DownloadResources } from './DownloadResources';
import { StuffLayout } from './StuffLayout';
import { ShadowedContainer } from '../../../components/info-card/InformationCard';

function OutcomeApproved({ storeState }) {
  const {
    acquisition,
    gemVisaAccountNumber,
    applyAndBuy: { merchantName },
    applicationRef,
    accountNumber,
    applicationDate,
  } = storeState;
  const { formHeader } = OutcomeContent[config.countryCode];
  const overviewSources = OverviewSources[config.countryCode];
  const formTealiumLocation = kebabCase(formHeader);
  const internalLinkEventConfig = {
    category: 'text-link',
    action: 'internal-link',
    location: formTealiumLocation,
  };
  const auCard = getCardImageMobile(config.productCode);
  const { groupLFrameCardImageMobile } = OutcomeContent[config.countryCode];
  const { trackEventSender, getLinkWithAnalytics } = useDataLayerAndAnalyticsLinks(storeState);
  const creditLimit = displayCreditLimit(storeState);
  const { productCode } = config;
  const finTableParams = `?creditLimit=${creditLimit}&accountNumber=${accountNumber}&applicationDate=${applicationDate}&productCode=${productCode}`;
  const finTableUrl = `${STEP_OUTCOME_FIN_TABLE}${finTableParams}`;
  const { shoppingInformation } = OutcomeApprovedSourcesAu;
  const StyledNumberListItem = styled.p`
    display: inline-block;
    margin-block-start: inherit;
    text-align: center;
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    background: #000;
    border-radius: 24px;
    width: 24px;
    height: 24px;
    margin-block-end: 0;
  `;
  const StyledStrong = styled.strong`
    color: #414141;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `;
  const TopImageWrapper =
    productCode === PRODUCT_CODE_TWENTY_EIGHT_DEGREES
      ? styled.div`
          display: flex;
          justify-content: center;
          width: 150px;
          height: 237px;
          margin: auto;
        `
      : styled.div`
          display: flex;
          justify-content: center;
          width: 211px;
          height: 133px;
          margin: auto;
        `;
  const TextAlignWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
  `;
  const IconTextWrapper = styled.div`
    display: flex;
    margin-left: 16px;
  `;
  const RightTextWrapper = styled.div`
    display: flex;
    margin-left: 10px;
    width: 90%;
  `;
  const ImageHeaderWrapper = styled.div`
    display: flex;
    margin-bottom: 24px;
  `;
  const TextHeaderWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-left: 12px;
    width: 90%;
  `;
  const NumberedListWrapper = styled.div`
    display: flex;
    margin-bottom: 4px;
  `;
  const NumberedTextWrapper = styled.div`
    display: flex;
    margin-left: 12px;
    width: 90%;
  `;
  const SmallContainerWrapper = styled.div`
    background: ${props => props.theme.colors.specialBackground};
    ${props => `
  ${`@media (max-width: ${props.theme.screenSize.xs})`} {
    margin-right: 6px;
  `}
  `;

  const DownloadAppImage = styled.img`
    height: 40px;
  `;

  const nzRatesAndFees = getRatesAndFees();
  const cashAnnualRate = nzRatesAndFees?.cashAnnualRate;
  const annualFee = nzRatesAndFees?.annualFee;
  const biAnnualFee = nzRatesAndFees?.annualFee / 2;
  const currentInterestRate = nzRatesAndFees?.purchaseAnnualRate;
  const establishmentFee = nzRatesAndFees?.establishmentFee;
  const cashWithdrawalFee = nzRatesAndFees?.cashWithdrawalFee;

  const OutcomeApprovedNZ = () => {
    return (
      <ContentWrapper>
        <TopContainer>
          <Grid preset="page" rowsGap={0} colsGap={5}>
            <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="4-11" colSpan-lg="5-11" rowSpan-md="0">
              <Container bg="secondary.lightBlue.t15">
                <Hero src="" srcMobile={groupLFrameCardImageMobile} alt="LFrame card" />
              </Container>
            </Grid.Item>

            <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="0-3" colSpan-lg="0-4" rowSpan-md="0">
              <ApprovedInformation
                merchantName={merchantName}
                trackEventSender={trackEventSender}
                getLinkWithAnalytics={getLinkWithAnalytics}
                creditLimit={creditLimit}
                gemVisaAccountNumber={gemVisaAccountNumber}
                firstName={acquisition.personalDetails.firstName}
                email={acquisition.contactDetails.emailAddress}
              />
            </Grid.Item>

            {merchantName === APPLE_MERCHANT && (
              <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="0-11" colSpan-lg="0-11" rowSpan-md="1">
                <AppleApplicantsBox storeState={storeState} />
              </Grid.Item>
            )}

            <Grid.Item colSpan="all" colSpan-sm="all" colSpan-md="0-5" colSpan-lg="0-5" rowSpan-md="2">
              <DownloadResources trackEventSender={trackEventSender} getLinkWithAnalytics={getLinkWithAnalytics} />
            </Grid.Item>
          </Grid>
        </TopContainer>

        <Container bg="secondary.lightBlue.t15" bg-md="white" padding="5" padding-md="10 0 10 10">
          <Text textStyle="heading4" textStyle-md="heading4" padding="4">
            Thank you for choosing Gem Visa
          </Text>
        </Container>

        <Container>
          <Grid preset="page" colsGap={2}>
            <Grid.Item
              colSpan="all"
              colSpan-sm="all"
              colSpan-md="all"
              colSpan-lg="0-5"
              rowSpan="0"
              rowSpan-md="0"
              rowSpan-lg="0"
            >
              <ShoppingInformation trackEventSender={trackEventSender} getLinkWithAnalytics={getLinkWithAnalytics} />
            </Grid.Item>
            <Grid.Item
              colSpan="all"
              colSpan-sm="all"
              colSpan-md="all"
              colSpan-lg="6-11"
              rowSpan="1"
              rowSpan-md="1"
              rowSpan-lg="0"
            >
              <Container>
                <Grid preset="page">
                  <Grid.Item colSpan="all">
                    <FeatureInformation
                      trackEventSender={trackEventSender}
                      getLinkWithAnalytics={getLinkWithAnalytics}
                    />
                  </Grid.Item>
                  <Grid.Item colSpan="all">
                    <StuffLayout />
                  </Grid.Item>
                </Grid>
              </Container>
            </Grid.Item>
          </Grid>

          <Container bg="secondary.lightBlue.t15" padding="6 8" margin="4" margin-md="10">
            <Container>
              <Text align="left" textStyle="legal">
                *Normal credit and lending criteria and fees, including a Gem Visa ${establishmentFee} establishment fee
                and ${annualFee} annual fee (charged ${biAnnualFee?.toFixed(2)} half-yearly) apply. Prevailing interest
                rate (currently {currentInterestRate}% p.a.) applies to any remaining balance on the expiry of the
                interest free period. Paying only the minimum monthly repayment of 3% of the outstanding monthly balance
                or $20 (whichever is more), will not be sufficient to repay the purchase amount(s) within the
                promotional period. Amount payable will be shown on your monthly statement. For cash advances, an
                interest rate of {cashAnnualRate}% p.a. and a ${cashWithdrawalFee} fee applies. Further information on
                rates and fees can be found at{' '}
                <InternalLink
                  label={overviewSources.gemfinance_url.label}
                  url={overviewSources.gemfinance_url.url}
                  eventConfig={internalLinkEventConfig}
                  newTab
                />
                . Available to approved Gem Visa credit card customers only. Credit provided by Latitude Financial
                Services Ltd.
              </Text>
            </Container>

            <Container padding="2 0">
              <Text align="left" textStyle="legal">
                #Long term interest free offers may vary. Available at participating retailers to approved customers
                only. Conditions, fees and charges may apply. Ask in-store for further details.
              </Text>
            </Container>

            <Container padding="2 0">
              <Text align="left" textStyle="legal">
                **Apple Store and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other
                countries and regions. Apple Store is a service mark of Apple Inc.
              </Text>
            </Container>

            <Container padding="2 0">
              <Text align="left" textStyle="legal">
                Google Play and the Google Play logo are trademarks of Google LLC.
              </Text>
            </Container>

            <Container padding="2 0">
              <Text align="left" textStyle="legal">
                Visa and the Visa brand are registered trademarks of Visa International.
              </Text>
            </Container>

            <Container padding="2 0">
              <Text align="left" textStyle="legal">
                Gem Visa is a credit facility provided by Latitude Financial Services Limited.
              </Text>
            </Container>
          </Container>
        </Container>
      </ContentWrapper>
    );
  };
  const OutcomeApprovedAU = () => {
    const { productName } = config;
    const creditCardName = productName + (isDJ() ? ' credit card' : '');
    return (
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <Container padding="5 0" padding-md="5" testId="LAYOUT FORM">
          <Grid preset="page" debug={false}>
            <Grid.Item colsGap="2" colSpan="all" colSpan-sm="1-6" colSpan-md="1-6" colSpan-lg="2-9" colSpan-xl="3-8">
              <Grid rowsGap="6">
                <Container padding="1 0 0 0" padding-lg="8 0 0 0">
                  <Text as="h1" textStyle="heading3" testId="congratulations">
                    Congratulations!
                  </Text>
                  <Text />
                </Container>
                <SmallContainerWrapper>
                  <Container padding="8" padding-lg="10">
                    <Grid rowsGap="4">
                      <Grid.Item colSpan="all" colSpan-lg="0-5" rowSpan-md="0">
                        <TopImageWrapper>
                          <img src={auCard} alt="AU Card" />
                        </TopImageWrapper>
                      </Grid.Item>
                      <Grid.Item colSpan="all" colSpan-lg="6-11" rowSpan="1" rowSpan-lg="0">
                        <TextAlignWrapper>
                          <Text as="h2" textStyle="heading5" align="center">
                            {isDJ() ? (
                              <>
                                You're approved for <data-hj-suppress>${creditLimit}</data-hj-suppress> {productName}{' '}
                                credit card
                              </>
                            ) : (
                              <>
                                You're approved for the {creditCardName} with{' '}
                                <data-hj-suppress>${creditLimit}</data-hj-suppress> credit limit
                              </>
                            )}
                          </Text>
                        </TextAlignWrapper>
                      </Grid.Item>
                    </Grid>
                  </Container>
                </SmallContainerWrapper>
                <Container>
                  <Text>
                    {isDJ() ? (
                      <>
                        Application reference number: <b>{applicationRef}</b>
                      </>
                    ) : (
                      <>
                        Account number: <b>{accountNumber}</b>
                      </>
                    )}
                  </Text>
                </Container>
                <Stack gap="4">
                  <Container margin="0 0 0 4">
                    <Text as="h3" textStyle="heading5">
                      What happens next?
                    </Text>
                  </Container>
                  <IconTextWrapper>
                    <Icon name="mail" color="grey.t75" />
                    <RightTextWrapper>
                      <Text>
                        We've just sent you an email with your important account details. You will receive a welcome
                        pack with your new {productName} credit card in the mail within 10 business days.
                      </Text>
                    </RightTextWrapper>
                  </IconTextWrapper>
                  <IconTextWrapper>
                    <Icon name="download" color="grey.t75" />
                    <RightTextWrapper>
                      <Text>
                        We suggest you save a copy of your{' '}
                        <Link
                          href={finTableUrl}
                          newTab
                          onClick={() =>
                            trackEventSender({
                              location: 'approval',
                              label: 'financial table',
                            })
                          }
                        >
                          financial table
                        </Link>{' '}
                        and{' '}
                        <Link
                          newTab
                          href={config.urls.conditionsOfUse}
                          onClick={() =>
                            trackEventSender({
                              location: 'approval',
                              label: 'Conditions of Use',
                            })
                          }
                        >
                          Conditions of Use
                        </Link>
                        .
                      </Text>
                    </RightTextWrapper>
                  </IconTextWrapper>
                </Stack>
                <Container bg="grey.t03" padding="4" margin="0 2 0 0" margin-xs={0}>
                  <ShadowedContainer>
                    <Container bg="white" padding="6">
                      <ImageHeaderWrapper>
                        <img src={iconShoppingBag} alt="Shopping online or In-store" />
                        <TextHeaderWrapper>
                          <Text as="h3" textStyle="heading5">
                            Start shopping Online & In-store today
                          </Text>
                        </TextHeaderWrapper>
                      </ImageHeaderWrapper>
                      <NumberedListWrapper>
                        <StyledNumberListItem style={{ background: ellipse }}>1</StyledNumberListItem>
                        <NumberedTextWrapper>
                          <StyledStrong>Set up your digital wallet to start shopping straight away</StyledStrong>
                        </NumberedTextWrapper>
                      </NumberedListWrapper>
                      <StyledUL marginLeft="24px" marginTop="4px" marginBottom="8px">
                        <li>Download the Latitude App from the App Store or Google Play.</li>
                        <li>Log in using your Latitude ID and password.</li>
                        <li>Add card to your mobile wallet.</li>
                        <li>Set your card PIN.</li>
                      </StyledUL>
                      {!isDJ() && (
                        <Container margin="1 0 2 10" padding="3 0 4 3">
                          <Flex width="100%">
                            <Container>
                              <Link appearance="icon" href={shoppingInformation.apple_store.url} newTab>
                                <DownloadAppImage
                                  src={DownloadApple}
                                  onClick={() =>
                                    trackEventSender({
                                      location: shoppingInformation.apple_store.location,
                                      label: shoppingInformation.apple_store.label,
                                    })
                                  }
                                />
                              </Link>
                            </Container>
                            <Container>
                              <Link appearance="icon" href={shoppingInformation.google_store.url} newTab>
                                <DownloadAppImage
                                  src={DownloadGoogle}
                                  onClick={() =>
                                    trackEventSender({
                                      location: shoppingInformation.google_store.location,
                                      label: shoppingInformation.google_store.label,
                                    })
                                  }
                                />
                              </Link>
                            </Container>
                          </Flex>
                        </Container>
                      )}
                      {isDJ() && (
                        <>
                          <NumberedListWrapper>
                            <StyledNumberListItem style={{ background: ellipse }}>2</StyledNumberListItem>
                            <NumberedTextWrapper>
                              <StyledStrong>
                                Link your {productName} account to enjoy benefits, including free shipping, gift
                                wrapping and more.
                              </StyledStrong>
                            </NumberedTextWrapper>
                          </NumberedListWrapper>
                          <StyledUL marginLeft="24px" marginTop="4px" marginBottom="8px">
                            <li>
                              Log in or create a {productName} account to{' '}
                              <Link
                                newTab
                                href="https://www.davidjones.com/default.aspx?Z=C&action=displaylogin&pr_id=promo_loyalty_1&pr_name=loyalty&pr_creative=signup&pr_position=hero"
                                onClick={() => {
                                  trackEventSender({
                                    location: 'approval',
                                    label: ' link your credit card',
                                    action: 'external-link',
                                  });
                                }}
                              >
                                link your credit card
                              </Link>{' '}
                              by following the onscreen prompts.
                            </li>
                          </StyledUL>
                        </>
                      )}
                      <NumberedListWrapper>
                        <StyledNumberListItem style={{ background: ellipse }}>
                          {isDJ() ? <>3</> : <>2</>}
                        </StyledNumberListItem>
                        <NumberedTextWrapper>
                          <StyledStrong>Make your first purchase</StyledStrong>
                        </NumberedTextWrapper>
                      </NumberedListWrapper>
                      <StyledUL marginLeft="24px" marginTop="4px" marginBottom="8px">
                        <li>
                          <StyledStrong>In-store:</StyledStrong> Tap and pay at the store checkout, using your digital
                          wallet.
                        </li>
                        <li>
                          <StyledStrong>Online:</StyledStrong> During checkout use Apple Pay, Google Pay or Samsung Pay.
                          Look out for these icons.
                        </li>
                      </StyledUL>
                      <Container margin="1 0 2 10">
                        <img src={appleGoogleSamsungPay} alt="Apple Google and Samsung Pay" />
                      </Container>
                    </Container>
                  </ShadowedContainer>
                </Container>
              </Grid>
            </Grid.Item>
          </Grid>
        </Container>
      </Container>
    );
  };
  return byCountry({
    AU: <OutcomeApprovedAU />,
    NZ: <OutcomeApprovedNZ />,
  });
}

function getCardImageMobile(productCode) {
  switch (productCode) {
    case PRODUCT_CODE_DAVID_JONES_PRESTIGE:
      return groupLFramePrestigeCardImageMobile;
    case PRODUCT_CODE_TWENTY_EIGHT_DEGREES:
      return groupLFrame28DCardImageMobile;
    case PRODUCT_CODE_DAVID_JONES_PREMIERE:
      return groupLFramePremiereCardImageMobile;
    case PRODUCT_CODE_GO:
      return groupLFrameGoCardImageMobile;
    case PRODUCT_CODE_NZ_GEM:
      return groupLFramePremiereCardImageMobile;
    default:
      throw new Error(`Unexpected product code: ${config.productCode}`);
  }
}
export { OutcomeApproved };
