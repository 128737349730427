import axios from 'axios';
import { useState } from 'react';
import jwtDecode from 'jwt-decode';
import {
  useStoreValue,
  OKTA_LOGIN_AND_SEND_OTP_ERROR,
  OKTA_SYNTHETIC_TOKEN_ERROR,
  OKTA_VERIFICATION_LOCKED_ERROR,
  OKTA_VERIFICATION_CODE_INCORRECT_ERROR,
  OKTA_VERIFICATION_ERROR,
  OKTA_ERROR_CODE_INVALID_PASSCODE_FOR_INCORRECT_OTP,
  OKTA_ERROR_CODE_INVALID_TOKEN_FOR_EXPIRED_OTP,
  OKTA_ERROR_CODE_SMS_WAIT_30_SECS,
  OKTA_ERROR_CODE_CAN_ONLY_BE_USED_ONCE,
  OKTA_OTP_TOO_OFTEN_ERROR,
  OKTA_EMPTY_MOBILE_NUMBER_ERROR,
  OKTA_SMS_FACTOR,
} from 'store';
import { oktaAuthClient } from 'library/oktaAuthClient';
import { RESPONSE_TYPE, SCOPES } from '_config/_constants/verificationPage';
import { getLatitudeId, getAuthHeader, byCountry, updateDataDogContext } from 'utils';
import { datadogRum } from '@datadog/browser-rum';
import { useEnv } from 'hooks';
import { useHandleApiErrors } from './useHandleApiErrors';
import { useGetUserInfo } from './useGetUserInfo';

const findOTPFactor = (item, factorType) => {
  return item.factors.find(factor => factor.provider === 'OKTA' && factor.factorType === factorType);
};

const useOktaClient = ({ onSuccess, onError } = {}) => {
  const getEnv = useEnv();
  const [store, updateStore] = useStoreValue();
  const { acquisition, oktaFactor } = store;
  const [loading, setLoading] = useState(false);
  const { handleError } = useHandleApiErrors('okta', onError);
  const { getUserInfo } = useGetUserInfo({ onError });

  const sendOTP = async (txn, factorType) => {
    const targetFactor = findOTPFactor(txn, factorType);
    await targetFactor.verify();
    updateStore({
      applicationErrors: null,
      oktaFactor: txn,
    });
  };

  const loginAndSendOTP = async (email, factor = OKTA_SMS_FACTOR, clearToken = true) => {
    try {
      setLoading(true);

      if (clearToken) {
        // in case browser had a cached token, clear it
        oktaAuthClient.tokenManager.clear();
        oktaAuthClient.closeSession();
      }

      const authData = await oktaAuthClient.signInWithCredentials({ username: email });
      await sendOTP(authData, factor);
      setLoading(false);
      onSuccess && onSuccess({});
      return true;
    } catch (error) {
      if (error.errorCode && error.errorCode === OKTA_ERROR_CODE_SMS_WAIT_30_SECS) {
        setLoading(false);
        handleError({ type: OKTA_OTP_TOO_OFTEN_ERROR, error }, false);
        return true;
      }
      setLoading(false);
      handleError({ type: OKTA_LOGIN_AND_SEND_OTP_ERROR, error });
      return false;
    }
  };

  const resendOTP = (factor = 'sms') => {
    return loginAndSendOTP(acquisition.contactDetails.emailAddress, factor);
  };

  const applySyntheticToken = async () => {
    const token = getEnv('REACT_APP_OKTA_TOKEN');
    if (!token && (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'staging')) {
      // eslint-disable-next-line
      console.warn('There is no synthetic token present');
      return true;
    }
    try {
      const decoded = jwtDecode(token);
      oktaAuthClient.tokenManager.setTokens({
        accessToken: {
          accessToken: token,
          claims: decoded,
        },
      });
      updateStore({
        applicationErrors: null,
        latitudeId: getLatitudeId(),
      });
      return true;
    } catch (error) {
      handleError({ type: OKTA_SYNTHETIC_TOKEN_ERROR, error });
      return false;
    }
  };

  const getUserStatuses = async () => {
    const method = 'GET';
    const url = `${process.env.REACT_APP_USER_REGISTER_URL}/statuses`;
    const headers = {
      Authorization: getAuthHeader(),
      'lfs-request-channel': byCountry({
        NZ: process.env.REACT_APP_OKTA_HEADER,
        AU: process.env.REACT_APP_OKTA_HEADER_AU,
      }),
      'Content-Type': 'application/json',
    };
    const response = await axios.request({
      method,
      url,
      headers,
    });

    const result = {
      isLatitudeIdPasswordSet: response?.data?.status_initial_password_set,
      isEmailVerified: response?.data?.status_email_verified,
    };
    return result;
  };

  const getAndSetAccessToken = async txn => {
    const result = await oktaAuthClient.token.getWithoutPrompt({
      sessionToken: txn.sessionToken,
      responseType: RESPONSE_TYPE,
      scopes: SCOPES,
    });

    const { tokens } = result;
    const decoded = jwtDecode(tokens.accessToken.accessToken);
    oktaAuthClient.tokenManager.setTokens(tokens);

    const userInfo = await getUserInfo(tokens.accessToken.accessToken);

    updateDataDogContext({ latitudeId: decoded.sub });

    const { mobile_phone: mobileNumber } = userInfo;

    if (mobileNumber === '') {
      handleError({
        type: OKTA_EMPTY_MOBILE_NUMBER_ERROR,
        error: new Error(
          `Okta profile mobile number is blank. Latitude ID: ${decoded.sub}. Application ID: ${store.applicationId}.`,
        ),
      });
    }

    updateStore({
      applicationErrors: null,
      latitudeId: decoded.sub,
      acquisition: {
        ...acquisition,
        contactDetails: {
          ...acquisition.contactDetails,
          mobileNumber,
        },
      },
    });
    datadogRum.setUser({ id: decoded.sub });
  };

  const verifyOTP = async (passCode, { checkIsLatitudeIdPasswordSet } = {}, factor = OKTA_SMS_FACTOR) => {
    try {
      setLoading(true);
      let targetFactor = findOTPFactor(oktaFactor, factor);
      // after page refreshing, verify function will lost from session storage
      if (!targetFactor.verify) {
        const authData = await oktaAuthClient.signInWithCredentials({
          username: acquisition.contactDetails.emailAddress,
        });
        targetFactor = findOTPFactor(authData, factor);
      }
      const verifyResult = await targetFactor.verify({
        passCode,
      });
      await getAndSetAccessToken(verifyResult);
      if (checkIsLatitudeIdPasswordSet) {
        const { isLatitudeIdPasswordSet, isEmailVerified } = await getUserStatuses();
        setLoading(false);
        onSuccess && (await onSuccess({ isLatitudeIdPasswordSet, isEmailVerified }));
      } else {
        setLoading(false);
        onSuccess && (await onSuccess({}));
      }
      return {};
    } catch (error) {
      setLoading(false);

      if (
        error.errorCode === OKTA_ERROR_CODE_INVALID_PASSCODE_FOR_INCORRECT_OTP ||
        error.errorCode === OKTA_ERROR_CODE_INVALID_TOKEN_FOR_EXPIRED_OTP ||
        error.errorCode === OKTA_ERROR_CODE_CAN_ONLY_BE_USED_ONCE
      ) {
        handleError({ type: OKTA_VERIFICATION_CODE_INCORRECT_ERROR, error }, false);
        return {
          errorMessage:
            'The verification code is incorrect. Please check the code, or resend a new code and try again.',
        };
      }
      if (error.errorCode === 'E0000069') {
        handleError({ type: OKTA_VERIFICATION_LOCKED_ERROR, error });
        return {};
      }

      handleError({ type: OKTA_VERIFICATION_ERROR, error });
      return {};
    }
  };

  return {
    loading,
    loginAndSendOTP,
    verifyOTP,
    resendOTP,
    applySyntheticToken,
  };
};

export { useOktaClient };
