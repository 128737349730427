import React from 'react';
import { Container, Flex, Grid } from 'basis';
import styled from '@emotion/styled';
import { Logo } from '../twenty-eight-degrees/Logo';

const HEIGHT_MAP = {
  default: 54,
  lg: 54,
};
const CenterTitle = styled.span`
  text-align: center;
  color: white;
  user-select: none;
  font-size: 18px;
  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.md})`} {
      font-size: 20px;
  `};
`;

export function GoHeader() {
  return (
    <header data-testid="au-28degrees-header">
      <Container bg="primary.blue.t100" height={HEIGHT_MAP.default} height-md={HEIGHT_MAP.lg} padding="5">
        <Grid cols="3">
          <Grid.Item colSpan="0">
            <Flex placeItems="center left">
              <Logo />
            </Flex>
          </Grid.Item>
          <Grid.Item colSpan="1">
            <Flex placeItems="center">
              <CenterTitle>Latitude GO Mastercard®</CenterTitle>
            </Flex>
          </Grid.Item>
        </Grid>
      </Container>
    </header>
  );
}

GoHeader.HEIGHT_MAP = HEIGHT_MAP;
