import React from 'react';
import styled from '@emotion/styled';
import { Modal } from 'components/modal/Modal';
import { Button, Container, Flex, List, Text, Link, Stack } from 'basis';
import { config } from '_config';

const personalInformationList = [
  `"sensitive information" (such as information or an opinion about an individual's racial or ethnic origin, membership of a political, professional or trade association and health information);`,
  `"credit information" (such as your identification details, information about your account, whether it is open or closed and the relevant dates, the type (such as a credit card or loan) and amount of credit, and your repayment history information, such as whether you have made, or missed, a payment on your credit facility); and`,
  `"credit eligibility information", which is information about you disclosed to us by a credit reporting body or information we derive from it about you.`,
];

const countries = [
  'China;',
  'Hungary;',
  'India;',
  'Japan;',
  'Mexico;',
  'New Zealand;',
  'Philippines;',
  'Poland;',
  'Singapore;',
  'Spain;',
  'South Korea;',
  'United Kingdom;',
  'United States of America.',
];

export const PrivacyNoticeModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{
        width: '886px',
      }}
    >
      <Container padding="3" padding-md="8">
        <Container padding="6 0">
          <Text textStyle="heading4" textStyle-md="heading4">
            Important Privacy Notice
          </Text>
        </Container>
        <PrivacyNoticeContent>
          <Stack gap="4">
            <Container>
              <Text>
                <strong>
                  By completing an Application Form for a credit facility, you acknowledge and consent to all of the
                  matters set out in this Privacy Notice, including the collection, use and disclosure of your personal
                  information by each member of the Latitude Group and other parties as described.
                </strong>
              </Text>
            </Container>

            <Container>
              <Text textStyle="heading5" as="h5">
                Personal information
              </Text>
            </Container>

            <Container>
              <Text textStyle="heading5" as="h5">
                In this Privacy Notice, references to "personal information" include:
              </Text>
            </Container>
            <Container padding="0 6">
              <List>
                {personalInformationList.map((item, index) => {
                  return <List.Item key={index}>{item}</List.Item>;
                })}
              </List>
            </Container>

            <Container>
              <Text textStyle="heading5" as="h5">
                Privacy Act
              </Text>
              <Text>
                In this Privacy Notice, a reference to the Privacy Act 1988 includes amendments to the Act, including
                the Privacy Amendment Act 2013 which introduces the Australian Privacy Principles.
              </Text>
            </Container>

            <Container>
              <Text textStyle="heading5" as="h5">
                Privacy Notice
              </Text>
              <Stack gap="4">
                <Text>
                  This Privacy Notice is provided by Latitude Finance Australia ABN 42 008 583 588 who is the credit
                  provider under the credit facility to which this Privacy Notice relates and is referred to in this
                  notice as the "Credit Provider". The Credit Provider and its related entities in Australia are
                  referred to in this notice as the "Latitude Group".
                </Text>
                <Text>
                  This Privacy Notice contains important information about the collection, use and disclosure of
                  personal information by the Latitude Group. Where personal information is collected, used and
                  disclosed for the same purposes by the Credit Provider and the other Latitude Group companies, the
                  word "<strong>we</strong>" or "<strong>us</strong>" is used. Where personal information is treated
                  differently, the organisation or group is identified separately.
                </Text>
                <Text>
                  In this Privacy Notice, "<strong>you / your</strong>" includes all borrowers, guarantors or other
                  relevant individuals (such as directors or shareholders) connected with a borrower and named in an
                  Application Form.
                </Text>
              </Stack>
            </Container>
            <Stack gap="4">
              <Text textStyle="heading5" as="h5">
                What personal information (including, as applicable, credit information) is collected{' '}
              </Text>
              <Text>
                Generally, <strong>we</strong> collect:
              </Text>
              <Container padding="2 6">
                <List>
                  <List.Item>
                    information <strong>you</strong> provide in the Application Form;
                  </List.Item>
                  <List.Item>
                    purchase and cash advance information (for example, the type of goods purchased and where they were
                    purchased);
                  </List.Item>
                  <List.Item>
                    information relating to <strong>your</strong> credit facility, <strong>your</strong> ongoing use of
                    that account and <strong>your</strong> credit limit;
                  </List.Item>
                  <List.Item>
                    information about <strong>your</strong> participation in the relevant associated loyalty program;
                  </List.Item>
                  <List.Item>
                    information <strong>you</strong> provide when registering, or using, <strong>your</strong> credit
                    facility on one of our websites;
                  </List.Item>
                  <List.Item>
                    information held by a service provider (for example, a loyalty program operator, a customer service
                    organisation such as a call centre, a rewards provider and others described below under{' '}
                    <strong>"Who your personal information may be shared with"</strong>);
                  </List.Item>
                  <List.Item>
                    sensitive information (in particular, health information) where it is necessary for{' '}
                    <strong>us</strong> to provide <strong>you</strong> with a Latitude Group product or a service
                    (including assessing hardship applications);
                  </List.Item>
                  <List.Item>
                    information from third parties (such as employers, government bodies, accountants, financial
                    institutions and related companies, here or overseas) where it is unreasonable or impracticable to
                    collect the information from <strong>you</strong>. Such circumstances may include where Latitude
                    seeks to verify the income or other details <strong>you</strong> provided in <strong>your</strong>{' '}
                    Application Form, to obtain information about facilities with other credit providers or where
                    dealers or retailers collect or disclose information about <strong>your</strong> purchases.
                  </List.Item>
                </List>
              </Container>
            </Stack>
            <Container>
              <Text textStyle="heading5" as="h5">
                Why personal information is collected, used and disclosed
              </Text>
              <Text>
                <strong>We</strong> collect, use and disclose <strong>your</strong> personal information:
              </Text>
              <Container padding="2 6">
                <List>
                  <List.Item>
                    to assess <strong>your</strong> application for a credit facility and if it is approved, for the
                    establishment and subsequent administration of the credit facility;
                  </List.Item>
                  <List.Item>
                    to share <strong>your</strong> personal information with related bodies for the purposes set out in
                    this Privacy Notice; and
                  </List.Item>
                  <List.Item>
                    to assess, establish and administer any credit related insurance product (including, if applicable,
                    assessing <strong>your</strong> application for insurance).
                  </List.Item>
                </List>
              </Container>
              <Text>
                <strong>We</strong> may also collect, use and disclose personal information for a number of purposes,
                including:
              </Text>
              <Container padding="2 6">
                <List>
                  <List.Item>
                    to perform administrative tasks and manage business operations related to the credit facility and
                    any associated loyalty program, including dispute resolution;
                  </List.Item>
                  <List.Item>to provide any associated loyalty program, if applicable;</List.Item>
                  <List.Item>
                    promote and provide benefits associated with the credit facility and discounts to which{' '}
                    <strong>you</strong> are entitled;
                  </List.Item>
                  <List.Item>
                    promote and provide products, services and offers of the Latitude Group and other Latitude related
                    entities and other organisations;
                  </List.Item>
                  <List.Item>for planning, product and service development and research purposes;</List.Item>
                  <List.Item>for risk assessment modelling;</List.Item>
                  <List.Item>
                    where <strong>you</strong> are a guarantor to support the applicants application for credit, to
                    allow Latitude Group to assess whether to accept <strong>you</strong> as a guarantor for the credit
                    facility, for keeping <strong>you</strong> informed about the status of the credit facility, and to
                    enforce the guarantee;
                  </List.Item>
                  <List.Item>for fraud and crime prevention and investigation;</List.Item>
                  <List.Item>
                    to comply with laws that may require or authorise <strong>us</strong> to obtain information about
                    you, such as the Anti-Money Laundering and Counter-Terrorism Financing Act 2006 and other anti-money
                    laundering legislation (for example, for identity verification), the National Consumer Credit
                    Protection Act 2009, the Personal Property Securities Act 2009 (for example, if relevant, for search
                    and registration purposes) and other regulatory legislation (for example, requiring{' '}
                    <strong>us</strong> to maintain client and transaction records, to provide information relating to
                    loans to the Australian Prudential Regulation Authority and to make reports and provide other
                    information to regulators) and the Taxation Administration Act 1953, the Income Tax Assessment Act
                    1997 and other taxation laws (for example, to comply with information requests issued by the
                    Commissioner of Taxation);
                  </List.Item>
                  <List.Item>
                    to assist in finalising <strong>your</strong> application for <strong>your</strong> credit facility
                    or making a purchase on <strong>your</strong> credit facility; and
                  </List.Item>
                  <List.Item>
                    for other purposes as listed in our Privacy Policy and our Credit Reporting Policy (our Credit
                    Reporting Policy also notes limitations that may apply to the purposes for which <strong>we</strong>{' '}
                    may use <strong>your</strong> credit information).
                  </List.Item>
                </List>
              </Container>
            </Container>
            <Container>
              <Text textStyle="heading5" as="h5">
                Consequences for you if your personal information is not provided to us
              </Text>
              <Text>
                If this personal information about <strong>you</strong> is not provided to <strong>us</strong>, it may
                result in the Credit Provider and/or the Latitude Group being unable to provide the credit facility or
                the benefits associated with it. The Credit Provider and/or the Latitude Group may also be unable to
                process <strong>your</strong> application, or to establish or administer the credit facility.
              </Text>
            </Container>
            <Container>
              <Text textStyle="heading5" as="h5">
                Collection, use and disclosure by Latitude Group of your credit information and credit eligibility
                information
              </Text>
              <Stack gap="4">
                <Text>
                  <strong>We</strong> may obtain <strong>your</strong> credit reporting information from credit
                  reporting bodies in circumstances permitted by the Privacy Act 1988, for example to assess{' '}
                  <strong>your</strong> application for consumer or commercial credit and to collect payments which are
                  overdue for consumer or commercial credit.
                </Text>
                <Text>
                  <strong>We</strong> use credit eligibility information for purposes permitted under the Privacy Act
                  1988, including for the purposes of assessing <strong>your</strong> initial and ongoing application
                  and availability for credit and for internal management purposes.
                </Text>
                <Text>
                  If <strong>you</strong> are a guarantor, <strong>you</strong> agree that <strong>we</strong> may
                  obtain credit reporting information about <strong>you</strong> from credit reporting bodies for the
                  purposes of assessing whether to accept <strong>you</strong> as a guarantor in relation to credit
                  provided by <strong>us</strong> to an applicant or credit for which an application has been made to{' '}
                  <strong>us</strong> by an applicant.
                </Text>
                <Text>
                  <strong>We</strong> may disclose personal information about <strong>you</strong> to credit reporting
                  bodies (including credit information, such as details about the credit that <strong>we</strong>{' '}
                  provide to <strong>you</strong>, <strong>your</strong> repayment history and any repayment defaults).
                </Text>
                <Text>
                  The credit reporting bodies that <strong>we</strong> exchange credit information with, and where{' '}
                  <strong>you</strong> can find their privacy policies (which sets out how they manage credit-related
                  personal information) are:
                </Text>
                <Text>
                  - Equifax Australia{' '}
                  <Link href="https://www.equifax.com.au/" newTab>
                    www.equifax.com.au
                  </Link>{' '}
                  Telephone - 1300 921 621
                </Text>
                <Text>
                  - Illion{' '}
                  <Link href="https://www.checkyourcredit.com.au" newTab>
                    www.checkyourcredit.com.au
                  </Link>{' '}
                  Telephone - 1300 783 684
                </Text>
                <Text>
                  - Experian{' '}
                  <Link href="https://www.experian.com.au" newTab>
                    www.experian.com.au
                  </Link>{' '}
                  Telephone - 1300 783 684
                </Text>
                <Text>
                  These credit reporting bodies may include the information in reports provided to credit providers to
                  assist them to assess <strong>your</strong> credit worthiness. Please be aware that{' '}
                  <strong>you</strong> have a right to request that these credit reporting bodies do not:
                </Text>
                <List>
                  <List.Item>
                    use <strong>your</strong> credit reporting information for the purposes of pre-screening of direct
                    marketing by a credit provider; and
                  </List.Item>
                  <List.Item>
                    use or disclose <strong>your</strong> credit reporting information, if <strong>you</strong> believe
                    on reasonable grounds that <strong>you</strong> have been or are likely to be a victim of fraud.
                  </List.Item>
                </List>
                <Text>
                  <strong>You</strong> should also be aware that:
                </Text>
                <List>
                  <List.Item>
                    if <strong>you</strong> fail to meet <strong>your</strong> payment obligations in relation to
                    consumer credit or commit a serious credit infringement, the Credit Provider may be entitled to
                    disclose this to the credit reporting body; and
                  </List.Item>
                  <List.Item>
                    <strong>you</strong> have a right to access the information from the Credit Provider, to request
                    that the Credit Provider correct the information and to make a complaint to the Credit Provider
                    (please see below for more details about access, correction and complaints).
                  </List.Item>
                </List>
                <Text>
                  The Credit Reporting Policy on our website includes the most up-to-date information about how{' '}
                  <strong>we</strong> handle <strong>your</strong> credit-related personal information, including
                  information about the credit reporting bodies to whom <strong>we</strong> are likely to disclose{' '}
                  <strong>your</strong> personal information. Please view our Credit Reporting Policy under the privacy
                  section at www.latitudefinancial.com.au/privacy or contact <strong>us</strong> on
                  {config.phoneNumbers.assistance2}.
                </Text>
                <Text>
                  Without limiting any other consents contained in this Privacy Notice, <strong>you</strong> agree that{' '}
                  <strong>we</strong> may share information about <strong>your</strong> credit arrangements and credit
                  eligibility information about <strong>you</strong> with any credit providers named by{' '}
                  <strong>you</strong> in the Application Form and any credit providers named in a credit report
                  obtained by <strong>us</strong> and issued by a credit reporting body in a manner and for purposes
                  that comply with the Privacy Act 1988, including to assess your application for credit and{' '}
                  <strong>your</strong> credit worthiness. Subject to the Privacy Act 1988, <strong>we</strong> may also
                  share information concerning a credit facility granted to <strong>you</strong> or the conduct of that
                  credit facility.
                </Text>
              </Stack>
            </Container>
            <Container>
              <Text textStyle="heading5" as="h5">
                Who your personal information may be shared with?
              </Text>
              <Text>
                For the purposes listed above or as described, <strong>we</strong> may share <strong>your</strong>{' '}
                personal information with the following (as well as otherwise permitted by the Privacy Act 1988):
              </Text>
              <Container padding="2 6">
                <List>
                  <List.Item>
                    any Introducers (including brokers, dealers and retail partners), if applicable. To the extent that
                    an Introducer is acting on <strong>your</strong> behalf, <strong>you</strong> authorise the
                    Introducer to receive personal information, including credit eligibility information, from{' '}
                    <strong>us</strong>;
                  </List.Item>
                  <List.Item>
                    any employer named in the Application Form to verify the personal information provided;
                  </List.Item>
                  <List.Item>
                    service providers such as customer service organisations, call centres, mailing houses, researchers,
                    collections agents, data analysts, professional advisers (such as accountants), auditors,
                    organisations providing services and rewards relevant to any associated loyalty program, delivery
                    companies, mailing organisations;
                  </List.Item>
                  <List.Item>
                    other organisations as required or authorised by law, for example, to government or regulatory
                    bodies for the prevention or detection of unlawful activities;
                  </List.Item>
                  <List.Item>
                    members of the Latitude Group and Latitude related entities in Australia and overseas;
                  </List.Item>
                  <List.Item>
                    credit reporting bodies or other businesses or other organisations that provide personal credit or
                    commercial credit information as described above in the section "Collection, use and disclosure by
                    Latitude Group of <strong>your</strong> credit information and credit eligibility information";
                  </List.Item>
                  <List.Item>other financial institutions, government bodies and credit providers;</List.Item>
                  <List.Item>card schemes such as MasterCard;</List.Item>
                  <List.Item>
                    <strong>your</strong> nominated referees;
                  </List.Item>
                  <List.Item>
                    additional cardholders or other persons (if any) authorised by <strong>you</strong> to operate or
                    access <strong>your</strong> credit facility;
                  </List.Item>
                  <List.Item>
                    organisations through whom <strong>you</strong> choose to make payments to Latitude Group;
                  </List.Item>
                  <List.Item>
                    providers of services related to the credit facility such as statement producers, debt collection
                    agencies and card manufacturers; and
                  </List.Item>
                  <List.Item>
                    organisations wishing to acquire an interest in any part of Latitude's business for assessing or
                    implementing any such acquisition.
                  </List.Item>
                </List>
              </Container>
            </Container>
            <Container>
              <Text textStyle="heading5" as="h5">
                Safeguarding personal information
              </Text>
              <Text>
                <strong>We</strong> will take reasonable steps to protect personal information about{' '}
                <strong>you</strong> that they hold and transmit, from misuse, interference and loss and from
                unauthorised access, modification and disclosure.
              </Text>
            </Container>
            <Container>
              <Text textStyle="heading5" as="h5">
                Transfer or disclosure of your personal information overseas
              </Text>
              <Text>
                In some circumstances, for the purposes set out in this Privacy Notice, <strong>your</strong> personal
                information, credit information and credit eligibility information may be disclosed by{' '}
                <strong>us</strong> to organisations located overseas and which do not have an Australian Link (for
                example, a disclosure to an overseas recipient may be necessary for operational reasons - such as
                because <strong>you</strong> have requested an international payment to be made or another product or
                service that involves an international element - or to comply with foreign legal or regulatory
                requirements). <strong>We</strong> may also use service providers based overseas. Where such disclosures
                occur, arrangements will be put in place to protect <strong>your</strong> personal information. It is
                not practicable to list every country in which such recipients are located but it is likely that the
                countries to which <strong>your</strong> information may be disclosed are as follows:
              </Text>
              <Container padding="2 6">
                <List>
                  {countries.map((item, index) => (
                    <List.Item key={index}>{item}</List.Item>
                  ))}
                </List>
              </Container>
            </Container>
            <Container>
              <Text textStyle="heading5" as="h5">
                Transfer of Latitude Group's rights
              </Text>
              <Text>
                The Credit Provider and the Latitude Group may, without notice to <strong>you</strong> or obtaining{' '}
                <strong>your</strong> consent:
              </Text>
              <Container padding="2 6">
                <List>
                  <List.Item>assign any of its rights under the credit facility; and</List.Item>
                  <List.Item>
                    give information about the credit facility and <strong>your</strong> obligations under it to any
                    assignee, or anyone who is considering becoming an assignee.
                  </List.Item>
                </List>
              </Container>
            </Container>
            <Container>
              <Text textStyle="heading5" as="h5">
                Access to personal information
              </Text>
              <Stack gap="4">
                <Text>
                  <strong>You</strong> may contact <strong>us</strong> to request access to <strong>your</strong>{' '}
                  personal information held by <strong>us</strong> by:
                </Text>
                <Container padding="2 6">
                  <List>
                    <List.Item>
                      contacting Latitude at 130 Lonsdale Street Melbourne, VIC 3000, Australia, Attention: Privacy
                      Officer;
                    </List.Item>
                    <List.Item>
                      Or call <strong>us</strong> on {config.phoneNumbers.assistance2}.
                    </List.Item>
                  </List>
                </Container>
                <Text>
                  Both our Privacy and Credit Reporting Policies are available under the privacy section at{' '}
                  <Link href="https://www.latitudefinancial.com.au/privacy" newTab>
                    www.latitudefinancial.com.au/privacy
                  </Link>
                  . These policies contain further information about how <strong>you</strong> may access the personal
                  information <strong>we</strong> hold about <strong>you</strong> .
                </Text>
                <Text>
                  A charge may apply for providing <strong>you</strong> with access to <strong>your</strong> personal
                  information.
                </Text>
              </Stack>
            </Container>
            <Container>
              <Text textStyle="heading5" as="h5">
                How to seek correction of your personal, credit, or credit eligibility information
              </Text>
              <Stack gap="4">
                <Text>
                  If <strong>you</strong> believe that the information that <strong>we</strong> hold about{' '}
                  <strong>you</strong> is incorrect in any way, please call <strong>us</strong> on{' '}
                  {config.phoneNumbers.assistance2} or write to <strong>us</strong> at GPO Box 1818, Melbourne VIC 3001.
                </Text>
                <Text>
                  Our Privacy Policy and Credit Reporting Policy contain further information about how{' '}
                  <strong>you</strong> may seek the correction of personal information (including credit eligibility
                  information) <strong>we</strong> hold about you.
                </Text>
              </Stack>
            </Container>
            <Container>
              <Text textStyle="heading5" as="h5">
                How we will deal with a complaint?
              </Text>
              <Stack gap="4">
                <Text>
                  Our Privacy Policy and Credit Reporting Policy also contains information as to how{' '}
                  <strong>you</strong> can complain about a breach by <strong>us</strong> of the Privacy Act (including
                  the credit reporting provisions in Part IIIA) or the Credit Reporting Code and how <strong>we</strong>{' '}
                  will deal with such a complaint.
                </Text>
                <Text>
                  {' '}
                  <strong>We</strong> aim to review and resolve <strong>your</strong> enquiries as quickly and fairly as
                  possible. <strong>We</strong> will keep you informed of our progress. <strong>We</strong> will do all{' '}
                  <strong>we</strong> can to provide <strong>you</strong> with the most suitable response specific to{' '}
                  <strong>your</strong> situation and ensure, where possible, that <strong>your</strong> complaint is
                  resolved to <strong>your</strong> satisfaction.
                </Text>
                <Text>
                  The Latitude Group Customer Resolution Team can be contacted on 1300 369 340 or <strong>you</strong>{' '}
                  can write to them at: GPO Box 2256 Melbourne VIC 3001.
                </Text>
              </Stack>
            </Container>
            <Container>
              <Text textStyle="heading5" as="h5">
                Information about additional cardholders and other persons
              </Text>
              <Stack gap="4">
                <Text>
                  If <strong>you</strong> nominate an additional <strong>cardholder</strong> or provide personal
                  information about any other individual, such as a referee, <strong>you</strong> must first ensure that
                  the person concerned:
                </Text>
                <List>
                  <List.Item>has seen this Privacy Notice and understood its contents; and</List.Item>
                  <List.Item>
                    has separately agreed to their personal information being collected, used and disclosed in
                    accordance with this Privacy Notice.
                  </List.Item>
                </List>
                <Text>
                  If <strong>you</strong> nominate an additional <strong>cardholder</strong> under 18 years of age who
                  does not have sufficient maturity to understand this Privacy Notice, <strong>you</strong> must ensure
                  that a parent or guardian agrees to this Privacy Notice on their behalf.
                </Text>
              </Stack>
            </Container>
            <Container>
              <Text textStyle="heading5" as="h5">
                Direct marketing opt-out
              </Text>
              <Stack gap="4">
                <Text>
                  The consents given by <strong>you</strong> in relation to the use of <strong>your</strong> personal
                  information for direct marketing apply to contacting you by all relevant means (for example, by
                  letter, email or phone) and apply for an indefinite period of time, unless <strong>you</strong>{' '}
                  expressly withdraw those consents by notice to <strong>us</strong>.
                </Text>
                <Text>
                  If <strong>you</strong> do not want to receive direct marketing information from the Credit Provider{' '}
                  <strong>you</strong> may call our Customer Solutions Team on {config.phoneNumbers.assistance2}.
                </Text>
              </Stack>
            </Container>
            <Container>
              <Text textStyle="heading5" as="h5">
                Email Communication
              </Text>
              <Stack gap="4">
                <Text>
                  If <strong>you</strong> provide Latitude Group with an email address, <strong>you</strong> consent to
                  electronic communications being sent to <strong>you</strong> via that email address, including notices
                  and reminders. To protect <strong>your</strong> privacy, <strong>we</strong> recommend that any email
                  address you provide to <strong>us</strong> be <strong>your</strong> personal email address rather
                  than, for example, an email address accessible by <strong>your</strong> work colleagues or family
                  members.
                </Text>
              </Stack>
            </Container>

            <Container>
              <Link newTab href="https://www.latitudefinancial.com.au/privacy">
                Privacy & Credit Reporting Policy
              </Link>
              {' | '}
              <Link newTab href="https://www.latitudefinancial.com.au/terms-and-conditions">
                Terms and Conditions
              </Link>
              {' | '}
              <Link newTab href="https://www.latitudefinancial.com.au/security">
                Security
              </Link>
            </Container>
            <Container>
              <Text>
                Credit provided by Latitude Finance Australia ABN 42 008 583 588 Australian Credit Licence number
                392145, 130 Lonsdale Street Melbourne, VIC 3000, Australia.
              </Text>
            </Container>
          </Stack>
        </PrivacyNoticeContent>

        <Flex placeItems="center">
          <Container padding="8 2 4 2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};

const PrivacyNoticeContent = styled.div`
  li::before {
    background-color: black;
  }

  overflow-y: auto;
  height: 800px;

  p {
    margin-bottom: 4px;
  }

  h5 {
    margin-bottom: 16px;
  }
`;
