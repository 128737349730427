import { DEV_FEATURES } from '__dev/devFeatures';
import {
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  DAVID_JONES_FEATURE_PREFIX,
  TWENTY_EIGHT_DEGREES_FEATURE_PREFIX,
  GO_FEATURE_PREFIX,
  config,
  COUNTRY_CODE_AU,
} from '_config';
import { mapValues, merge, startsWith, upperFirst } from 'lodash';
import { byCard, getProductInfo, isDJ, isNZ } from 'utils/byConfig';
import { getSessionStorage } from 'utils/sessionStorage';
import {
  DEFAULT_FEATURES_28D,
  DEFAULT_FEATURES_DJ,
  DEFAULT_FEATURES_GO,
  DEFAULT_FEATURES_NZ,
  defaultMaintenanceMessage,
  defaultNzRatesAndFees,
} from './defaultValues';

export * from './defaultValues';

export const sharedFeatures = ['maintenanceMessage'];

export const featuresStorageKey = 'features';

export const getFeatures = () => {
  if (
    DEV_FEATURES.USE_LOCAL_FEATURES || // when turn on USE_LOCAL_FEATURES
    process.env.REACT_APP_LAUNCH_DARKLY_INACTIVE // when unit test running any where
  ) {
    return getEnabledFeatures();
  }

  return getSessionStorage('launchDarklyFlags');
};

export function convertToFeatureKey(featureKey) {
  if (sharedFeatures.includes(featureKey)) {
    return featureKey;
  }

  if (isNZ()) {
    return featureKey;
  }

  if (isDJ()) {
    return `${DAVID_JONES_FEATURE_PREFIX}${upperFirst(featureKey)}`;
  }

  const prefix = getProductInfo().featureToggleKeyPrefix;
  return `${prefix}${upperFirst(featureKey)}`;
}

export function convertToFeatureName(featureName) {
  switch (config.productCode) {
    case PRODUCT_CODE_DAVID_JONES_PREMIERE:
    case PRODUCT_CODE_DAVID_JONES_PRESTIGE:
      return startsWith(featureName, DAVID_JONES_FEATURE_PREFIX) ? featureName.substring(2) : featureName;
    case PRODUCT_CODE_TWENTY_EIGHT_DEGREES:
      return startsWith(featureName, TWENTY_EIGHT_DEGREES_FEATURE_PREFIX) ? featureName.substring(5) : featureName;
    case PRODUCT_CODE_GO:
      return startsWith(featureName, GO_FEATURE_PREFIX) ? featureName.substring(2) : featureName;
    default:
      return featureName;
  }
}

const nzEnabledFeatures = {
  ...mapValues(DEFAULT_FEATURES_NZ, () => true),
  nzRatesAndFees: defaultNzRatesAndFees,
};

export const djEnabledFeatures = {
  ...mapValues(DEFAULT_FEATURES_DJ, () => true),
  maintenanceMessage: defaultMaintenanceMessage,
};

export const au28DEnabledFeatures = {
  ...mapValues(DEFAULT_FEATURES_28D, () => true),
  maintenanceMessage: defaultMaintenanceMessage,
};

export const goEnabledFeatures = {
  ...mapValues(DEFAULT_FEATURES_GO, () => true),
  maintenanceMessage: defaultMaintenanceMessage,
};

const getEnabledFeatures = () => {
  const storageFeatures = getSessionStorage(featuresStorageKey) || {};
  let enabledFeatures = nzEnabledFeatures;
  switch (config.productCode) {
    case PRODUCT_CODE_DAVID_JONES_PREMIERE:
    case PRODUCT_CODE_DAVID_JONES_PRESTIGE:
      enabledFeatures = djEnabledFeatures;
      break;
    case PRODUCT_CODE_TWENTY_EIGHT_DEGREES:
      enabledFeatures = au28DEnabledFeatures;
      break;
    case PRODUCT_CODE_GO:
      enabledFeatures = goEnabledFeatures;
      break;
    default:
      break;
  }

  return merge({}, enabledFeatures, storageFeatures);
};

export function isFeatureOn(feature) {
  return getFeatureValue(feature) === true;
}

export function getFeatureValue(feature) {
  const featureKey = convertToFeatureKey(feature);

  if (getFeatures()) {
    return getFeatures()[featureKey];
  }
  return null;
}

export function getRatesAndFees() {
  const features = getFeatures();
  if (isDJ()) {
    if (!config.productCode) {
      // either on page dj-product-comparison or resume-your-application with deprecated params `cardType=dj` without `productCode`
      return {};
    }

    return byCard({
      [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: features.prestigeRatesAndFees,
      [PRODUCT_CODE_DAVID_JONES_PREMIERE]: features.premiereRatesAndFees,
    });
  }
  if (isNZ()) {
    return features.nzRatesAndFees; // TODO: make NZ feature keys consistent with prefix
  }

  return getFeatureValue('ratesAndFees');
}

export const isDCLOn = () => config.countryCode === COUNTRY_CODE_AU || isFeatureOn('dynamicCreditLimit');
