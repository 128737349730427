import React, { useMemo } from 'react';
import { BasisProvider } from 'basis';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@emotion/react';
import { useSiteTracking } from 'react-event-tracker';
import { trackingConfig } from '_config/tealiumConfig';
import { byCard, byCountry } from 'utils';
import { useApplyAndBuyQueryParams } from 'hooks/useApplyAndBuyQueryParams';
import { useLaunchDarklyFlags } from 'hooks/useLaunchDarklyFlags';
import {
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_NZ_GEM,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
} from '_config';
import { bffGraphqlClient } from 'gql';
import { isFeatureOn } from 'featureToggles';
import { getInitialState, StoreProvider } from './store';
import { nzTheme, davidJonesTheme, twentyEightDegreesTheme } from './theme';
import { DevFeatureToggle } from './__dev';
import { Routes } from './route';
import { ErrorBoundary, Boundary, Maintenance } from './components';

export function App() {
  const { SiteTracking } = useSiteTracking(trackingConfig);
  const applyAndBuy = useApplyAndBuyQueryParams();
  const initialStoreState = useMemo(() => ({ ...getInitialState(), applyAndBuy }), [applyAndBuy]);
  useLaunchDarklyFlags();
  const au28Degrees = {
    ...twentyEightDegreesTheme,
    backgroundColor: 'white',
  };
  const theme = byCard({
    [PRODUCT_CODE_NZ_GEM]: nzTheme,
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: davidJonesTheme,
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: davidJonesTheme,
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: au28Degrees,
    [PRODUCT_CODE_GO]: au28Degrees,
    default: byCountry({ AU: davidJonesTheme, NZ: nzTheme }),
  });
  return (
    <ApolloProvider client={bffGraphqlClient}>
      <HelmetProvider>
        <SiteTracking>
          <BasisProvider theme={theme}>
            <Boundary>
              <ThemeProvider theme={theme}>
                <StoreProvider initialState={initialStoreState}>
                  <ErrorBoundary>
                    {isFeatureOn('maintenancePage') ? <Maintenance /> : <Routes />}
                    <DevFeatureToggle />
                  </ErrorBoundary>
                </StoreProvider>
              </ThemeProvider>
            </Boundary>
          </BasisProvider>
        </SiteTracking>
      </HelmetProvider>
    </ApolloProvider>
  );
}
