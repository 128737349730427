import React from 'react';
import { Accordion, Text, Container, Stack, List } from 'basis';
import {
  config,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
} from '_config';
import { byCard } from 'utils';
import { EarnRateTiers } from 'components/earn-rate-tiers/EarnRateTiers';
import { getRatesAndFees } from 'featureToggles';

export const BenefitInfo = () => {
  const ratesAndFees = getRatesAndFees();

  const creditCardBenefits = {
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: [
      {
        title: 'Everyday credit card ',
        content: 'with the ability to earn David Jones Points.',
      },
      {
        title: 'Annual fee - ',
        content: `$${ratesAndFees?.annualFee}`,
      },
      {
        title: 'Interest rates - ',
        content: `${ratesAndFees?.purchaseAnnualRate}% p.a. on everyday purchases and ${ratesAndFees?.cashAnnualRate}% p.a. on cash advances`,
      },
      {
        title: 'Earn David Jones Points -',
      },
      {
        title: 'Exclusive access to David Jones sales, previews, ',
        content: 'complimentary express delivery, gift wrapping and more.',
      },
      {
        title: 'Discounts ',
        content: 'on selected hotel bookings through Expedia, Wotif, & Booking.com',
      },
    ],
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: [
      {
        title: 'Everyday credit card ',
        content: 'with the ability to earn David Jones Points.',
      },
      {
        title: 'Annual fee - ',
        content: `$${ratesAndFees?.annualFee}`,
      },
      {
        title: 'Interest rates - ',
        content: `${ratesAndFees?.purchaseAnnualRate}% p.a. on everyday purchases and ${ratesAndFees?.cashAnnualRate}% p.a. on cash advances`,
      },
      {
        title: 'Earn David Jones Points -',
      },
      {
        title: 'Exclusive access to David Jones sales, previews and events, ',
        content: 'complimentary same day delivery, gift wrapping and alterations when shopping instore at David Jones.',
      },
      {
        title: 'Complimentary travel insurance',
      },
      {
        title: 'Discounts ',
        content: 'on selected hotel bookings through Expedia, Wotif, & Booking.com',
      },
      {
        title: 'Free ',
        content: 'additional cardholders',
      },
    ],
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: [
      {
        content:
          'No international transaction or currency conversion fees on purchases online and overseas. T&Cs, fees and charges apply.',
      },
      {
        content:
          'Great foreign exchange rates set by our partners at Mastercard® so you get foreign currencies at the same low rate we do.',
      },
      {
        content: 'Spend on everyday offers - Credit back offers, discounts and deals on big brands at home and abroad.',
      },
      {
        content:
          'Small comforts with free Flight Delay Pass - Food, drinks and free Wi-Fi in a selection of airport lounges if your flight is delayed 2+ hours. T&Cs apply.',
      },
      {
        content:
          'Stay in touch with free Global Data Roaming with Flexiroam - 3GB free data each calendar year & 15% off any extra data purchased in over 150 countries with Flexiroam. T&Cs apply.',
      },
      { content: 'Fraud protection for peace of mind.' },
      { content: 'Add an additional cardholder at no extra fee.' },
    ],
  };

  return (
    <>
      <Accordion testId="feature-benefit-message">
        <Accordion.Item>
          <Accordion.Item.Header>
            <Container padding="5 0">
              <Text textStyle="body1">
                {byCard({
                  [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: (
                    <>
                      <b>View credit card benefits</b> for more information on benefits, repayments, rates and fees
                    </>
                  ),
                  default: (
                    <>
                      <b>View credit card features and benefits</b> for more information on rewards, repayments, rates
                      and fees
                    </>
                  ),
                })}
              </Text>
            </Container>
          </Accordion.Item.Header>
          <Accordion.Item.Content padding="6">
            <Stack gap="4">
              <Text textStyle="heading5">
                {config.productName} credit card{' '}
                {byCard({
                  [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: 'benefits',
                  default: 'features and benefits',
                })}
              </Text>
              {byCard({
                [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: null,
                default: <Text>Please ensure the {config.productName} credit card is right for you.</Text>,
              })}
              <List textStyle="body1">
                {creditCardBenefits[config.productCode]?.map((benefit, index) => (
                  <List.Item key={index}>
                    <b>{benefit.title}</b>
                    {benefit.content}
                    {index === 3 ? <EarnRateTiers productCode={config.productCode} /> : null}
                  </List.Item>
                ))}
              </List>
            </Stack>
          </Accordion.Item.Content>
        </Accordion.Item>
      </Accordion>
      <Container margin="5 0 0 0">
        <Text>*We may ask you to supply supporting information</Text>
      </Container>
    </>
  );
};
