import React from 'react';
import { Text, Container } from 'basis';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { config } from '_config';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  width: 100%;
`;

const ContentWrapper = styled.div`
  max-width: 450px;
  margin: 16px 8px;
`;

export function DJSystemUnavailable({ applicationRef, isPreSubmission }) {
  const theme = useTheme();

  return (
    <Container padding="8 0" testId="dj-system-unavailable" hasBreakpointWidth>
      <ErrorWrapper>
        <ContentWrapper>
          <Container padding="4 0" margin-md="4 0">
            <Text as="h5" textStyle="heading5" color={theme.headingColor} align="left">
              Oops! Something went wrong.
            </Text>
          </Container>

          {applicationRef && (
            <Container padding="0 0 4 0">
              <Text>
                Application reference number: <b>{applicationRef}</b>
              </Text>
            </Container>
          )}
          {isPreSubmission ? (
            <Container margin="2 0">
              <Text>
                Something has gone wrong at our end, but we are working hard to resolve the issue. Please try and start
                a new application shortly.
              </Text>
            </Container>
          ) : (
            <Container margin="2 0">
              <Text margin="0 0 4 0">
                Something has gone wrong at our end, but we are working hard to resolve the issue. Please try to access
                your application again shortly.
              </Text>
              <Text>
                To continue with your application simply click the link in your email and have your mobile phone close
                by to access your application.
              </Text>
            </Container>
          )}

          <Container padding="10 0 0 0">
            <Text align="left">Thank you for your patience.</Text>
          </Container>
          <Container padding="8 0 0 0">
            <Text align="left">The {config.productName} credit card team</Text>
          </Container>
        </ContentWrapper>
      </ErrorWrapper>
    </Container>
  );
}
