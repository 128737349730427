import { useCallback } from 'react';
import { useEventTracking } from 'react-event-tracker';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import { API_ERRORS, DATADOG_CUSTOM_SOURCE, useStoreValue } from 'store';
import { datadogRum } from '@datadog/browser-rum';
import { useRoutePageInfo } from './useRoutePageInfo';

const isApiError = error => {
  if (error?.type && API_ERRORS.includes(error.type)) {
    return true;
  }
  return false;
};

export const useErrorTracking = label => {
  const [store] = useStoreValue();
  const { trackEvent } = useEventTracking();
  const { pageTitle } = useRoutePageInfo();

  const handleErrorTracking = useCallback(
    (error, errorName) => {
      const errorLabel =
        errorName ||
        label ||
        (typeof error === 'string' && error) ||
        (typeof error === 'object' && error?.error?.response?.data?.message) ||
        'Request Error';

      trackEvent({
        event: {
          category: 'application',
          action: 'application-error',
          location: pageTitle,
          label: errorLabel,
        },
        ...getDataLayerElements({
          ...store,
          applicationErrors: error,
          applicationStatus: 'Error',
        }),
      });

      if (!isApiError(error)) {
        datadogRum.addError(error, undefined, DATADOG_CUSTOM_SOURCE);
      }

      if (process.env.REACT_APP_ENV !== 'prod') {
        // eslint-disable-next-line
        console.warn(error);
      }
    },
    [label, trackEvent, pageTitle, store],
  );

  return {
    handleErrorTracking,
  };
};

export const useErrorTrackingAndUpdateStore = label => {
  const [, updateStore] = useStoreValue();
  const { handleErrorTracking } = useErrorTracking(label);

  const handleErrorCallback = useCallback(
    error => {
      updateStore({ applicationErrors: error });
      handleErrorTracking(error);
    },
    [updateStore, handleErrorTracking],
  );

  return {
    handleErrorCallback,
  };
};
