import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { byCountry } from 'utils/byConfig';
import { config } from '_config';
import { useHandleApiErrors } from './useHandleApiErrors';

export const INIT_APPLICATION = gql`
  mutation initApplication($input: InitApplicationInput!) {
    initApplication(input: $input) {
      applicationId
      applicationRef
    }
  }
`;

function useInitApplication({ onSuccess, onError }) {
  const { handleError } = useHandleApiErrors('hook:useInitApplication', onError);

  const [initApplication, { loading }] = useMutation(INIT_APPLICATION, {
    onCompleted: data => {
      onSuccess(data.initApplication);
    },
    onError: err => {
      handleError(err);
    },
  });

  const submit = useCallback(
    formData => {
      if (formData) {
        const input = byCountry({
          AU: { consentGiven: formData.consentGiven, productCode: config.productCode },
          NZ: { consentGiven: formData.consentGiven },
        });
        initApplication({ variables: { input } }).then();
      }
    },
    [initApplication],
  );

  return {
    submit,
    loading,
  };
}

export { useInitApplication };
