import React from 'react';
import { isSafari } from 'react-device-detect';
import { getRatesAndFees } from 'featureToggles';
import { byCard, getProductInfo } from 'utils';
import {
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  config,
  setConfig,
} from '../../../../_config';
import { DjFinancialTable } from './DjFinancialTable';
import { Au28DFinancialTable } from './Au28DFinancialTable';

export function FinancialTable() {
  const queryParams = new URLSearchParams(window.location.search);
  const creditLimit = queryParams.get('creditLimit');
  const accountNumber = queryParams.get('accountNumber');
  const applicationDate = queryParams.get('applicationDate');
  const productCode = queryParams.get('productCode');

  if (!config.productCode && productCode) {
    setConfig({ productCode });
    return null;
  }

  const downloadOrPrintFile = () => {
    if (isSafari) {
      document.execCommand('print', false, null);
    }
    window.print();
  };
  const productName = getProductInfo().name;
  const ratesAndFees = getRatesAndFees();

  const statementDate = new Date(applicationDate).toLocaleDateString('en-AU', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  const ProductFinancialTable = byCard({
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: DjFinancialTable,
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: DjFinancialTable,
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: Au28DFinancialTable,
  });

  return (
    <ProductFinancialTable
      ratesAndFees={ratesAndFees || {}}
      statementDate={statementDate}
      downloadOrPrintFile={downloadOrPrintFile}
      productName={productName}
      accountNumber={accountNumber}
      creditLimit={creditLimit}
      productCode={productCode}
    />
  );
}
