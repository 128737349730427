import {
  POI_CHECK_REQUIRED,
  DECLINED,
  REFERRED,
  SERVICE_UNAVAILABLE,
  APPROVED,
  CONDITIONALLY_APPROVED,
  QUOTE_INITIAL,
  QUOTE_DECLINE,
  IDV_CHECK_REQUIRED,
  CREATED,
  SAVED,
  APPLICATION_NOT_FOUND,
  PENDING,
  PROCESSING_ACCEPTANCE,
  IDV_CHECK_PENDING,
  POI_CHECK_PENDING,
  PENDING_CUSTOMER_ACCEPTANCE,
  BIOMETRICS_CHECK_REQUIRED,
  BIOMETRICS_STARTED,
  CCR_REQUIRED,
  CCR_PENDING,
  POI_BIO_CHECK_REQUIRED,
} from 'store';
import {
  STEP_PROCESSING_BIOMETRICS,
  STEP_PROCESSING,
  STEP_PROCESSING_ACCEPTANCE,
  STEP_EIDV,
  STEP_VERIFY_INCOME,
  STEP_ACCEPTANCE,
  STEP_CONFIRM_CREDIT_LIMIT,
  STEP_OUTCOME,
  STEP_PROCESSING_PENDING_POI,
  STEP_PROCESSING_IDV,
  STEP_DVS_CONSENT,
  STEP_RESUME_APPLICATION_EXPIRED,
  STEP_LOAN_INFORMATION,
  STEP_PROCESSING_LOAN_INFORMATION,
  STEP_AUS_POST,
  STEP_LATITUDE_ID_INTRODUCTION,
} from '_config';
import { byCountry, isAU } from 'utils';
import { isFeatureOn } from 'featureToggles';

export function calculateNextStep(application, storeState = {}) {
  if (
    isAU() &&
    ![CREATED, SAVED, PENDING, QUOTE_INITIAL, QUOTE_DECLINE, DECLINED, CCR_REQUIRED].includes(application.status) &&
    (!storeState.isLatitudeIdPasswordSet || (isFeatureOn('djEmailVerification') && !storeState.isEmailVerified))
  ) {
    // REDIRECT to Latitude ID steps if there are missing steps and the application is not in the specific status
    return STEP_LATITUDE_ID_INTRODUCTION;
  }

  switch (application.status) {
    case APPLICATION_NOT_FOUND: {
      return STEP_RESUME_APPLICATION_EXPIRED;
    }
    case BIOMETRICS_CHECK_REQUIRED:
    case BIOMETRICS_STARTED: {
      return STEP_PROCESSING_BIOMETRICS;
    }
    case SAVED:
    case PENDING: {
      return STEP_PROCESSING;
    }
    case PROCESSING_ACCEPTANCE: {
      return STEP_PROCESSING_ACCEPTANCE;
    }
    case IDV_CHECK_PENDING: {
      return STEP_PROCESSING_IDV;
    }
    case POI_CHECK_PENDING: {
      return STEP_PROCESSING_PENDING_POI;
    }
    case CCR_REQUIRED: {
      return STEP_LOAN_INFORMATION;
    }
    case CCR_PENDING: {
      return STEP_PROCESSING_LOAN_INFORMATION;
    }
    case QUOTE_INITIAL:
    case QUOTE_DECLINE: {
      return byCountry({
        AU: STEP_CONFIRM_CREDIT_LIMIT,
        NZ: isFeatureOn('dynamicCreditLimit') ? STEP_CONFIRM_CREDIT_LIMIT : STEP_ACCEPTANCE,
      });
    }
    case IDV_CHECK_REQUIRED: {
      if (
        // dvs consent not selected for the second time or idv attempts exhausted or idv none option triggered
        (!application.dvsConsent && application.finalDVSConsentAttempted) ||
        (application.medicareChecked && application.passportChecked) ||
        application.idvNoneChecked
      ) {
        return STEP_AUS_POST;
      }
      if (application.dvsConsent) {
        return STEP_EIDV;
      }
      return STEP_DVS_CONSENT;
    }
    case POI_BIO_CHECK_REQUIRED:
    case POI_CHECK_REQUIRED: {
      return STEP_VERIFY_INCOME;
    }
    case PENDING_CUSTOMER_ACCEPTANCE: {
      if (isAU()) {
        throw new Error(
          `Unexpected AU application status: PENDING_CUSTOMER_ACCEPTANCE. Application ID: ${application.id}.`,
        );
      }
      return STEP_ACCEPTANCE;
    }
    case APPROVED:
    case CONDITIONALLY_APPROVED: {
      return STEP_OUTCOME;
    }
    case DECLINED:
    case REFERRED: {
      // Check if this is a pre-DynamicCreditLimit early rejection
      const isEarlyRejection = storeState?.activeStep === STEP_PROCESSING;
      const needDCL = byCountry({ AU: false, NZ: isFeatureOn('dynamicCreditLimit') });
      if (isEarlyRejection && needDCL) {
        // we collect information of desired credit limit amount even if the application decision is declined or referred from an early stage.
        return STEP_CONFIRM_CREDIT_LIMIT;
      }
      return STEP_OUTCOME;
    }
    case SERVICE_UNAVAILABLE: {
      return STEP_OUTCOME;
    }
    default:
      throw new Error(`Unexpected application status: ${application.status}. Application ID: ${application.id}.`);
  }
}
