import React from 'react';
import { Modal } from 'components/modal/Modal';
import { Button, Container, Flex, Text, Link } from 'basis';
import { OverviewModalContentTwentyEightDegrees } from 'siteContent/overviewContent';
import { OverviewSources } from '_config';
import styled from '@emotion/styled';
import { TickIcon } from 'components';

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    padding: 6px 0;
  }

  svg {
    flex-shrink: 0;
    margin-right: 12px;
    margin-top: -4px;
  }
`;

const FeaturesAndBenefitsModal = ({ isOpen, onClose, getLinkWithAnalytics, trackEventSender }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container padding="6" padding-md="8">
        <Container padding="0 0 4">
          <Flex placeItems="left center">
            <Text textStyle="heading6" textStyle-md="heading4">
              {OverviewModalContentTwentyEightDegrees.title}
            </Text>
          </Flex>
        </Container>
        <Container>
          Please ensure the{' '}
          <Link
            href={getLinkWithAnalytics(OverviewSources.AU.latitude_28d_mastercard)}
            newTab
            onClick={() => trackEventSender(OverviewSources.AU.latitude_28d_mastercard.label)}
          >
            {OverviewSources.AU.latitude_28d_mastercard.label}
          </Link>{' '}
          is right for you.
        </Container>
        <Container padding="6 0">
          <List>
            {OverviewModalContentTwentyEightDegrees.body.map((content, key) => {
              return (
                <li key={key}>
                  <TickIcon size="24" />
                  <Text textStyle="body1" testId={`textDescription-${key}`}>
                    {content}
                  </Text>
                </li>
              );
            })}
          </List>
        </Container>

        <Flex placeItems="center">
          <Container padding="2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};

export { FeaturesAndBenefitsModal };
