import React from 'react';
import { Text, Container, Button, Grid, Message } from 'basis';
import { LayoutPage, LayoutContent } from 'components';
import { useHistory } from 'react-router-dom';
import { useEventTracking } from 'react-event-tracker';
import { getDataLayerElements, isAU } from 'utils';
import { useStoreValue } from 'store';
import { config } from '_config';
import { GreenMessage } from '../../components/message/GreenMessage';

export const SavedConfirmation = () => {
  const history = useHistory();
  const [storeState] = useStoreValue();
  const { trackEvent } = useEventTracking();

  const handleResumeApplication = () => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-navigation',
        location: 'saved',
        label: 'Resume application',
      },
      ...getDataLayerElements(storeState),
    });
    history.goBack();
  };

  const SavedConfirmationNZ = () => (
    <LayoutPage testId="saved" noProgressStepper>
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <LayoutContent>
          <Text>
            We’ve sent you an email with instructions on how to resume. You can come back anytime within the next 60
            days.
          </Text>
          <Text>
            Remember, all you need is your email address and mobile phone to hand when you’re ready to resume your
            application.
          </Text>

          <Button testId="resume" width="100%" onClick={() => handleResumeApplication()}>
            Resume application
          </Button>
        </LayoutContent>
      </Container>
    </LayoutPage>
  );
  const SavedConfirmationAU = () => (
    <LayoutPage testId="saved" noSessionTimeout noProgressStepper>
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <LayoutContent rowsGap="0">
          <Container padding="0" margin="0">
            <Grid preset="page" colsGap={6}>
              <Grid.Item colSpan="all">
                <Grid rowsGap={6}>
                  <Container padding="0 0 0 0">
                    <Text>
                      Application reference number: <b>{storeState.applicationRef}</b>
                    </Text>
                  </Container>

                  <GreenMessage>
                    <Message testId="green-message" severity="success" contentColor="conditional.positive.text">
                      <Text color="conditional.positive.text">
                        <strong>We have saved your application</strong>
                      </Text>
                      <Text>We have sent you an email with instructions on how to complete your application.</Text>
                      <br />
                      <Text>
                        Remember, you will need your email address and the mobile phone close by to resume your
                        application.
                      </Text>
                    </Message>
                  </GreenMessage>
                  <Text>Your {config.productName} credit card team</Text>
                </Grid>
              </Grid.Item>
            </Grid>
          </Container>
        </LayoutContent>
      </Container>
    </LayoutPage>
  );
  return isAU() ? <SavedConfirmationAU /> : <SavedConfirmationNZ />;
};
