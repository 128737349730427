import React, { createContext, useContext, useReducer } from 'react';
import { setSessionStorage } from 'utils/sessionStorage';

export const storageStateKey = 'store';

const reducer = (existingState, newState) => {
  const state = {
    ...existingState,
    ...newState,
  };
  setSessionStorage(storageStateKey, state);
  return state;
};

export const StoreContext = createContext({});

export function StoreProvider({ initialState, children }) {
  return <StoreContext.Provider value={useReducer(reducer, initialState)}>{children}</StoreContext.Provider>;
}

export const useStoreValue = () => useContext(StoreContext);
