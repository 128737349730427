const fortnightlyMultiplier = 26 / 12;
const weeklyMultiplier = 52 / 12;

export function convertRateToMonthlyString({ amount, frequency } = {}, toFixed = 0) {
  let convertedValueToMonthly;
  const parseFloatVal = parseFloat(amount);
  const parsedVal = isNaN(parseFloatVal) ? 0 : parseFloatVal;

  if (frequency === 'monthly') {
    convertedValueToMonthly = parsedVal;
  }
  if (frequency === 'annually') {
    convertedValueToMonthly = parsedVal / 12;
  }
  if (frequency === 'quarterly') {
    convertedValueToMonthly = parsedVal / 3;
  }
  if (frequency === 'fortnightly') {
    convertedValueToMonthly = parsedVal * fortnightlyMultiplier;
  }
  if (frequency === 'weekly') {
    convertedValueToMonthly = parsedVal * weeklyMultiplier;
  }

  convertedValueToMonthly = convertedValueToMonthly?.toFixed(toFixed);

  return isNaN(convertedValueToMonthly) ? 0 : convertedValueToMonthly;
}

export function convertRateToMonthlyNumber(input) {
  const amount = Number(input.amount);

  switch (input.frequency) {
    case 'annually':
      return Math.round(amount / 12);
    case 'quarterly':
      return Math.round(amount / 3);
    case 'monthly':
      return amount;
    case 'fortnightly':
      return Math.round(amount * 2);
    case 'weekly':
      return Math.round(amount * 4);
    default:
      throw new Error(`Invalid frequency: ${input.frequency}`);
  }
}
