import React from 'react';
import { useStoreValue, APOLLO_GRAPHQL_ERROR } from 'store';
import { DJNetworkErrors } from './DJNetworkErrors';
import { DJSystemUnavailable } from './DJSystemUnavailable';

export function DJErrors({ applicationRef, retry, retrying, isPreSubmission = false }) {
  const [storeState] = useStoreValue();

  // Display pre-submission network error page if retry is specified network error is not null.
  // However if error type starts with APOLLO, display system unavailable page instead.
  if (isPreSubmission) {
    if (retry && storeState.applicationErrors?.type !== APOLLO_GRAPHQL_ERROR) {
      return <DJNetworkErrors applicationRef={applicationRef} retry={retry} retrying={retrying} />;
    }
  }

  return <DJSystemUnavailable applicationRef={applicationRef} isPreSubmission={isPreSubmission} />;
}
