import React from 'react';
import { useStoreValue } from 'store';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import { useEventTracking } from 'react-event-tracker';
import { Link, Text, Container, Flex, Stack, Grid } from 'basis';
import styled from '@emotion/styled';
import { IconLogo } from 'components/twenty-eight-degrees/IconLogo';
import { WidthContainer } from 'components/twenty-eight-degrees/Containers';

// TODO: update urls
const FooterSources = {
  privacy_policy: {
    label: 'Privacy and Credit Reporting Policy',
    url: 'https://www.latitudefinancial.com.au/privacy',
  },
  terms_and_conditions: {
    label: 'Website Terms and Conditions',
    url: 'https://www.latitudefinancial.com.au/terms-and-conditions',
  },
  security: {
    label: 'Security',
    url: 'https://www.latitudefinancial.com.au/security',
  },
};

export const FooterWrapper = styled.div`
  svg {
    height: 24px;
    min-width: 150px;
  }
  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.lg})`} {
      & > div {
        display: flex;
      }
    }
    ${`@media (min-width: ${props.theme.screenSize.md})`} {
      svg {
        height: 32px;
        width: 204px;
      }
    }
  `}
`;

export function Au28DFooter() {
  const [state] = useStoreValue();
  const { trackEvent, getQueryString } = useEventTracking();

  const trackEventSender = label => {
    trackEvent({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location: 'footer',
        label,
      },
      ...getDataLayerElements(state),
    });
  };

  const getLinkWithAnalytics = key => {
    // TODO: update query params for AU Houndstooth products
    const gemid2 = getQueryString({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location: 'footer',
        label: FooterSources[key].label,
      },
    });

    const link = new URL(FooterSources[key].url);
    // TODO: update query params for AU Houndstooth products
    link.searchParams.set('gemid2', gemid2);
    return link.href;
  };

  return (
    <Container bg="grey.t03" testId="au-28degrees-footer">
      <FooterWrapper>
        <WidthContainer>
          <Container padding="5 0">
            <Container margin="auto" margin-md="auto">
              <Grid colsGutter="0" cols="6">
                <Grid.Item colSpan="0-1" colSpan-md="0" colSpan-lg="0" rowSpan="0" rowSpan-md="0" margin-md="0">
                  <Container padding="6 0" padding-md="0 0 5 0">
                    <Flex placeItems="center left">
                      <IconLogo />
                    </Flex>
                  </Container>
                </Grid.Item>

                <Grid.Item colSpan="all">
                  <Flex placeItems="left">
                    <Stack direction="horizontal" align="left" gap="3 4">
                      {Object.keys(FooterSources).map((key, index) => {
                        return (
                          <Link
                            variant="medium-bg"
                            key={index}
                            href={getLinkWithAnalytics(key)}
                            newTab
                            onClick={() => trackEventSender(FooterSources[key].label)}
                          >
                            {FooterSources[key].label}
                          </Link>
                        );
                      })}
                    </Stack>
                  </Flex>
                </Grid.Item>

                <Grid.Item colSpan="all">
                  <Flex placeItems="left">
                    <Container textStyle="legal" margin="7 0 0 0">
                      <Text color="primary.blue.t100">
                        Mastercard is a registered trademark and the circles design is a trademark of Mastercard
                        International Incorporated. Credit facility provided by Latitude Finance Australia ABN 42 008
                        583 588. Australian Credit Licence Number 392145. 130 Lonsdale Street, Melbourne 3000.
                      </Text>
                    </Container>
                  </Flex>
                </Grid.Item>
              </Grid>
            </Container>
          </Container>
        </WidthContainer>
      </FooterWrapper>
    </Container>
  );
}
