import { setSessionStorage, byCard } from 'utils';
import { PRODUCT_DAVID_JONES, PRODUCT_CODE_NZ_GEM, PRODUCT_CODE_TWENTY_EIGHT_DEGREES } from '_config';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DEFAULT_FEATURES_28D, DEFAULT_FEATURES_DJ, DEFAULT_FEATURES_NZ } from 'featureToggles';

const useLaunchDarklyFlags = () => {
  const launchDarklyFlags = useFlags();
  const flags = Object.keys(launchDarklyFlags)
    .filter(key =>
      Object.keys(
        byCard({
          [PRODUCT_CODE_NZ_GEM]: DEFAULT_FEATURES_NZ,
          [PRODUCT_DAVID_JONES]: DEFAULT_FEATURES_DJ,
          [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: DEFAULT_FEATURES_28D,
        }),
      ).includes(key),
    )
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: launchDarklyFlags[key] });
    }, {});
  setSessionStorage('launchDarklyFlags', flags);
};

export { useLaunchDarklyFlags };
