import { useState, useCallback } from 'react';
import { formatNumber, convertRateToMonthlyNumber } from 'utils';
import { getAge } from 'utils/getDataLayerElementsHelper';
import {
  yesNoOptions,
  employmentStatusOptionsAU,
  numbersOnlyPattern,
  UNEMPLOYED,
  GOVERNMENT_BENEFITS,
  WORKERS_COMPENSATION,
  auNonGovIndustryOptions,
  auGovIndustryOptions,
} from '../_constants';
import { validEmployerNamePattern } from './constants';

const incomeValidationMessages = {
  required: 'Please enter your income after tax.',
  minLength: 'Must have at least {{minLength}} characters.',
  maxLength: 'Must have at most {{maxLength}} characters',
  invalid: 'Please enter a whole dollar amount, without cents.',
};

const lengthOfTimeAtCurrentEmployerMessages = {
  required: 'Please enter your length of time employed.',
  invalid: 'Please enter numbers only.',
  monthsError: 'Please enter the number of months at your current employer. If less than 1 month, enter 0.',
  yearsError: 'Please enter your length of time employed.',
  monthRangeError: 'Please provide a number between {{minMonths}}-{{maxMonths}}.',
  yearRangeError: 'Please provide a number between {{minYears}}-{{maxYears}}.',
  excessAgeError: 'The length of time looks too high. Please review your input.',
};

const lengthOfTimeUnemployedMessages = {
  required: 'Please enter the length of time you have been unemployed.',
  invalid: 'Please enter numbers only.',
  monthsError: 'Please enter the number of months you have been unemployed. If less than 1 month, enter 0.',
  yearsError: 'Please enter the length of time you have been unemployed.',
  monthRangeError: 'Please provide a number between {{minMonths}}-{{maxMonths}}.',
  yearRangeError: 'Please provide a number between {{minYears}}-{{maxYears}}.',
  excessAgeError: 'The length of time looks too high. Please review your input.',
};

const lengthOfTimeGovernmentBenefitMessages = {
  required: `Please enter the length of time you've received your current government benefits.`,
  invalid: 'Please enter numbers only.',
  monthsError: `Please enter the number of months you've received your current government benefits. If less than 1 month, enter 0.`,
  yearsError: `Please enter the number of years you've received your current government benefits.`,
  monthRangeError: 'Please provide a number between {{minMonths}}-{{maxMonths}}.',
  yearRangeError: 'Please provide a number between {{minYears}}-{{maxYears}}.',
  excessAgeError: 'The length of time looks too high. Please review your input.',
};

const lengthOfTimeWorkersCompensationMessages = {
  required: `Please enter the length of time of years you have received your current compensation`,
  invalid: 'Please enter numbers only.',
  monthsError: `Please enter the number of months you have received your current compensation. If less than 1 month, enter 0.`,
  yearsError: `Please enter the number of years you have received your current compensation.`,
  monthRangeError: 'Please provide a number between {{minMonths}}-{{maxMonths}}.',
  yearRangeError: 'Please provide a number between {{minYears}}-{{maxYears}}.',
  excessAgeError: 'The length of time looks too high. Please review your input.',
};

const employerNameMessages = {
  required: 'Please provide us the name of the company you work at.',
  invalid: `Company name contains an invalid character. Must only contain letters, numbers and these characters: ( & , - . : ; ' " ! @ # $ % ^ & * () _ + = ? /.' )`,
  minLength: 'Must have at least {{minLength}} characters.',
  maxLength: 'Must have at most {{maxLength}} characters',
};

export const employmentDetails = [
  {
    component: 'Select',
    name: 'employmentStatus',
    label: 'Your employment status?',
    options: employmentStatusOptionsAU,
    testId: 'employment-status',
    validate: {
      messages: {
        required: 'Please provide us your employment status.',
      },
    },
  },
  {
    condition: values => !!values.employmentStatus,
    name: 'hasEmploymentStatus',
    fields: [
      {
        condition: values => ![UNEMPLOYED].includes(values.employmentStatus),
        component: 'Select',
        name: 'industry',
        label: 'Employment Industry',
        options: values => {
          switch (values.employmentStatus) {
            case GOVERNMENT_BENEFITS:
              return auGovIndustryOptions;
            default:
              return auNonGovIndustryOptions;
          }
        },
        testId: 'industry',
        validate: {
          messages: {
            required: 'Please provide us the industry you work in.',
          },
        },
      },
      {
        condition: values => ![UNEMPLOYED].includes(values.employmentStatus),
        component: 'Input',
        name: 'employerName',
        label: 'Company name',
        testId: 'employer-name',
        validate: {
          props: {
            minLength: 1,
            maxLength: 30,
            validPattern: validEmployerNamePattern,
          },
          messages: employerNameMessages,
        },
      },
      {
        condition: values => ![UNEMPLOYED, GOVERNMENT_BENEFITS, WORKERS_COMPENSATION].includes(values.employmentStatus),
        component: 'TimeSpan',
        name: 'lengthOfTimeAtCurrentEmployer',
        label: 'Length of time at current employer',
        testId: 'current-employer-length',
        validate: {
          props: storeState => {
            const age = getAge(storeState.acquisition.personalDetails.dateOfBirth, undefined, true);
            return {
              ageAsUpperLimit: age,
              minYears: 0,
              maxYears: 99,
              minMonths: 0,
              maxMonths: 11,
              validPattern: numbersOnlyPattern,
            };
          },
          messages: lengthOfTimeAtCurrentEmployerMessages,
          component: 'TimeSpan',
        },
      },
      {
        condition: values => UNEMPLOYED === values.employmentStatus,
        component: 'TimeSpan',
        name: 'lengthOfTimeAtCurrentEmployer',
        label: 'How long have you been unemployed?',
        testId: 'current-employer-length',
        validate: {
          props: storeState => {
            const age = getAge(storeState.acquisition.personalDetails.dateOfBirth, undefined, true);
            return {
              ageAsUpperLimit: age,
              minYears: 0,
              maxYears: 99,
              minMonths: 0,
              maxMonths: 11,
              validPattern: numbersOnlyPattern,
            };
          },
          messages: lengthOfTimeUnemployedMessages,
          component: 'TimeSpan',
        },
      },
      {
        condition: values => GOVERNMENT_BENEFITS === values.employmentStatus,
        component: 'TimeSpan',
        name: 'lengthOfTimeAtCurrentEmployer',
        label: 'Length of time on benefits',
        testId: 'current-employer-length',
        validate: {
          props: storeState => {
            const age = getAge(storeState.acquisition.personalDetails.dateOfBirth, undefined, true);
            return {
              ageAsUpperLimit: age,
              minYears: 0,
              maxYears: 99,
              minMonths: 0,
              maxMonths: 11,
              validPattern: numbersOnlyPattern,
            };
          },
          messages: lengthOfTimeGovernmentBenefitMessages,
          component: 'TimeSpan',
        },
      },
      {
        condition: values => WORKERS_COMPENSATION === values.employmentStatus,
        component: 'TimeSpan',
        name: 'lengthOfTimeAtCurrentEmployer',
        label: 'Length of time on compensation',
        testId: 'current-employer-length',
        validate: {
          props: storeState => {
            const age = getAge(storeState.acquisition.personalDetails.dateOfBirth, undefined, true);
            return {
              ageAsUpperLimit: age,
              minYears: 0,
              maxYears: 99,
              minMonths: 0,
              maxMonths: 11,
              validPattern: numbersOnlyPattern,
            };
          },
          messages: lengthOfTimeWorkersCompensationMessages,
          component: 'TimeSpan',
        },
      },
      {
        component: 'Frequency',
        name: 'income',
        label: 'How much do you earn after tax?',
        mode: 'select',
        amountPrefix: '$',
        quarterly: false,
        testId: 'income',
        validate: {
          props: { minLength: 1, maxLength: 6, validPattern: numbersOnlyPattern },
          messages: incomeValidationMessages,
          component: 'Frequency',
        },
      },
      {
        component: 'RadioGroup',
        name: 'hasOtherIncome',
        label: 'Do you have additional sources of income?',
        testId: 'has-other-income',
        options: yesNoOptions,
        showCircles: true,
        validate: {
          messages: {
            required: 'Please select yes or no.',
          },
        },
      },
      {
        condition: values => values.hasOtherIncome === 'yes',
        name: 'otherIncomeGroup',
        fields: [
          {
            component: 'Frequency',
            name: 'otherIncome',
            label: 'How much is your additional income after tax?',
            mode: 'select',
            amountPrefix: '$',
            quarterly: false,
            testId: 'other-income',
            helpText: 'Additional income may be income from your second job, rental property, shares or pension.',
            validate: {
              props: { minLength: 1, maxLength: 6, validPattern: numbersOnlyPattern },
              messages: incomeValidationMessages,
              component: 'Frequency',
            },
          },
          {
            component: 'Select',
            name: 'otherEmploymentStatus',
            label: "What's your other source of income?",
            options: employmentStatusOptionsAU,
            testId: 'other-employment-status',
            validate: {
              messages: {
                required: 'Please select your main source of income',
              },
            },
          },
        ],
      },
    ],
  },
];

export const useWarning = () => {
  const [warnings, setWarning] = useState([]);

  const check = useCallback(values => {
    const additionMonthlyIncome = values.hasOtherIncome === 'yes' ? convertRateToMonthlyNumber(values.otherIncome) : 0;

    const totalMonthlyIncome = convertRateToMonthlyNumber(values.income) + additionMonthlyIncome;

    const isUnemployed = values.employmentStatus === UNEMPLOYED;
    const income0 = totalMonthlyIncome === 0;
    const incomeLessThan100 = totalMonthlyIncome !== 0 && totalMonthlyIncome < 100;

    const newWarnings = [];
    if (isUnemployed) {
      newWarnings.push(
        `Please review and confirm your employment status and your income is $${formatNumber(values.income.amount)}.`,
      );
    }
    if (income0) {
      newWarnings.push(`Please update your total income. It must be greater than $0.`);
    }
    if (incomeLessThan100) {
      newWarnings.push(
        `Please review and confirm that your total net monthly income is $${formatNumber(totalMonthlyIncome)}.
            (Please note, we've converted your income to a monthly figure).`,
      );
    }
    setWarning(newWarnings);
    return newWarnings.length === 0;
  }, []);

  return { check, warnings };
};
