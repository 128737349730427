import { Region } from 'fi-connect';

export const phoneCountryCode = '+64';
export const countryName = 'New Zealand';
export const getPhoneNumbers = productCode => ({
  general: '0800 500 505',
  generalAlt: '+64 9 362 5432',
  originationsTeam: '0800 800 870',
  disputes: '0800 735 192',
  disputesAlt: '(09) 375 0819',
  hardship: '0800 433 033',
  ombudsman: '(04) 499 7612',
  ombudsmanAlt: '0800 888 202',
  appleMerchant: '0800 692 7753',
  assistance: '0800 500 505',
});

export const getUrls = productCode => {
  return {};
};

export const minimumResidencyInMonths = 36;

export const minCreditLimit = 500;
// TODO: The max value should come from the decisioning system once application has some form of approval
export const maxCreditLimit = 40000;
export const creditLimitIncrement = 100;

export const minMonthlyPaymentRate = 0.03;

const timezoneOffsetInMs = new Date().getTimezoneOffset() * 60000;

const tomorrowEpoch = new Date().setDate(new Date().getDate() + 1);
const tomorrowLocalDate = new Date(tomorrowEpoch - timezoneOffsetInMs).toISOString().split('T')[0];
export const minExpiryDate = tomorrowLocalDate;

export const minAge = 18;
export const minAgeMaxDate = new Date(new Date().setFullYear(new Date().getFullYear() - minAge) - timezoneOffsetInMs)
  .toISOString()
  .split('T')[0];

export const maxAge = 99;
export const maxAgeDate = new Date(new Date().setFullYear(new Date().getFullYear() - maxAge) - timezoneOffsetInMs);

// NZ Mobile numbers start with 02, followed by 7 - 9 numbers
export const validNzMobilePattern = '^02\\d{7,9}$';
// NZ landline numbers start with area codes 03 | 04 | 06 | 07 | 09, followed by 7 numbers
export const validNzLandlinePattern = '^0[34679]\\d{7}$';
// address
export const validUnitAddressPattern = '^[0-9]+[a-zA-Z ]*$';
export const validStreetNumberPattern = '^[A-Za-z0-9/-]*$';
export const validStreetNamePattern = `^[A-Za-z '-]*$`;
export const validSuburbPattern = `^[A-Za-z '-]*$`;
export const validPostcodePattern = '^[0-9]{4}$';

export const validEmployerNamePattern = `^[A-Za-z0-9 '-]*$`;

export { idTypeOptions } from './idTypeOptions';

export const idVerificationFormUrl =
  'https://assets.latitudefinancial.com/forms/cards/identity-verification/gemvisa-nz-idv.pdf';

export const aplyIdUrl = 'https://gemvisa.aplyid.com/forms/redirect';

export const fiConnectRegion = Region.NZ;
