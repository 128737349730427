/* eslint-disable no-param-reassign */
import _get from 'lodash/get';
import _set from 'lodash/set';
import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import _unset from 'lodash/unset';
import _cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';
import {
  OptionSystem,
  isSuperOrGov,
  SUPERANNUATION,
  GOVERNMENT_BENEFIT,
  RENTAL_INCOME,
  INVESTMENT,
  isRentalOrInvestment,
  isEligibleForBankConnect,
} from '_config/_constants';
import { config } from '_config';
import { isFeatureOn } from 'featureToggles';
import { PO_BOX_TYPE, STREET_ADDRESS_TYPE, FIELDS } from 'address-lookup';
import { toLocalNumber } from 'utils/mobileNumber';
import { isNZ } from 'utils/byConfig';

const { countryCode } = config;

const convertStringToIntPaths = _.uniq([
  'personalDetails.dateOfBirth.day',
  'personalDetails.dateOfBirth.month',
  'personalDetails.dateOfBirth.year',
  'otherDetails.lengthOfCurrentResidency.years',
  'otherDetails.lengthOfCurrentResidency.months',
  'extraDetails.numberOfDependents',
  'extraDetails.expiryDate.day',
  'extraDetails.expiryDate.month',
  'extraDetails.expiryDate.year',
  'expensesDetails.facilityLimit',
  'expensesDetails.primaryPropertyMortgageRentBoard.amount',
  'expensesDetails.secondaryPropertyMortgageRentBoard.amount',
  'expensesDetails.propertyRates.amount',
  'expensesDetails.propertyUtilitiesOther.amount',
  'expensesDetails.foodGroceriesLifestyleEntertainment.amount',
  'expensesDetails.healthLifeInsurance.amount',
  'expensesDetails.generalInsurance.amount',
  'expensesDetails.transport.amount',
  'expensesDetails.educationChildcare.amount',
  'expensesDetails.personalFitnessMedical.amount',
  'expensesDetails.other.amount',
  'employmentDetails.lengthOfTimeAtCurrentEmployer.years',
  'employmentDetails.lengthOfTimeAtCurrentEmployer.months',
  'employmentDetails.lengthOfUnemployment.years',
  'employmentDetails.lengthOfUnemployment.months',
  'employmentDetails.income.amount',
  'employmentDetails.otherIncome.amount',
  'additionalDebtsDetails.loanRepayment.amount',
  'additionalDebtsDetails.homeLoans',
  'additionalDebtsDetails.totalCardLimits',
  'additionalDebtsDetails.amountOwingOnCreditCard',
  'creditLimitDetails.creditLimit',
]);

const convertToUpperCasePaths = _.uniq([
  'employmentDetails.income.frequency',
  'expensesDetails.primaryPropertyMortgageRentBoard.frequency',
  'expensesDetails.secondaryPropertyMortgageRentBoard.frequency',
  'expensesDetails.propertyRates.frequency',
  'expensesDetails.propertyUtilitiesOther.frequency',
  'expensesDetails.foodGroceriesLifestyleEntertainment.frequency',
  'expensesDetails.healthLifeInsurance.frequency',
  'expensesDetails.generalInsurance.frequency',
  'expensesDetails.transport.frequency',
  'expensesDetails.educationChildcare.frequency',
  'expensesDetails.personalFitnessMedical.frequency',
  'expensesDetails.other.frequency',
  'employmentDetails.otherIncome.frequency',
  'additionalDebtsDetails.loanRepayment.frequency',
]);

const convertYesNoToBooleanPaths = _.uniq([
  'otherDetails.isPostalSameWithResidential',
  'additionalDebtsDetails.hasOtherDebts',
  'expensesDetails.isExpensesShared',
  'additionalDebtsDetails.hasLoanLumpSumPayment',
  'additionalDebtsDetails.payLumpSumWithExistingSaving',
  'expensesDetails.isRevolvingOrOffsetMortgage',
  'employmentDetails.expectedIncomeChange',
  'employmentDetails.hasOtherIncome',
]);

const transformAddressPaths = _.uniq([
  { address: 'otherDetails.currentAddress' },
  {
    condition: values =>
      values.otherDetails.lengthOfCurrentResidency.years < 3 &&
      values.otherDetails.hasNonDomesticPreviousAddress === false,
    address: 'otherDetails.previousAddress',
  },
  { condition: values => !values.otherDetails.isPostalSameWithResidential, address: 'otherDetails.postalAddress' },
]);

const overwritePaths = _.uniq([
  {
    when: transformedState => _get(transformedState, 'additionalDebtsDetails.hasOtherDebts') === false,
    changePaths: [
      'additionalDebtsDetails.loanRepayment.amount',
      'additionalDebtsDetails.homeLoans',
      'additionalDebtsDetails.totalCardLimits',
      'additionalDebtsDetails.amountOwingOnCreditCard',
    ],
    value: 0,
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.hasOtherIncome') === false,
    changePaths: ['employmentDetails.otherEmploymentStatus', 'employmentDetails.otherIncome'],
    value: null,
  },
  {
    when: transformedState =>
      isFeatureOn('incomeReplay') && isEligibleForBankConnect(transformedState.employmentDetails.employmentStatus),
    changePaths: ['employmentDetails.income', 'employmentDetails.otherIncome'],
    value: null,
  },
  {
    when: (transformedState, storeState) => {
      const conditionMet =
        !isFeatureOn('creditLimit99999') &&
        _get(transformedState, 'creditLimitDetails.creditLimitSource') === OptionSystem;
      if (conditionMet) {
        // eslint-disable-next-line no-console
        console.error(
          `
Setting credit limit to null.
Application ID: ${storeState.applicationId}.
isFeatureOn('creditLimit99999'): ${isFeatureOn('creditLimit99999')}.
isFeatureOn('dynamicCreditLimit'): ${isFeatureOn('dynamicCreditLimit')}.
creditLimitSource: ${transformedState.creditLimitDetails.creditLimitSource}`,
        );
      }
      return conditionMet;
    },
    // deprecated behaviour. we use null to represent max credit limit requested by the user.
    // after features creditLimit99999 and dynamicCreditLimit released, we will use `99999` to represent max credit limit.
    changePaths: ['creditLimitDetails.creditLimit'],
    value: null,
  },
  {
    when: transformedState =>
      isFeatureOn('creditLimit99999') &&
      _get(transformedState, 'creditLimitDetails.creditLimitSource') === OptionSystem,
    changePaths: ['creditLimitDetails.creditLimit'],
    value: 99999,
  },
  {
    when: (transformedState, storeState) => {
      const conditionMet =
        isFeatureOn('creditLimit99999') &&
        isFeatureOn('dynamicCreditLimit') &&
        _get(transformedState, 'creditLimitDetails.creditLimitSource') === '';
      if (conditionMet) {
        // eslint-disable-next-line no-console
        console.error(
          `
Setting credit limit to null.
Application ID: ${storeState.applicationId}.
isFeatureOn('creditLimit99999'): ${isFeatureOn('creditLimit99999')}.
isFeatureOn('dynamicCreditLimit'): ${isFeatureOn('dynamicCreditLimit')}.
creditLimitSource: ${transformedState.creditLimitDetails.creditLimitSource}`,
        );
      }
      return conditionMet;
    },
    // user has not selected any credit limit options due to the new flow of dynamicCreditLimit
    changePaths: ['creditLimitDetails.creditLimit'],
    value: null,
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.otherEmploymentStatus') === '',
    changePaths: ['employmentDetails.otherEmploymentStatus'],
    value: null,
  },
  {
    when: transformedState => isSuperOrGov(_get(transformedState, 'employmentDetails.employmentStatus')),
    changePaths: ['employmentDetails.employerName'],
    value: 'WINZ/IRD',
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.employmentStatus') === SUPERANNUATION,
    changePaths: ['employmentDetails.occupation'],
    value: 'RETIRED',
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.employmentStatus') === GOVERNMENT_BENEFIT,
    changePaths: ['employmentDetails.occupation'],
    value: 'BENEFIT',
  },
  {
    when: transformedState => isRentalOrInvestment(_get(transformedState, 'employmentDetails.employmentStatus')),
    changePaths: ['employmentDetails.occupation'],
    value: 'SELF_EMPLOYED',
  },
  {
    when: transformedState => isRentalOrInvestment(_get(transformedState, 'employmentDetails.employmentStatus')),
    changePaths: ['employmentDetails.industry'],
    value: 'PROPERTY_BUSINESS',
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.employmentStatus') === SUPERANNUATION,
    changePaths: ['employmentDetails.industry'],
    value: 'PENSIONER',
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.employmentStatus') === GOVERNMENT_BENEFIT,
    changePaths: ['employmentDetails.industry'],
    value: 'UNEMPLOYED',
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.employmentStatus') === RENTAL_INCOME,
    changePaths: ['employmentDetails.employerName'],
    value: 'Rent',
  },
  {
    when: transformedState => _get(transformedState, 'employmentDetails.employmentStatus') === INVESTMENT,
    changePaths: ['employmentDetails.employerName'],
    value: 'Investment',
  },
]);

const removePaths = _.uniq([
  'overview',
  'verification',
  'biometricsAboutYou',
  'biometricsIdentification',
  'biometricsAddressDetails',
  'personalDetails',
  'creditLimitDetails.creditLimitSource',
  'preferredCreditLimitDetails',
  'acceptance',
  'confirmCreditLimit',
  'expensesDetails',
  'employmentDetails',
  'employmentDetailsV2.hasOtherIncome',
  'employmentDetailsV2.incomeAdjustmentRequired',
  'employmentDetailsV2.conditionalStop',
]);

const extraDetailsIdentificationTypeValuePaths = {
  NZ_DRIVERS_LICENCE: {
    id: 'idNZDriversLicence',
    fields: ['licenceNumber', 'version', 'expiryDate'],
  },
  NZ_PASSPORT: { id: 'idNZPassport', fields: ['idNumber', 'expiryDate'] },
  NZ_FIREARM_LICENCE: {
    id: 'idNZFirearmLicence',
    fields: ['licenceNumber', 'expiryDate', 'issuingCountry'],
  },
  AU_PASSPORT: { id: 'idAUPassport', fields: ['idNumber', 'expiryDate', 'issuingCountry'] },
  AU_MEDICARE: {
    id: 'AU_MEDICARE',
    fields: ['medicareCardName', 'medicareCardName', 'medicareCardNumber', 'expiryDate'],
  },
  AU_DRIVERS_LICENCE: {
    id: 'idAUDriversLicence',
    fields: ['licenceNumber', 'version', 'expiryDate', 'australianState'],
  },
  OTHER_PASSPORT: {
    id: 'idOtherPassport',
    fields: ['idNumber', 'expiryDate', 'issuingCountry', 'nzResidencyNumber'],
  },
};

const toStreetAddress = address => {
  const streetAddressFields = FIELDS[config.countryCode].STREET_ADDRESS_FIELDS;
  return Object.keys(address).reduce(
    (acc, key) =>
      streetAddressFields.includes(key) ? { ...acc, ...(address[key] ? { [key]: address[key] } : null) } : acc,
    {},
  );
};

const toPoBoxAddress = address => {
  const poBoxFields = FIELDS[config.countryCode].PO_BOX_FIELDS;
  return Object.keys(address).reduce(
    (acc, key) => (poBoxFields.includes(key) ? { ...acc, ...(address[key] ? { [key]: address[key] } : null) } : acc),
    {},
  );
};

// This function should only be used in NZ.
export const toSaveApplicationInput = (formData, storeState) => {
  const transformed = _cloneDeep(formData);
  if (!isNZ()) {
    throw new Error('toSaveApplicationInput should only be used in NZ');
  }

  Object.assign(transformed, convertConditionalFields(transformed, storeState));

  transformed.applicationReason = transformed.overview.applicationReason;

  transformed.contactDetails.mobileNumber = toLocalNumber(transformed.contactDetails.mobileNumber);

  transformed.password = transformed.password.password || null;

  const isBiometricsFlow = storeState.biometricsVerification === 'SUCCESSFUL';

  if (isBiometricsFlow) {
    transformFromBiometricsFlow(transformed, storeState, formData);
  } else {
    transformed.contactDetails = {
      ...transformed.contactDetails,
      ...transformed.personalDetails,
    };
  }

  convertStringToIntPaths.forEach(path => {
    const value = _get(transformed, path, '0');
    const newValue = typeof value === 'string' ? parseInt(value, 10) : 0;
    _set(transformed, path, Number.isNaN(newValue) ? 0 : newValue);
  });
  convertToUpperCasePaths.forEach(path => {
    const value = _get(transformed, path);
    const newValue = typeof value === 'string' ? value.toUpperCase() : '';
    _set(transformed, path, newValue);
  });
  convertYesNoToBooleanPaths.forEach(path => {
    const value = _get(transformed, path);
    const newValue = value === 'yes';
    _set(transformed, path, newValue);
  });
  transformAddressPaths.forEach(path => {
    if (!path.condition || path.condition(transformed)) {
      const { address } = _get(transformed, path.address);
      // TODO remove hardcoding of addressType and countryCode - https://di.latitudefinancial.com/jira/browse/NZP-524
      const validAddress =
        address.addressType === PO_BOX_TYPE
          ? { addressType: PO_BOX_TYPE, poBoxAddress: { ...toPoBoxAddress(address), countryCode } }
          : {
              addressType: STREET_ADDRESS_TYPE,
              streetAddress: { ...toStreetAddress(address), addressType: STREET_ADDRESS_TYPE, countryCode },
            };
      _set(transformed, path.address, validAddress);
    } else {
      _set(transformed, path.address, null);
    }
  });
  // type OverwritePaths = Array<{
  //   when: (s: transformedState) => boolean,
  //   changePaths: string[],
  //   value: unknown,
  // }>
  overwritePaths.forEach(({ when, changePaths, value }) => {
    const isTrue = when(transformed, storeState);
    if (isTrue) {
      changePaths.forEach(path => _set(transformed, path, value));
    }
  });

  transformed.extraDetails = {
    relationshipStatus: transformed.extraDetails.relationshipStatus,
    numberOfDependents: transformed.extraDetails.numberOfDependents,
    idType: transformed.extraDetails.idType,
    ...(storeState.biometricsResult?.idType
      ? null
      : {
          // only transform identification document fields if biometrics is not used or if biometrics is used but the provided document is not valid
          [extraDetailsIdentificationTypeValuePaths[transformed.extraDetails.idType].id]: {
            ...extraDetailsIdentificationTypeValuePaths[transformed.extraDetails.idType].fields.reduce(
              (acc, field) => ({
                ...acc,
                [field]: transformed.extraDetails[field],
              }),
              {},
            ),
          },
        }),
  };

  transformed.employmentDetailsV2 = transformed.employmentDetails;

  transformed.expensesInformation = {
    facilityLimit: transformed.expensesDetails?.facilityLimit,
    isPayAllExpenses: !transformed.expensesDetails.isExpensesShared,
    isRevolvingOrOffsetMortgage: transformed.expensesDetails?.isRevolvingOrOffsetMortgage,
  };

  transformed.itemisedExpensesDetails = {
    primaryPropertyMortgageRentBoard: transformed.expensesDetails.primaryPropertyMortgageRentBoard,
    secondaryPropertyMortgageRentBoard: transformed.expensesDetails.secondaryPropertyMortgageRentBoard,
    propertyRates: transformed.expensesDetails.propertyRates,
    propertyUtilitiesOther: transformed.expensesDetails.propertyUtilitiesOther,
    foodGroceriesLifestyleEntertainment: transformed.expensesDetails.foodGroceriesLifestyleEntertainment,
    healthLifeInsurance: transformed.expensesDetails.healthLifeInsurance,
    generalInsurance: transformed.expensesDetails.generalInsurance,
    transport: transformed.expensesDetails.transport,
    educationChildcare: transformed.expensesDetails.educationChildcare,
    personalFitnessMedical: transformed.expensesDetails.personalFitnessMedical,
    other: transformed.expensesDetails.other,
  };

  removePaths.forEach(path => _unset(transformed, path));

  transformed.applyAndBuyDetails = {
    merchantId: storeState.applyAndBuy.merchantId,
    shortCode: storeState.applyAndBuy.shortCode,
    solCode: storeState.applyAndBuy.solCode,
    merchantSourceCode: storeState.applyAndBuy.merchantSourceCode,
  };

  Object.assign(transformed, trimStringFields(transformed));

  return transformed;
};

/**
 * Fill conditional fields with default values when their conditions are not met
 */
const convertConditionalFields = (formState, storeState) => {
  return _reduce(
    formState,
    (acc, sectionValue, sectionName) => {
      return {
        ...acc,
        [sectionName]: convertConditionalFieldsInOneSection(sectionValue, sectionName, storeState),
      };
    },
    {},
  );
};

const convertConditionalFieldsInOneSection = (fields, parentPath, storeState) => {
  const conditionalFieldsWithDefaultValues = _reduce(
    fields,
    (acc, _fieldValue, fieldName) => {
      const fieldConfig = findFieldConfig(fieldName, config.formConfigs[parentPath]);

      if (!fieldConfig?.condition || fieldConfig?.condition(fields, storeState) === true) {
        return acc;
      }

      if (!fieldConfig.fields) {
        const defaultValue = _get(config.initialFormValues, `${parentPath}.${fieldName}`);
        return {
          ...acc,
          [fieldName]: defaultValue,
        };
      }

      const dependentFieldNames = fieldConfig.fields.map(field => field.name);
      const dependentFieldDefaultValues = _pick(config.initialFormValues[parentPath], dependentFieldNames);
      return {
        ...acc,
        ...dependentFieldDefaultValues,
      };
    },
    {},
  );

  return Object.assign({}, fields, conditionalFieldsWithDefaultValues);
};

const trimStringFields = fields => {
  return _reduce(
    fields,
    (acc, fieldValue, fieldName) => {
      if (fieldValue === null || fieldValue === undefined) {
        return { ...acc, [fieldName]: fieldValue };
      }
      switch (typeof fieldValue) {
        case 'string':
          return {
            ...acc,
            [fieldName]: fieldValue.trim(),
          };
        case 'object':
          return {
            ...acc,
            [fieldName]: trimStringFields(fieldValue),
          };
        default:
          return {
            ...acc,
            [fieldName]: fieldValue,
          };
      }
    },
    {},
  );
};

const findFieldConfig = (fieldName, fieldConfigs) => {
  let fieldConfigsCopy = fieldConfigs;
  if (!fieldConfigsCopy) {
    return null;
  }

  if (typeof fieldConfigs === 'function') {
    fieldConfigsCopy = fieldConfigs();
  }

  return fieldConfigsCopy.find(fieldConfig => {
    if (fieldConfig.name === fieldName) {
      return true;
    }
    if (fieldConfig.fields) {
      return findFieldConfig(fieldName, fieldConfig.fields);
    }
    return false;
  });
};

const transformFromBiometricsFlow = (transformed, storeState, formData) => {
  transformed.extraDetails.relationshipStatus = transformed.biometricsAboutYou.relationshipStatus;
  transformed.extraDetails.numberOfDependents = transformed.biometricsAboutYou.numberOfDependents;

  transformed.otherDetails.residentialStatus = transformed.biometricsAddressDetails.residentialStatus;
  transformed.otherDetails.previousAddress = transformed.biometricsAddressDetails.previousAddress;
  transformed.otherDetails.postalAddress = transformed.biometricsAddressDetails.postalAddress;
  transformed.otherDetails.hasNonDomesticPreviousAddress =
    transformed.biometricsAddressDetails.hasNonDomesticPreviousAddress;
  transformed.otherDetails.isPostalSameWithResidential =
    transformed.biometricsAddressDetails.isPostalSameWithResidential;
  transformed.otherDetails.lengthOfCurrentResidency = transformed.biometricsAddressDetails.lengthOfCurrentResidency;

  transformed.contactDetails = {
    ...transformed.contactDetails,
    ..._pick(storeState.biometricsResult, ['firstName', 'dateOfBirth']),
    title: transformed.biometricsAboutYou.title,
  };

  // Current address is either the one provided by biometrics or the one provided by the user on /biometrics-address page
  const hasObtainAddressFromBiometrics = !!storeState.biometricsResult?.address;
  const isAddressFromBiometricsOverridden = !!(formData.biometricsAddressDetails.updateCurrentAddress === 'yes');
  const currentAddress =
    hasObtainAddressFromBiometrics && !isAddressFromBiometricsOverridden
      ? {
          address: {
            ...storeState.biometricsResult?.address,
            countryCode: 'NZ',
          },
        }
      : transformed.biometricsAddressDetails.currentAddress;

  transformed.otherDetails.currentAddress = currentAddress;

  // ID document is either the one provided by biometrics or the one provided by the user on /biometrics-identification page
  if (storeState.biometricsResult?.idType) {
    // ID document provided in biometrics is valid
    transformed.extraDetails.idType = null;
  } else {
    // ID document provided in biometrics is invalid and we asked the user to re-enter a valid one from /biometrics-identification page
    transformed.extraDetails.idType = transformed.biometricsIdentification.idType;
    transformed.extraDetails.driversLicenseInfoBlock = transformed.biometricsIdentification.driversLicenseInfoBlock;
    transformed.extraDetails.licenceNumber = transformed.biometricsIdentification.licenceNumber;
    transformed.extraDetails.version = transformed.biometricsIdentification.version;
    transformed.extraDetails.idNumber = transformed.biometricsIdentification.idNumber;
    transformed.extraDetails.expiryDate = transformed.biometricsIdentification.expiryDate;
    transformed.extraDetails.nzResidencyNumber = transformed.biometricsIdentification.nzResidencyNumber;
    transformed.extraDetails.issuingCountry = transformed.biometricsIdentification.issuingCountry;
  }
};
