import { Message } from 'basis';
import React from 'react';
import { config } from '_config';

export const OktaProfileError = () => {
  const supportNumber = config.phoneNumbers.originationsTeam;

  return (
    <Message severity="blocking" bg="secondary.pink.t30">
      Something went wrong. Please call our Contact Centre on <a href={`tel:${supportNumber}`}>{supportNumber}</a>{' '}
      quoting <strong>`Profile issue`</strong>.
    </Message>
  );
};
