import React from 'react';
import { getSiteSubSection } from 'utils';
import { Grid, Flex } from 'basis';
import styled from '@emotion/styled';
import { useBreakpoint } from '@chakra-ui/react';
import { Logo } from './Logo';
import { IconLogo } from './IconLogo';
import 'typeface-montserrat';
import { WidthContainer } from './Containers';

const Title = styled.div`
  user-select: none;
  color: #0555c8;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.01em;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;

  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.md})`} {
      font-size: 20px;
    }
  `};
`;

const HEIGHT_MAP = {
  default: 53,
  lg: 71,
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-bottom: 1px solid #d6d6d6;
  height: ${HEIGHT_MAP.default}px;
  svg {
    max-height: 22px;
    min-width: 150px;
  }
  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.md})`} {
      height:${HEIGHT_MAP.lg}px;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center left;
      }
      svg {
        min-height: 32px;
        min-width: 204px;
      }
    }
  `};
`;

export function TwentyEightDegreesHeader() {
  const breakpoint = useBreakpoint();

  const showTitle = getSiteSubSection(window.location.pathname) !== 'intro';

  return (
    <header data-testid="au-28degrees-header">
      <HeaderWrapper data-testid="header-wrapper">
        <WidthContainer>
          <Grid colsGutter="0" cols="8">
            <Grid.Item colSpan="0" rowSpan="0">
              <Flex height="100%" placeItems="center left">
                {['md', 'lg', 'xl'].includes(breakpoint) ? <Logo /> : <IconLogo width={204} height={32} />}
              </Flex>
            </Grid.Item>
            <Grid.Item colSpan="1-7" rowSpan="0">
              {showTitle && (
                <Title>
                  <span>
                    Latitude 28° Global Platinum Mastercard<sup>&reg;</sup>
                  </span>
                </Title>
              )}
            </Grid.Item>
          </Grid>
        </WidthContainer>
      </HeaderWrapper>
    </header>
  );
}

TwentyEightDegreesHeader.HEIGHT_MAP = HEIGHT_MAP;
