import { useCallback } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import {
  UNKNOWN_API_ERROR,
  APOLLO_NETWORK_ERROR,
  APOLLO_GRAPHQL_ERROR,
  APOLLO_UNKNOWN_ERROR,
  AXIOS_NETWORK_ERROR,
  AXIOS_NO_RESPONSE_ERROR,
  AXIOS_UNKNOWN_ERROR,
  OKTA_ERROR,
  OKTA_ERRORS,
  DATADOG_NETWORK_SOURCE,
  DATADOG_CUSTOM_SOURCE,
} from 'store';

function useHandleApiErrors(type, onError) {
  const callback = useCallback(
    (error, doOnError) => {
      if (process.env.REACT_APP_ENV !== 'prod') {
        // eslint-disable-next-line
        console.warn(error);
      }

      let source = DATADOG_NETWORK_SOURCE;
      if (OKTA_ERRORS.includes(error.type)) {
        source = DATADOG_CUSTOM_SOURCE;
      }
      datadogRum.addError(error, undefined, source);
      doOnError && onError && onError(error);
    },
    [onError],
  );

  const handleError = useCallback(
    (error, doOnError = true) => {
      let err = { type: UNKNOWN_API_ERROR, error };

      if (error) {
        if (error.type) {
          err = error;
        } else {
          if (type === 'apollo') {
            if (error.graphQLErrors?.length) {
              err = { type: APOLLO_GRAPHQL_ERROR, error };
            } else if (Object.keys(error.networkError || {}).length) {
              err = { type: APOLLO_NETWORK_ERROR, error };
            } else {
              err = { type: APOLLO_UNKNOWN_ERROR, error };
            }
          }

          if (type === 'axios') {
            if (error.response) {
              // The request was made and the server responded with a status code that falls out of the range of 2xx
              err = { type: AXIOS_NETWORK_ERROR, error };
            } else if (error.request) {
              // The request was made but no response was received
              err = { type: AXIOS_NO_RESPONSE_ERROR, error };
            } else {
              // Something happened in setting up the request that triggered an Error
              err = { type: AXIOS_UNKNOWN_ERROR, error };
            }
          }

          if (type === 'okta') {
            err = { type: OKTA_ERROR, error };
          }
        }
      }

      callback(err, doOnError);
    },
    [type, callback],
  );

  return {
    handleError,
  };
}

export { useHandleApiErrors };
