import React from 'react';
import { Text, Stack, Container, Flex, Message } from 'basis';
import { initFormConfig } from 'utils';
import {
  medicareCardColourOptions,
  MEDICARE_CARD_GREEN,
  MEDICARE_CARD_YELLOW,
  MEDICARE_CARD_BLUE,
  numbersOnlyPattern,
  PREVENT_CREDIT_CARD_AUTOFILL,
  config,
} from '_config';
import { AU_MEDICARE, AU_PASSPORT } from '_config/au/constants';
import medicareGreenImg from 'images/medicare-green-guided.png';
import medicareYellowImg from 'images/medicare-yellow-guided.png';
import medicareBlueImg from 'images/medicare-blue-guided.png';
import ausPassportImg from 'images/australian-passport-guided.png';
import compact from 'lodash/compact';
import { YellowMessage } from 'components/message/YellowMessage';
import { getNameOptions } from './getNameOptions';

export const initialValues = {
  idType: '',
  idNumber: '',
  name: '',
  medicareCardNumber: '',
  individualReferenceNumber: '',
  medicareCardColour: '',
  expiryDateGreen: {
    day: '',
    month: '',
    year: '',
  },
  expiryDateNonGreen: {
    day: '',
    month: '',
    year: '',
  },
};

const expiryDateValidationMessages = {
  invalid: 'Please enter a valid date.',
  completeYear: 'Please enter the complete year YYYY.',
  monthError: 'Month must be within 1-12.',
  dayError: 'Day must be within 1-31.',
  minDate: 'Expired Medicare cards cannot be used for identification.',
};

const numberValidationMessages = 'Please enter a number from 0 to 9.';

export const getFormConfig = storeState => {
  const { firstName, middleName, lastName } = storeState.acquisition.personalDetails;
  const fullName = compact([firstName, middleName, lastName]).join(' ');
  const idTypeOptions = [
    ...(!storeState.eIDVPassportChecked ? [{ value: AU_PASSPORT, label: 'Australian Passport' }] : []),
    ...(!storeState.eIDVMedicareChecked ? [{ value: AU_MEDICARE, label: 'Medicare' }] : []),
    { value: 'NONE', label: 'None' },
  ];
  const medicareCardNameOptions = getNameOptions(storeState);

  return initFormConfig([
    {
      condition: (values, store) => store.eIDVMedicareChecked || store.eIDVPassportChecked,
      component: 'CustomComponent',
      testId: 'eidv-failure-message',
      children: () => (
        <YellowMessage>
          <Message
            testId="yellow-message"
            severity="warning-or-significant"
            title="Unfortunately, we weren’t able to verify your identity with the document you just supplied."
          >
            Please supply us with another form of ID. If you currently don’t hold another ID document you can skip this
            process and we will attempt to verify you using alternative electronic verification means.
          </Message>
        </YellowMessage>
      ),
    },
    {
      condition: (values, store) => !store.eIDVMedicareChecked && !store.eIDVPassportChecked,
      component: 'InfoBlock',
      name: 'intro',
      children: (
        <Container padding="2 0 0 0">
          <Stack gap="5">
            <Container>
              <Text>
                We weren't able to verify your identity. To assist you further we will attempt to verify you with your
                {` `}
                <b>Medicare</b> and/or <b>Australian Passport</b> (not expired more than 2 years).
              </Text>
            </Container>
            <Container>
              <Text>
                If you currently don't hold either of these ID documents you can skip this process and we will attempt
                to verify you using alternative electronic verification means.
              </Text>{' '}
            </Container>
          </Stack>
        </Container>
      ),
    },
    {
      component: 'Select',
      name: 'idType',
      label: 'ID document',
      options: idTypeOptions,
      testId: 'id-type',
      helpText: `Select 'None' if you don't hold any of these ID documents`,
    },
    {
      condition: values => values.idType === AU_MEDICARE,
      name: 'selectedMethodMedicare',
      fields: [
        {
          name: 'name',
          testId: 'name',
          component: 'Select',
          label: 'Name as displayed on your Medicare card',
          options: medicareCardNameOptions,
          validate: {
            messages: {
              required: 'Select the name that is displayed on your Medicare card?',
            },
          },
        },
        {
          name: 'medicareCardColour',
          testId: 'medicareCardColour',
          component: 'Select',
          label: 'Medicare card colour',
          options: medicareCardColourOptions,
          validate: {
            messages: {
              required: 'Please select your Medicare card colour.',
            },
          },
        },
        {
          condition: values => values.medicareCardColour === MEDICARE_CARD_GREEN || values.medicareCardColour === '',
          component: 'InfoBlock',
          name: 'medicareImageGreen',
          children: (
            <Flex height="100%" placeItems="top center">
              <img key={medicareGreenImg} src={medicareGreenImg} alt="Medicare Card" width="100%" />
            </Flex>
          ),
        },
        {
          condition: values => values.medicareCardColour === MEDICARE_CARD_YELLOW,
          component: 'InfoBlock',
          name: 'medicareImageYellow',
          children: (
            <Flex height="100%" placeItems="top center">
              <img key={medicareYellowImg} src={medicareYellowImg} alt="Medicare Card" width="100%" />
            </Flex>
          ),
        },
        {
          condition: values => values.medicareCardColour === MEDICARE_CARD_BLUE,
          component: 'InfoBlock',
          name: 'medicareImageBlue',
          children: (
            <Flex height="100%" placeItems="top center">
              <img key={medicareBlueImg} src={medicareBlueImg} alt="Medicare Card" width="100%" />
            </Flex>
          ),
        },
        {
          name: 'individualReferenceNumber',
          testId: 'individualReferenceNumber',
          label: 'Individual reference number',
          component: 'Input',
          variant: 'numeric',
          autoComplete: PREVENT_CREDIT_CARD_AUTOFILL[0],
          validate: {
            props: {
              minLength: 1,
              maxLength: 1,
              validPattern: numbersOnlyPattern,
            },
            messages: {
              invalid: numberValidationMessages,
              minLength: 'Reference number must be {{minLength}} digits long.',
              maxLength: 'Reference number must be {{maxLength}} digits long.',
              required: 'Please enter your individual reference number.',
            },
          },
        },
        {
          name: 'medicareCardNumber',
          testId: 'medicareCardNumber',
          label: 'Card number',
          component: 'Input',
          variant: 'numeric',
          autoComplete: PREVENT_CREDIT_CARD_AUTOFILL[1],
          validate: {
            props: {
              minLength: 10,
              maxLength: 10,
              validPattern: numbersOnlyPattern,
            },
            messages: {
              invalid: numberValidationMessages,
              minLength: 'Card number must be {{minLength}} digits long.',
              maxLength: 'Card number must be {{maxLength}} digits long.',
              required: 'Please enter your card number.',
            },
          },
        },
        {
          condition: values => values.medicareCardColour === MEDICARE_CARD_GREEN,
          name: 'expiryDateGreen',
          testId: 'expiryDate',
          component: 'DatePicker',
          label: 'Expiry date',
          dayMode: 'none',
          validate: {
            props: {
              validPattern: numbersOnlyPattern,
              minDate: config.minExpiryDate,
            },
            messages: {
              ...expiryDateValidationMessages,
              required: 'Please enter a valid expiry date (MM/YYYY).',
            },
            component: 'DatePicker',
          },
        },
        {
          condition: values => values.medicareCardColour !== MEDICARE_CARD_GREEN,
          name: 'expiryDateNonGreen',
          testId: 'expiryDate',
          component: 'DatePicker',
          label: 'Expiry date',
          validate: {
            props: {
              validPattern: numbersOnlyPattern,
              minDate: config.minExpiryDate,
            },
            messages: {
              ...expiryDateValidationMessages,
              required: 'Please enter a valid expiry date (DD/MM/YYYY).',
            },
            component: 'DatePicker',
          },
        },
      ],
    },
    {
      condition: values => values.idType === AU_PASSPORT,
      name: 'selectedMethodPassport',
      fields: [
        {
          component: 'InfoBlock',
          name: 'passportImage',
          children: (
            <Flex height="100%" placeItems="top center">
              <img key={ausPassportImg} src={ausPassportImg} alt="Australian Passport" width="100%" />
            </Flex>
          ),
        },
        {
          component: 'InfoBlock',
          name: 'intro',
          children: (
            <Text>
              <b>Name on Passport:</b> {fullName}
            </Text>
          ),
        },
        {
          name: 'idNumber',
          testId: 'passportNumber',
          component: 'Input',
          label: 'Australian Passport number',
          helpText: 'Australian Passports must not be expired more than 2 years.',
          validate: {
            props: {
              validPattern: /^[A-Za-z]{1,2}\d{7}$/,
              maxLength: 9,
            },
            messages: {
              required: 'Please enter your Australian Passport number.',
              invalid: 'Please provide a valid Australian passport number.',
            },
          },
        },
      ],
    },
  ]);
};
