import React from 'react';
import { Header as BasisHeader } from 'basis';
import { byCard, byCountry } from 'utils';
import { DavidJonesHeader } from 'components/david-jones/Header';
import { TwentyEightDegreesHeader } from 'components/twenty-eight-degrees/Header';
import { GoHeader } from 'components/go/Header';
import {
  PRODUCT_CODE_NZ_GEM,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  PRODUCT_CODE_GO,
} from '_config';

export function Header() {
  const ProductHeader = byCard({
    [PRODUCT_CODE_NZ_GEM]: NzHeader,
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: DavidJonesHeader,
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: DavidJonesHeader,
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: TwentyEightDegreesHeader,
    [PRODUCT_CODE_GO]: GoHeader,
    default: byCountry({ NZ: NzHeader, AU: DavidJonesHeader }),
  });

  return <ProductHeader />;
}

function NzHeader() {
  return (
    <BasisHeader>
      <BasisHeader.Logo name="gem" />
    </BasisHeader>
  );
}

Header.ID = 'Header';
Header.HEIGHT_MAP = byCountry({ NZ: BasisHeader.HEIGHT_MAP, AU: DavidJonesHeader.HEIGHT_MAP });
