import { setSessionStorage, getSessionStorage } from 'utils/sessionStorage';

export const sessionStorageKey = 'config';

export * from './_constants';

export const STEP_DAVID_JONES_PREMIERE = '/davidjonespremiere';
export const STEP_DAVID_JONES_PRESTIGE = '/davidjonesprestige';
export const STEP_TWENTY_EIGHT_DEGREES = '/28degrees';
export const STEP_GO = '/go';
export const STEP_PRODUCT_COMPARISON = '/davidjones-product-comparison';
export const STEP_ABOUT_YOU = '/about-you';
export const STEP_ACCEPTANCE = '/acceptance';
export const STEP_ADDRESS_DETAILS = '/address-details';
export const STEP_BIOMETRICS_ABOUT_YOU = '/biometrics-about-you';
export const STEP_BIOMETRICS_ADDRESS_DETAILS = '/biometrics-address-details';
export const STEP_BIOMETRICS_CANCELLED = '/biometrics-cancelled';
export const STEP_BIOMETRICS_COMPLETE = '/biometrics-complete';
export const STEP_BIOMETRICS_IDENTIFICATION = '/biometrics-identification';
export const STEP_BIOMETRICS_RETURN = '/biometrics-return';
export const STEP_COMMITMENTS = '/commitments';
export const STEP_ADDITIONAL_CARD_HOLDER = '/additional-card-holder';
export const STEP_CONFIRM_CREDIT_LIMIT = '/credit-limit-details';
export const STEP_LOAN_INFORMATION = '/loan-information';
export const STEP_CONFIRM_INCOME = '/confirm-income';
export const STEP_CONTACT_DETAILS = '/contact-details';
export const STEP_CREDIT_LIMIT = '/credit-limit';
export const STEP_DVS_CONSENT = '/dvs-consent';
export const STEP_EIDV = '/check-your-identity';
export const STEP_AUS_POST = '/aus-post-identity';
export const STEP_EXPENSES = '/expenses';
export const STEP_FINANCIALS = '/financials';
export const STEP_IDENTIFICATION = '/identification';
export const STEP_INCOME = '/income';
export const STEP_LANDING = '/';
export const STEP_LATITUDE_ID_INTRODUCTION = '/latitude-id-introduction';
export const STEP_EMAIL_VERIFICATION = '/verify-email';
export const STEP_LATITUDE_ID = '/latitude-id';
export const STEP_OUTCOME = '/outcome';
export const STEP_OUTCOME_FIN_TABLE = '/outcome-fin-table';
export const STEP_OUTCOME_TIMEOUT = '/outcome-timeout';
export const STEP_PASSWORD = '/password';
export const STEP_PROCESSING = '/processing';
export const STEP_PROCESSING_ACCEPTANCE = '/processing-acceptance';
export const STEP_PROCESSING_BIOMETRICS = '/processing-biometrics';
export const STEP_PROCESSING_IDV = '/processing-IDV';
export const STEP_PROCESSING_PENDING_POI = '/processing-pending-poi';
export const STEP_PROCESSING_POI = '/processing-poi';
export const STEP_PROCESSING_LOAN_INFORMATION = '/processing-loan-information';
export const STEP_RESUME_ACCEPTANCE = '/resume-acceptance';
export const STEP_RESUME_APPLICATION_EXPIRED = '/resume-application-expired';
export const STEP_RESUME_SAVED = '/saved';
export const STEP_RESUME_YOUR_APPLICATION = '/resume-your-application';
export const STEP_SAVED = '/saved';
export const STEP_VERIFY = '/verify';
export const STEP_VERIFY_DOCUMENT_UPLOAD = '/verify-document-upload';
export const STEP_VERIFY_FICONNECT = '/verify-ficonnect';
export const STEP_VERIFY_IDENTITY = '/verify-identity';
export const STEP_VERIFY_INCOME = '/verify-income';
export const STEP_VERIFY_TO_RESUME = '/verify-to-resume';

export const COUNTRY_CODE_AU = 'AU';
export const COUNTRY_CODE_NZ = 'NZ';

export const PRODUCT_CODE_NZ_GEM = 'CCNZGEM';
export const PRODUCT_CODE_DAVID_JONES_PRESTIGE = 'CCAUDJG';
export const PRODUCT_CODE_DAVID_JONES_PREMIERE = 'CCAUDJM';
export const PRODUCT_CODE_TWENTY_EIGHT_DEGREES = 'CCAU28D';
export const PRODUCT_CODE_GO = 'CCAUGOM';

export const PRODUCT_NAME_NZ_GEM = 'Gem';
export const PRODUCT_NAME_DAVID_JONES_PRESTIGE = 'David Jones Prestige';
export const PRODUCT_NAME_DAVID_JONES_PREMIERE = 'David Jones Premiere';
export const PRODUCT_NAME_TWENTY_EIGHT_DEGREES = 'Latitude 28° Global Platinum Mastercard®';
export const PRODUCT_NAME_GO = 'Latitude GO Mastercard®';

export const PRODUCT_DAVID_JONES = 'DavidJones';

export const DAVID_JONES_FEATURE_PREFIX = 'dj';
export const TWENTY_EIGHT_DEGREES_FEATURE_PREFIX = 'au28D';
export const GO_FEATURE_PREFIX = 'go';

export const PRODUCTS = {
  [COUNTRY_CODE_AU]: {
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: {
      name: PRODUCT_NAME_DAVID_JONES_PREMIERE,
      landingPath: STEP_DAVID_JONES_PREMIERE,
      docUploadCardType: 'dj',
      featureToggleKeyPrefix: DAVID_JONES_FEATURE_PREFIX,
      ratesAndFeesFeatureToggleKey: 'premiereRatesAndFees',
    },
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: {
      name: PRODUCT_NAME_DAVID_JONES_PRESTIGE,
      landingPath: STEP_DAVID_JONES_PRESTIGE,
      docUploadCardType: 'dj',
      featureToggleKeyPrefix: DAVID_JONES_FEATURE_PREFIX,
      ratesAndFeesFeatureToggleKey: 'prestigeRatesAndFees',
    },
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: {
      name: PRODUCT_NAME_TWENTY_EIGHT_DEGREES,
      landingPath: STEP_TWENTY_EIGHT_DEGREES,
      docUploadCardType: '28degreescard',
      featureToggleKeyPrefix: TWENTY_EIGHT_DEGREES_FEATURE_PREFIX,
    },
    [PRODUCT_CODE_GO]: {
      name: PRODUCT_NAME_GO,
      landingPath: STEP_GO,
      docUploadCardType: 'go',
      featureToggleKeyPrefix: GO_FEATURE_PREFIX,
    },
    // TODO: config the below AU products
    // [PRODUCT_CODE_AU_GEM]: {
    //   name: PRODUCT_NAME_AU_GEM,
    //   landingPath: STEP_LANDING_AU_GEM,
    //   docUploadCardType: 'gemvisaau',
    //   featureToggleKeyPrefix: AU_GEM_FEATURE_PREFIX,
    // },
    // [PRODUCT_CODE_AU_CREDITLINE]: {
    //   name: PRODUCT_NAME_AU_CREDITLINE,
    //   landingPath: STEP_LANDING_AU_CREDITLINE,
    //   docUploadCardType: 'creditline',
    //   featureToggleKeyPrefix: AU_CREDITLINE_FEATURE_PREFIX,
    // },
  },
  [COUNTRY_CODE_NZ]: {
    [PRODUCT_CODE_NZ_GEM]: {
      name: PRODUCT_NAME_NZ_GEM,
      landingPath: STEP_LANDING,
      docUploadCardType: 'gemvisanz',
      featureToggleKeyPrefix: null, // TODO: make NZ feature toggles with prefix
    },
  },
};

const configSingleton = {};

export const setConfig = ({ productCode, countryCode: countryCodeInput }) => {
  const countryCode = countryCodeInput || lookupCountryCode(productCode);

  let importCountryConfig = null;
  switch (countryCode) {
    case COUNTRY_CODE_NZ:
      importCountryConfig = () => require('./nz');
      break;
    case COUNTRY_CODE_AU:
      importCountryConfig = () => require('./au');
      break;
    default:
      throw new Error(`Unexpected country code: ${countryCode}`);
  }

  const countryConfig = importCountryConfig();
  Object.assign(configSingleton, {
    countryCode,
    productCode,
    productName: countryCode && productCode ? PRODUCTS[countryCode][productCode].name : null,
    phoneNumbers: productCode && countryConfig?.getPhoneNumbers(productCode),
    urls: productCode && countryConfig?.getUrls(productCode),
    ...countryConfig,
  });

  setSessionStorage(sessionStorageKey, {
    countryCode,
    productCode,
  });

  return configSingleton;
};

const initConfig = () => {
  switch (window.location.pathname) {
    case STEP_DAVID_JONES_PRESTIGE:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
        productCode: PRODUCT_CODE_DAVID_JONES_PRESTIGE,
      });
    case STEP_DAVID_JONES_PREMIERE:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
        productCode: PRODUCT_CODE_DAVID_JONES_PREMIERE,
      });
    case STEP_PRODUCT_COMPARISON:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
      });
    case STEP_TWENTY_EIGHT_DEGREES:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
        productCode: PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
      });
    case STEP_GO:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
        productCode: PRODUCT_CODE_GO,
      });
    case STEP_OUTCOME_FIN_TABLE:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
      });
    case STEP_RESUME_YOUR_APPLICATION: {
      const queryString = window.location.search;
      const queries = new URLSearchParams(queryString);
      const cardType = queries.get('cardType');
      const productCode = queries.get('productCode');
      if (!productCode && cardType) {
        return setConfig({ countryCode: cardType === 'DJ' ? COUNTRY_CODE_AU : COUNTRY_CODE_NZ });
      }
      return setConfig({
        countryCode: COUNTRY_CODE_NZ,
        productCode: PRODUCT_CODE_NZ_GEM,
      });
    }
    default:
      if (getSessionStorage(sessionStorageKey)) {
        // This is to prevent when user hits refresh and then config always get reset to default NZ settings
        const { countryCode, productCode } = getSessionStorage(sessionStorageKey);
        return setConfig({
          countryCode,
          productCode,
        });
      }

      return setConfig({
        countryCode: COUNTRY_CODE_NZ,
        productCode: PRODUCT_CODE_NZ_GEM,
      });
  }
};

export const config = Object.keys(configSingleton).length ? configSingleton : initConfig();

export function lookupCountryCode(productCode) {
  const matchedPair = Object.entries(PRODUCTS).find(([country, products]) =>
    Object.keys(products).includes(productCode),
  );

  if (!matchedPair) {
    throw new Error(`Unexpected product code: ${productCode}`);
  }

  return matchedPair[0];
}

export const stepToFormMap = {
  [STEP_LANDING]: 'overview',
  [STEP_CONTACT_DETAILS]: 'contactDetails',
  [STEP_RESUME_YOUR_APPLICATION]: 'contactDetails',
  [STEP_VERIFY_TO_RESUME]: 'verification',
  [STEP_VERIFY]: 'verification',
  [STEP_PASSWORD]: 'password',
  [STEP_ABOUT_YOU]: 'personalDetails',
  [STEP_BIOMETRICS_ABOUT_YOU]: 'biometricsAboutYou',
  [STEP_BIOMETRICS_ADDRESS_DETAILS]: 'biometricsAddressDetails',
  [STEP_ADDRESS_DETAILS]: 'otherDetails',
  [STEP_IDENTIFICATION]: 'extraDetails',
  [STEP_INCOME]: 'employmentDetails',
  [STEP_EXPENSES]: 'expensesDetails',
  [STEP_COMMITMENTS]: 'additionalDebtsDetails',
  [STEP_CREDIT_LIMIT]: 'creditLimitDetails',
  [STEP_ACCEPTANCE]: 'acceptance',
  [STEP_PRODUCT_COMPARISON]: 'comparison',
  [STEP_LANDING]: 'overview',
  [STEP_DAVID_JONES_PRESTIGE]: 'overview',
  [STEP_DAVID_JONES_PREMIERE]: 'overview',
  [STEP_TWENTY_EIGHT_DEGREES]: 'overview',
  [STEP_VERIFY_DOCUMENT_UPLOAD]: 'employmentDetails',
};

export const formToStepMap = Object.keys(stepToFormMap).reduce(
  (acc, key) => ({
    ...acc,
    [stepToFormMap[key]]: key,
  }),
  {},
);

export const downSellCreditLimit = () => {
  if (config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE) {
    return 6000;
  }
  // for PRODUCT_CODE_NZ_GEM and PRODUCT_CODE_DAVID_JONES_PREMIERE, the minimum credit limit is 1000
  return 1000;
};

export const getMinAcceptedCreditLimit = () => {
  switch (config.productCode) {
    case PRODUCT_CODE_NZ_GEM:
    case PRODUCT_CODE_DAVID_JONES_PREMIERE:
    case PRODUCT_CODE_TWENTY_EIGHT_DEGREES:
      return 1000;
    case PRODUCT_CODE_DAVID_JONES_PRESTIGE:
      return 6000;
    case PRODUCT_CODE_GO:
      return 1000;
    default:
      throw new Error(`Unexpected product code: ${config.productCode}`);
  }
};
