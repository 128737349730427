import React from 'react';
import styled from '@emotion/styled';

export const IconTitleWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  margin-bottom: 32px;
  & > img {
    margin-right: 8px;
  }

  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.sm})`} {
      font-size: 18px;
    }
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  padding-bottom: 50px;

  li::before {
    width: 0.3em;
    height: 0.3em;
  }

  li:not(:first-of-type) {
    margin-top: 12px;
  }

  a {
    cursor: pointer;
  }

  h6 {
    font-weight: 500;
  }

  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.sm})`} {
      & > div {
        width: 100%;
        flex-direction: column;
      }
    }
    ${`@media (min-width: ${props.theme.screenSize.lg})`} {
      & > div:first-of-type {
        flex-direction: column;
      }

      & > div {
        max-width: ${props.theme.maxWidth || '1220px'};
        display: flex;
        flex-direction: column;
      }
    }

    ${`@media (max-width: ${props.theme.screenSize.md})`} {
    margin-top: 32px;
      h4 {
        font-size: 20px;
      }
      h6 {
        font-size: 16px;
      }
      padding-bottom: 30px;
    }
  `}
`;

export const LandingContentItem = styled.div`
  display: flex;
  img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
  svg {
    width: 24px;
    height: 24px;
    margin-left: -6px;
    margin-right: 4px;
  }
  ul {
    margin-top: 2px;
    margin-left: 1.5em;
  }
  margin-bottom: 20px;
  ${props => `
    ${`@media (max-width: ${props.theme.screenSize.md})`} {
      margin-bottom: 12px;
    }
  `}
`;

export const AnchorTag = styled.a`
  font-size: 8px;
  vertical-align: top;
  text-decoration: underline;
  cursor: pointer;
  ${props => `
    color: ${props.theme.a.color};
  `};
`;

export const BannerTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;

export const ImageIcons = styled.img`
  height: 68px;
  margin-right: 16px;
  ${props => `
      ${`@media (min-width: ${props.theme.screenSize.lg})`} {
        height: 68px;
      }
  `};
`;

export const HeaderBannerTextStyle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  vertical-align: text-top;
  line-height: 60px;
  ${props => `
    ${`@media (max-width: ${props.theme.screenSize.md})`} {
      line-height: 44px;
    }
  `};
  ${props => `
      ${props.theme.minMediaQueries.md} {
        background-color: transparent !important;
        height: 100% !important;
      }

      ${props.theme.minMediaQueries.xs} {
        height: auto;
      }
  `}

  & div {
    background-color: transparent !important;
  }
  button {
    border: 1px white solid;
    border-radius: 4px;
    padding: 12px 16px;
    width: 142px;
    height: 52px;
    font-size: 17px;
    line-height: 28px;
  }

  sup {
    vertical-align: top;
    position: relative;
    top: -0.2em;
  }
`;

export const ProductNameWrapper = styled.div`
  margin-top: 64px;
  color: white;
  font-size: 40px;
  font-weight: 600;
  font-family: 'Montserrat';
  ${props => `
    ${`@media (max-width: ${props.theme.screenSize.lg})`} {
      margin-top: 0px;
    }
  `}

  ${props => `
    ${`@media (max-width: ${props.theme.screenSize.sm})`} {
      font-size: 32px;
    }
  `}
`;

export const Banner = styled.div`
  background: linear-gradient(129deg, #0032aa 0%, #0062ed 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.sm})`} {
      max-height: auto;
      & > div {
        width: 100%;
      }
    }
    ${`@media (min-width: ${props.theme.screenSize.lg})`} {
      & > div:first-of-type {
        max-height: 340px;
      }

      & > div {
        max-width: 1220px;
        display: flex;
      }
    }
  `}
`;

export const BannerImageContainer = styled.div`
  ${props => `
      ${`@media (max-width: ${props.theme.screenSize.lg})`} {
        margin-top: 12px;
        margin-bottom: 24px;
      }
  `}
  img {
    display: block;
    width: auto;
    height: auto;
    margin: auto;
  }
`;

export const BannerImage = ({ children }) => {
  return <BannerImageContainer>{children}</BannerImageContainer>;
};
